export class OtherChargesTypes {
    constructor(
        public ID: number,
        public InvoiceID: number,
        public OtherChargesTypeID: number,
        public SupplierID: number,
        public SupplierInvoiceID: string,
        public VATTypeID: number,
        public VATPercentage: number,
        public Amount: number,
        public Comment: string,
        public Label_OtherChargesType: string,
        public Label_VATTypeName: string,
        public NetPrice: number,
        public VatPrice: number,
        public VATTotal: number,
        public TotalAmount: number,
        public PerDiscountNetAmount: number,
        public Description: string,
        public IsDeleted: boolean,
        public CreatedDate: Date,
        public ModifiedDate: Date,
        public CreatedBy: string,
        public ModifiedBy: string,
    ) {}
}