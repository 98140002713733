export class InvoiceMapDeliverable
{
    constructor(
        public InvoiceNo :string,
        public DeliverableNumber :number,
        public SupplierID:number,
        public ProductID:number,
        public Quantity:number,
        public IsReceived:boolean,
        public IsException:boolean,
        public InvoiceDate:string,
        public Name:string,
        public SupplierProductCode:string,
        public SupplierName:string,
        public Comments:string,
        public IsInvoiceReceived :boolean,
        public DeliveryDate:string,
        public ReceivedQuantity:number,
        public ExceptionQuantity:number,
        public ProductVariationID:number
    ){}
}
 
export class InvoiceMapDeliverableLineItem
{
    constructor(
        public DeliverableNumber :number,
        public ProductID:number,
        public SupplierID:number,
        public Name :string,
        public SupplierCode:string,
        public Quantity:number,
        public IsReceived:boolean,
        public IsException:boolean,
        public Comments:string,
        public DeliveryDate:string,
        public ReceivedQuantity:number,
        public ExceptionQuantity:number,
        public ProductVariationID:number
    )
    { }
}


export class InvoiceMapDeliverableItem
{
    constructor(
        public SupplierID:number,
        public SupplierName:string,
        public DeliverableNumber :number,
        public InvoiceMapDeliverableLineItems:InvoiceMapDeliverableLineItem[]
    )
    { }
}
 ///Filtered UnMap Details///

 export class InvoiceFilteredMapDeliverableLineItem
{
    constructor(
        public DeliverableNumber :number,
        public ProductID:number,
        public SupplierID:number,
        public Name :string,
        public SupplierCode:string,
        public Quantity:number,
        public IsReceived:boolean,
        public IsException:boolean,
        public Comments:string,
        public DeliveryDate:string,
        public ReceivedQuantity:number,
        public ExceptionQuantity:number,
        public ProductVariationID:number
    )
    { }
}


export class InvoiceFilteredMapDeliverableItem
{
    constructor(
        public SupplierID:string,
        public SupplierName:string,
        public DeliverableNumber :number,
        public InvoiceFilteredMapDeliverableLineItems:InvoiceFilteredMapDeliverableLineItem[]
    )
    { }
}