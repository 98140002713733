<div class="app-outer-container page-background">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-7 my-auto">
        <div class="contact-title">
          <h1>Welcome To Retail Helper</h1>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card card-body card-radius">
          <h3 class="headingalign">Contact Us</h3>
          <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && ContactSubmit()" novalidate>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="Name" placeholder="Name" pattern=".*\S.*" maxlength="50"
                         [(ngModel)]="contact.Name" #Name="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && Name.invalid }" [disabled]="loading"
                         autocomplete="off" required />
                  <div *ngIf="f.submitted && Name.invalid" class="invalid-feedback">
                    <div *ngIf="Name.errors.required || Name.errors.pattern" class="leftpadding">Name is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-phone" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="telephone" placeholder="Telephone" maxlength="15"
                         [(ngModel)]="contact.Telephone" #telephone="ngModel" pattern="^([0-9\(\)\/\+ \-]*)$"
                         [ngClass]="{ 'is-invalid': f.submitted && telephone.invalid }" [disabled]="loading"
                         autocomplete="off" required />
                  <div *ngIf="f.submitted && telephone.invalid" class="invalid-feedback">
                    <div *ngIf="telephone.errors.required" class="leftpadding">Phone number is required</div>
                    <div *ngIf="telephone.errors.pattern" class="leftpadding">Enter valid phone number</div>
                  </div>
                </div>

              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="email" placeholder="Email" [(ngModel)]="contact.Email"
                         #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email 
                         (keydown.space)="$event.preventDefault()" autocomplete="off" [disabled]="loading" required />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required" class="leftpadding">Email is required</div>
                    <div *ngIf="email.errors.email" class="leftpadding">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
            </div>

            <!--Recaptcha-->
            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="g-recaptcha" data-sitekey="6LfaMvcUAAAAAKvvoVNrpPt7J50DOJagYzl3Mk21"></div>
              </div>
            </div>


            <div class="form-group row">
              <div class="col-md-12">
                <button [disabled]="loading" class="btn round-button red-grad-button buttonMargin">Submit</button>
                <span *ngIf="loading" class="rightalign">
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </span>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="validationerror">
                  <span><small>{{validationerror}}</small></span>
                </p>
              </div>
            </div>
          </form>

          
        </div>
      </div>
    </div>       
  </div>
</div>
<div class="pop-up">
  <div class="login-conatiner">
    <app-login></app-login>
  </div>
</div>
