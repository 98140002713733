<!--<div class="app-outer-container  page-background">
  <div class="container">
    <div class="row">
      <div class="col-md-2 topmargin">
      </div>
      <div class="col-md-8 topmargin">
        <div class="card card-body card-radius text-center" *ngIf="registration">
          <h4>Thank you for registering.</h4>
          <p>We have sent you a verification email. Please click on the link there to verify your email address.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="confirm">
          <h4>Thank you for verifying your email.</h4>
          <p>Your account is now active. Please Sign In to continue.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="linkexpired">
          <h4>Link expired ! Please Contact Us.</h4>-->
<!--<p><span (click)="ResendConfirmationLink()" class="confirmlink">Click here</span> to resend confirmation link to activate your mail ID.</p>-->
<!--</div>

        <div class="card card-body card-radius text-center" *ngIf="forgotPassword">
          <h4>Password reset link sent.</h4>
          <p>We have sent a password reset link to your email address. Please click on the link to reset your password.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="resetPassword">
          <h4>Password updated Successfully.</h4>
          <p>Please Sign in to continue.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="verificationlinkResent">
          <h4>Email verification link sent.</h4>
          <p>Please click on the link there to verify your email address.</p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="contactdetails">
          <h4>Thank you for contacting us. </h4>
          <p>We will get back to you soon. </p>
        </div>

        <div class="card card-body card-radius text-center" *ngIf="confirmationError">
          <h4>Error: {{status}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>-->
<!--<div class="app-outer-container page-background">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-6">
        <div class="card card-body card-radius">
          <h2 class="headingalign text-center">Reset Password</h2>
          <form name="form" (ngSubmit)="f.form.valid && ResetPassword()" #f="ngForm">
            <div class="form-group row">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></span>
                  </div>
                  <input type="password" class="form-control" name="password" placeholder="New Password"
                         [(ngModel)]="resetPassword.password" #password="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                    <div *ngIf="password.errors.required" class="leftpadding">Password is required</div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="form-group row">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></span>
                  </div>
                  <input type="password" class="form-control" name="confirmpassword" placeholder="Confirm Password"
                         [(ngModel)]="resetPassword.confirmpassword" #password="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                    <div *ngIf="password.errors.required" class="leftpadding">Confirm Password is required</div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="form-group row">
              <div class="col-md-12">
                <button type="submit" [disabled]="loading" class="btn round-button red-grad-button buttonMargin">Update</button>
                <span *ngIf="loading" class="rightalign">
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </span>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="validationerror">
                  <span><small>{{validationerror}}</small></span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-3">

      </div>
    </div>
  </div>
</div>-->
<!--<div class="app-outer-container page-background">
  <div class="container">
    <div class="row h-100">
      <div class="col-lg-7 my-auto">
        <div class="register-title">
          <h1>Welcome To Retail Helper</h1>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card card-body card-radius">
          <h3 class="headingalign">Sign Up</h3>
          <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && UserRegister(f)" novalidate>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="firstName" placeholder="First Name"
                         [(ngModel)]="register.firstName" #firstName="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }"
                         (keydown.space)="$event.preventDefault()" autocomplete="off" required />
                  <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                    <div *ngIf="firstName.errors.required" class="leftpadding">First Name is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="lastName" placeholder="Last Name"
                         [(ngModel)]="register.lastName" #lastName="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }"
                         (keydown.space)="$event.preventDefault()" autocomplete="off" required />
                  <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                    <div *ngIf="lastName.errors.required" class="leftpadding">Last Name is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-server" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="department" placeholder="Department"
                         [(ngModel)]="register.department" #department="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && department.invalid }"
                         (keydown.space)="$event.preventDefault()" autocomplete="off" required />
                  <div *ngIf="f.submitted && department.invalid" class="invalid-feedback">
                    <div *ngIf="department.errors.required" class="leftpadding">Department is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="email" placeholder="Email" [(ngModel)]="register.email"
                         #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email
                         (keydown.space)="$event.preventDefault()" autocomplete="off" required />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required" class="leftpadding">Email is required</div>
                    <div *ngIf="email.errors.email" class="leftpadding">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row textboxMarginbottom">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></span>
                  </div>
                  <input type="password" class="form-control" name="password" placeholder="Password"
                         [(ngModel)]="register.password" #password="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" minlength="6"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                    <div *ngIf="password.errors.required" class="leftpadding">Password is required</div>
                    <div *ngIf="password.errors.minlength" class="leftpadding">
                      Password must be at least 6 characters
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12">
                <button [disabled]="loading" class="btn round-button red-grad-button buttonMargin">Register</button>
                <span *ngIf="loading" class="rightalign">
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </span>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="validationerror">
                  <span><small>{{validationerror}}</small></span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pop-up">-->
<!--<div class="login-conatiner">
  <app-login></app-login>
</div>-->
<!--</div>-->


<div class=" main-content">
  <div class=" header bg-gradient-danger py-7 py-lg-8 pt-lg-9">
    <div class=" container">
      <div class=" header-body text-center mb-7">
        <div class=" row justify-content-center">
          <div class=" col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class=" text-white">Thank You</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0"
           y="0"
           viewBox="0 0 2560 100"
           preserveAspectRatio="none"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </div>

  <div class=" container mt--8 pb-5">
    <div class=" row justify-content-center">
      <div class=" col-lg-6 col-md-8">
        <div class=" card bg-secondary border-0">
          <div class=" card-body px-lg-5 py-lg-5">

            <div class="col-md-12 topmargin">
              <div class="card card-body card-radius text-center" *ngIf="registration">
                <h4>Thank you for registering.</h4>
                <p>We have sent you a verification email. Please click on the link there to verify your email address.</p>
              </div>

              <div class="card card-body card-radius text-center" *ngIf="confirm">
                <h4>Thank you for verifying your email.</h4>
                <p>Your account is now active. Please Sign In to continue.</p>
              </div>

              <div class="card card-body card-radius text-center" *ngIf="linkexpired">
                <h4>Link expired ! Please Contact Us.</h4>
                <!--<p><span (click)="ResendConfirmationLink()" class="confirmlink">Click here</span> to resend confirmation link to activate your mail ID.</p>-->
              </div>

              <div class="card card-body card-radius text-center" *ngIf="forgotPassword">
                <h4>Password reset link sent.</h4>
                <p>We have sent a password reset link to your email address. Please click on the link to reset your password.</p>
              </div>

              <div class="card card-body card-radius text-center" *ngIf="resetPassword">
                <h4>Password updated Successfully.</h4>
                <p>Please Sign in to continue.</p>
              </div>

              <div class="card card-body card-radius text-center" *ngIf="verificationlinkResent">
                <h4>Email verification link sent.</h4>
                <p>Please click on the link there to verify your email address.</p>
              </div>

              <div class="card card-body card-radius text-center" *ngIf="contactdetails">
                <h4>Thank you for contacting us. </h4>
                <p>We will get back to you soon. </p>
              </div>

              <div class="card card-body card-radius text-center" *ngIf="confirmationError">
                <h4>Error: {{status}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
