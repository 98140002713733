import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ProductmanagementService } from 'src/app/modules/product/services/productmanagement.service';
import { InvoiceDeliverable, InvoiceDeliverableItem, InvoiceDeliverableLineItem } from '../../models/invoice-deliverable.model';
import { InvoiceNewDeliverableLineItems, InvoiceNewDeliverableLineItemsDetails } from '../../models/invoice-new-deliverable-line-items.model';
import { InvoiceManagementService } from '../../services/InvoiceManagement.service';
import { PendingDeliveryDetailsService } from '../../services/pending-delivery-details.service';
//import { NewProductComponent } from '../new-product/new-product.component';

@Component({
  selector: 'app-pending-delivery-details',
  templateUrl: './pending-delivery-details.component.html',
  styleUrls: ['./pending-delivery-details.component.css']
})
export class PendingDeliveryDetailsComponent implements OnInit {
  InvoiceDeliverableDetails: InvoiceDeliverable[];
  //InvoiceDeliverable = new InvoiceDeliverable("", "", 0, "", 0, 0, 0, null, "", false, false, "", false, 0, 0, false, "", null, 0, 0, false, 0);
  InvoiceDeliverableItems: InvoiceDeliverableItem[] = [];
  tempAllItems: InvoiceDeliverableItem[] = [];
  InvoiceNewDeliverableLineItem = new InvoiceNewDeliverableLineItems("", "", 0, "", 0, 0, 0, "", false, false, "", "", false, false, 0, false,"", null);
  InvoiceNewDeliverableLineItemsDetails = new InvoiceNewDeliverableLineItemsDetails("", "", 0, 0, 0, false, false, 0, "", null, "", 0, 0, 0, 0, "");
  //DeliveryNumber: number = 0;
  //SupplierID: number;
  //InvoiceDate: any = null;
  SelectedProductID: number;
  //SupplierProductCode: string;
  //Quantity: number;
  selectedCategory: any = null;
  //selectedCheckValue: any = null;
  pendingDetails: any[] = [];
  newDeliveryDetails: any[] = [];
  //pendingDelivery: any[] = [];
  //prodDetails: any[] = [];
  newDelivery: boolean = false;
  //selectedCheckValue: string[] = [];
  selectedCheckValues: string = "";
  errorMessageQuantity: string = "";
  errorMessageProductCode: string = "";
  errorMessageSelectedProduct: string = "";
  errorMessageInvoiceDate: string = "";
  errorMessageSuppliers: string = "";
  suppliers: any[] = [];
  product: any[] = [];
  //msgs: { severity: string; summary: string; detail: string; }[];
  errorMessage: string = "";
  SelectedSupplierID: number;
  categories: any[] = [{name: 'Receive', key: 'REC'}, {name: 'Resolved', key: 'RES'}, {name: 'All', key: 'ALL'}];
  //checkValues: any[] = [{name: 'Is Received', key: 'R'}, {name: 'Is Exception', key: 'E'}];
  SupplierLoading: boolean = false;
  productCopy: any[]=[];
  NewDeliveryForm: FormGroup;
  viewOtherQuantity: boolean = false;
  //check2: boolean = false;
  //check1: boolean = false;
  InvoiceDetails: any;
  IsValid: boolean;
  IsValidControls: boolean;
  successMessage: string = "";
  SupplierName: string = "";
  productVariations: any[] = [];
  ProductVariation: any;
  errorMessageDeliverableNumber: string = "";
  DeliverySubmit: boolean;
  ProductsExist: boolean;
  infoMessageSubmitted: string = "";
  searchedDeliverableNum: boolean;
  disableDetails: boolean;
  errorMessageNewDelivery: string = "";
  errorMessageIsReceived: string = "";
  errorMessageReceivedQuantity: string = "";
  errorMessageExceptionQuantity: string = "";
  errorMessageExceptionAndReceivedQuantity: string = "";
  errorMessageComments: string = "";
  successMessageNewDelivery: string = "";
  deliveryDetailsCount: number;
  position: string;
  allocationResponseResult =[];
  successMessageSubmitDelivery: string;
  addDeliveryDisable: boolean = false;
  addNewDeliveryDetDisable: boolean = true;
  isExceptionOrReceive: boolean = false;
  recevieInvoiceNo: string ="";
  receiveSupplierID: number;
  inputSearch: string = "";
  loading: boolean = false;
  InvDeliverableItems: any[]= [];
  isShownProduct: boolean = false;
  isShownSupplier: boolean = false;
  isExited: boolean = false;
  SelectedSupplierName: string = "";
  SelectedProductName: string = "";

  constructor(private productmanagementService: ProductmanagementService,
    private formBuilder: FormBuilder,
    private pendingDeliveryDetailsService: PendingDeliveryDetailsService,
    private invoiceManagementService: InvoiceManagementService,
    private confirmationService: ConfirmationService,
    private activatedRoute: ActivatedRoute, private router: Router,) {
     }

  ngOnInit(): void {
      this.selectedCategory = this.categories[0];
    this.recevieInvoiceNo = this.activatedRoute.snapshot.queryParams["InvoiceNo"];
    this.receiveSupplierID = Number(this.activatedRoute.snapshot.queryParams["SupplierID"]);
    this.getPendingDetails();
    if(this.receiveSupplierID>0 && this.recevieInvoiceNo!=""){
      this.selectedCategory = this.categories[2];
      this.inputSearch = this.recevieInvoiceNo;
    }
    this.InvoiceDeliverableItems = [];
    this.NewDeliveryForm = this.formBuilder.group({
      ID: [''],
      DeliverableNumber: ['', Validators.required],
      SupplierID: [0],
      SupplierDate: [''],
      SelectedProductID: [''],
      ProductVariation: [0],
      ProductCode: [''],
      ProductQuantity: [''],
      selectedCheckValue: [''],
      ProductReceivedQuantity: [''],
      ProductExceptionQuantity: [''],
      Comments: [''],
    });
  }

  getPendingDetails(){
    this.pendingDetails.length = 0;
    this.loading = true;
    //var a = performance.now();
    this.pendingDeliveryDetailsService.getAllPendingDetails().subscribe(
      data=>{
        this.InvoiceDeliverableDetails = data
        this.getdeliverablePendingDetails();
        this.loading = false;
        this.onChangeCategory(this.selectedCategory);
        //var b = performance.now();
        //console.log("Pending Delivery Details", b-a);
      },
      error=>{
        this.loading = false;
        this.errorMessage = "Error while loading data";
      });
  }

  checkInvoiceDate(InvoiceDate) {
    if (InvoiceDate == "" || InvoiceDate == null) {
      this.errorMessageInvoiceDate = "Select date"; 
    }
  }

  getdeliverablePendingDetails() {
    for (var i = 0; i < this.InvoiceDeliverableDetails.length; i++) {
      let item = this.InvDeliverableItems.filter(element => element.SupplierInvoiceID == this.InvoiceDeliverableDetails[i].SupplierInvoiceID && element.SupplierID == this.InvoiceDeliverableDetails[i].SupplierID);
        let lineItem = new InvoiceDeliverableLineItem(this.InvoiceDeliverableDetails[i].Name, this.InvoiceDeliverableDetails[i].SupplierProductCode, this.InvoiceDeliverableDetails[i].Quantity, this.InvoiceDeliverableDetails[i].ProductID, this.InvoiceDeliverableDetails[i].InvoiceDate, this.InvoiceDeliverableDetails[i].ProductVariationID);
      if(item && item.length>0){
        item[0].InvoiceDeliverableLineItems.push(lineItem);
      }
      else {
        let lineItems: InvoiceDeliverableLineItem[] = [];
        lineItems.push(lineItem);
        let newItem = new InvoiceDeliverableItem(this.InvoiceDeliverableDetails[i].SupplierInvoiceID, this.InvoiceDeliverableDetails[i].SupplierName, this.InvoiceDeliverableDetails[i].SupplierID, this.InvoiceDeliverableDetails[i].IsResolved, this.InvoiceDeliverableDetails[i].InvoiceDate, lineItems);
        this.InvDeliverableItems.push(newItem);
      }
    }
    if(this.inputSearch!=''){
      this.InvoiceDeliverableItems = this.InvDeliverableItems.filter(x=> x.SupplierInvoiceID == this.recevieInvoiceNo && x.SupplierID == this.receiveSupplierID);
    }
    else{
    this.InvoiceDeliverableItems = this.InvDeliverableItems;
    }
    this.InvoiceDetails = this.InvoiceDeliverableItems;
    this.tempAllItems = this.InvoiceDeliverableItems;
    this.deliveryDetailsCount = this.InvoiceDetails.length;
  }
    
    validDelivery() {
      this.IsValid = false;
      if (this.InvoiceNewDeliverableLineItem.SupplierID == null || this.InvoiceNewDeliverableLineItem.SupplierID == 0) {
        this.IsValid = true;
        this.errorMessageSuppliers = "Please select supplier";
        return;
      }
      else if (this.InvoiceNewDeliverableLineItem.InvoiceDate == null || this.InvoiceNewDeliverableLineItem.InvoiceDate == "") {
        this.IsValid = true;
        this.errorMessageInvoiceDate = "Please select date";
        return;
      }
      else if (this.InvoiceNewDeliverableLineItem.SupplierID == null || this.InvoiceNewDeliverableLineItem.InvoiceDate == null) {
        this.IsValidControls = true;
        return;
      }
      return this.IsValid;
    }
    
    addDelivery(){
      if(this.validDelivery()==false){
        if (this.InvoiceNewDeliverableLineItem.DeliverableNumber == null) {
          this.InvoiceNewDeliverableLineItem.DeliverableNumber = 0;
        }
        this.errorMessageSuppliers = "";
        this.errorMessageInvoiceDate = "";
        this.InvoiceNewDeliverableLineItem.IsDeliverySubmitted = false;
        this.InvoiceNewDeliverableLineItem.SupplierName = this.SupplierName;
        this.InvoiceNewDeliverableLineItem.CreatedBy = localStorage.getItem("userId");
        
        this.pendingDeliveryDetailsService.addNewDelivery(this.InvoiceNewDeliverableLineItem).subscribe(
          data=>{
            this.addDeliveryDisable = true;
            this.addNewDeliveryDetDisable = false;
            this.NewDeliveryForm.controls['SupplierID'].disable();
            this.NewDeliveryForm.controls['SupplierDate'].disable();
            this.getDeliverableNumber();
          });
      }
    }

    getDeliverableNumber()
    {
      this.pendingDeliveryDetailsService.getDeliverableNumber().subscribe(
        data=>{
          this.InvoiceNewDeliverableLineItem.DeliverableNumber = data[0].ID;
          this.InvoiceNewDeliverableLineItemsDetails.DeliverableNumber = data[0].ID;
          this.NewDeliveryForm.patchValue({
            DeliverableNumber: this.InvoiceNewDeliverableLineItem.DeliverableNumber,
          });
          this.successMessage = "Delivery Added Successfully, Delivery no = " + this.InvoiceNewDeliverableLineItem.DeliverableNumber;
        }
      )
    }

  onChangeCategory(selectedCategory)
  {
    this.selectedCategory = selectedCategory;
    if(this.selectedCategory['key']=="REC"){
      this.InvoiceDeliverableItems = this.tempAllItems.filter(x => x.IsResolved == false).sort((a,b) => +new Date(b.InvoiceDate) - +new Date(a.InvoiceDate));
      this.deliveryDetailsCount = this.InvoiceDeliverableItems.length;
    }
    else if(this.selectedCategory['key']=='RES'){
      this.InvoiceDeliverableItems = this.tempAllItems.filter(x => x.IsResolved == true).sort((a,b) => +new Date(b.InvoiceDate) - +new Date(a.InvoiceDate));
      this.deliveryDetailsCount = this.InvoiceDeliverableItems.length;
    }
    else{
      this.InvoiceDeliverableItems = this.tempAllItems;
      this.deliveryDetailsCount = this.InvoiceDeliverableItems.length;
    }
  }

  addNewDelivery(){
    this.getAllSuppliers();
    this.newDeliveryDetails = [];
    this.successMessage="";
    this.errorMessageInvoiceDate = "";
    this.errorMessageSuppliers = "";
    this.errorMessageProductCode = "";
    this.errorMessageIsReceived = "";
    this.errorMessageQuantity = "";
    this.errorMessageSelectedProduct = "";
    this.errorMessageDeliverableNumber = "";
    this.viewOtherQuantity = false;
    this.addDeliveryDisable = false;
    this.addNewDeliveryDetDisable = true;
    this.NewDeliveryForm.patchValue({
      ID: 0,
      DeliverableNumber: 0,
      SupplierID: 0,
      SupplierDate: null,
      SelectedProductID: null,
      ProductCode: "",
      ProductVariation: 0,
      ProductQuantity: 0,
      selectedCheckValue: null,
      ProductReceivedQuantity: 0,
      ProductExceptionQuantity: 0,
      Comments: "",
    });
    this.NewDeliveryForm.controls['SupplierID'].enable();
    this.NewDeliveryForm.controls['SupplierDate'].enable();
    this.newDelivery = true;
  }

  refresh(){
    this.successMessage="";
    this.errorMessageIsReceived = "";
    this.errorMessageInvoiceDate = "";
    this.errorMessageSuppliers = "";
    this.errorMessageProductCode = "";
    this.errorMessageQuantity = "";
    this.errorMessageSelectedProduct = "";
    this.successMessageNewDelivery="";
    this.viewOtherQuantity = false;
    this.newDeliveryDetails = [];
    this.addDeliveryDisable = false;
    this.addNewDeliveryDetDisable = true;
    this.NewDeliveryForm.patchValue({
      ID: 0,
      DeliverableNumber: 0,
      SupplierID: 0,
      SupplierDate: 0,
      SelectedProductID: -1,
      ProductCode: "",
      ProductVariation: 0,
      ProductQuantity: 0,
      selectedCheckValue: null,
      ProductReceivedQuantity: 0,
      ProductExceptionQuantity: 0,
      Comments: "",
    });
    this.NewDeliveryForm.controls['SupplierID'].enable();
    this.NewDeliveryForm.controls['SupplierDate'].enable();
  }

  showAddSupplier() {
    this.isShownSupplier =  true;
  }

  exitSup(value){
    this.isShownSupplier = value.isShownSup;
    this.isExited = true;
    this.SelectedSupplierName = value.supValue;
    if(this.SelectedSupplierName!=""){
    this.getAllSuppliers();
    }
  }

  showAddProduct() {
    this.SelectedSupplierID = this.NewDeliveryForm.get("SupplierID").value;
    this.isShownProduct = true;
  }

  exitProd(value){
    this.isShownProduct = value.isShownProd;
    this.isExited = true;
    this.SelectedProductName = value.prodVal;
    this.getAllProducts();
  }

  closeDialog(){
    this.newDelivery = false;
  }

  onChangeCheckValue(event) {
    this.errorMessageIsReceived = "";
    //console.log(this.selectedCheckValues);
    if(this.selectedCheckValues.length>=2){
      const latest = this.selectedCheckValues[this.selectedCheckValues.length - 1];
      this.selectedCheckValues = latest;
    }
    if(this.selectedCheckValues == "Is Exception")
    {
      this.InvoiceNewDeliverableLineItemsDetails.IsException = true;
      this.InvoiceNewDeliverableLineItemsDetails.IsReceived = false;
      this.viewOtherQuantity = true;
    }
    else if(this.selectedCheckValues == "Is Received"){
      this.InvoiceNewDeliverableLineItemsDetails.IsReceived = true;
      this.InvoiceNewDeliverableLineItemsDetails.IsException= false;
      this.viewOtherQuantity = false;
    }
    else{
      this.InvoiceNewDeliverableLineItemsDetails.IsReceived = false;
      this.InvoiceNewDeliverableLineItemsDetails.IsException= false;
      this.viewOtherQuantity = false;
    }
    this.NewDeliveryForm.patchValue({
           selectedCheckValue: this.selectedCheckValues,
         });
  }

  getAllSuppliers() {
    this.SupplierLoading = true;
    const newValue = { ID: 0, Name: 'Loading Suppliers ...' };
    this.suppliers.unshift(newValue);
    this.productmanagementService.getAllSuppliers()
      .subscribe(
        data => {
          this.suppliers = data;
          if(this.isExited == true){
            var sup = this.suppliers.filter(x=> x.Name == this.SelectedSupplierName);
            this.SelectedSupplierID = sup[0].ID;
            this.SupplierName = sup[0].Name;
            this.NewDeliveryForm.patchValue({
              SupplierID: sup[0].ID,
            });
            this.isExited = false;
          }
          if(this.suppliers.length>0){
          const newValue = { ID: 0, Name: '--Select Supplier--' };
          this.suppliers.unshift(newValue);
          }

          if (this.SelectedSupplierID <= 0) {
            this.product = [];
          }
          else { 
            this.getAllProducts();
          }
        },
        error => {
          this.errorMessage = "Supplier data not found";
          return;
        });
        this.NewDeliveryForm.patchValue({
          SupplierID: 0,
        });
  }

  onSelectSupplierChange(event) {
    this.successMessage = "";
    this.successMessageSubmitDelivery="";
    this.DeliverySubmit = false;
    this.errorMessageDeliverableNumber = "";
    this.NewDeliveryForm.patchValue({
      SupplierDate: null,
      SelectedProductID: 0,
      ProductVariation: 0,
      ProductCode: "",
      ProductQuantity: 0,
      selectedCheckValue: null,
      ProductReceivedQuantity: 0,
      ProductExceptionQuantity: 0,
      Comments: null,
    });
    this.viewOtherQuantity = false;
    this.newDeliveryDetails = [];
    let filtered: any[] = [];
    let query = event.value;
    this.SelectedSupplierID = query;
    this.InvoiceNewDeliverableLineItem.SupplierID = query;
    if (query > 0) {
      for(let j=0; j<this.suppliers.length; j++){
        let sup = this.suppliers[j];
        if(sup['ID']==this.SelectedSupplierID){
          this.SupplierName = sup['Name'];
        }
      }
      for (let i = 0; i < this.productCopy.length; i++) {
        let pro = this.productCopy[i];
        if (pro.SupplierID == query) {
          filtered.push(pro);

        }
      }
      this.product = filtered;
      if(this.product.length>0){
      const newValue = { ID: 0, Name: '--Select Product--' };
      this.product.unshift(newValue);
      }
    }
    else {
      this.product = [];
    }
    this.NewDeliveryForm.patchValue({
      SupplierID: this.SelectedSupplierID,
      SupplierDate: null,
      SelectedProductID: 0,
      ProductVariation: 0,
      ProductCode: null,
      ProductQuantity: 0,
      selectedCheckValue: null,
      ProductReceivedQuantity: 0,
      ProductExceptionQuantity: 0,
      Comments: null,
    });
  }

  onSelectProductChange(event){
    this.NewDeliveryForm.patchValue({
      selectedCheckValue: null,
      ProductQuantity: 0,
      ProductReceivedQuantity: 0,
      ProductExceptionQuantity: 0,
      Comments: null,
    });
    this.errorMessageSelectedProduct = "";
    this.successMessageNewDelivery="";
    this.viewOtherQuantity = false;
    let query = event.value;
    this.SelectedProductID = query;
    this.InvoiceNewDeliverableLineItemsDetails.ProductID = query;
    if(query>0){
      this.variation(this.SelectedProductID, 0);
      for (let i = 0; i < this.product.length; i++) {
        let pro = this.product[i];
        if (pro['ID'] == this.SelectedProductID) {
          this.InvoiceNewDeliverableLineItemsDetails.ProductName = pro['Name'];
          this.InvoiceNewDeliverableLineItemsDetails.SupplierCode = pro['SupplierProductCode'];
          this.NewDeliveryForm.patchValue({
            ProductCode: pro['SupplierProductCode'],
            SelectedProductID: pro['ID'],
            selectedCheckValue: null,
            ProductQuantity: 0, 
            ProductReceivedQuantity: 0,
            ProductExceptionQuantity: 0,
            Comments: null,
          });
        }
      }
    }
    else{
      this.NewDeliveryForm.patchValue({
        ProductCode: null,
        SelectedProductID: 0,
        selectedCheckValue: null,
        ProductQuantity: 0,
        ProductReceivedQuantity: 0,
        ProductExceptionQuantity: 0,
        Comments: null,
      });
    }
  }

  onChangeProductCode(SupplierCode) {
    this.NewDeliveryForm.patchValue({
      selectedCheckValue: null,
      ProductQuantity: 0,
      ProductReceivedQuantity: 0,
      ProductExceptionQuantity: 0,
      Comments: null,
    });
    this.errorMessageSelectedProduct = "";
    this.successMessageNewDelivery="";
    this.viewOtherQuantity = false;
    this.InvoiceNewDeliverableLineItemsDetails.SupplierCode = SupplierCode;
    if(SupplierCode!=""){
      for (let i = 0; i < this.product.length; i++) {
        let pro = this.product[i];
        if (pro['SupplierProductCode'] == SupplierCode) {
          this.InvoiceNewDeliverableLineItemsDetails.ProductName = pro['Name'];
          this.SelectedProductID = pro['Name'];
          this.variation(this.SelectedProductID, 0);
          this.InvoiceNewDeliverableLineItemsDetails.SupplierCode = pro['SupplierProductCode'];
          this.NewDeliveryForm.patchValue({
            ProductCode: pro['SupplierProductCode'],
            SelectedProductID: pro['ID'],
            selectedCheckValue: null,
            ProductQuantity: 0, 
            ProductReceivedQuantity: 0,
            ProductExceptionQuantity: 0,
            Comments: null,
          });
        }
      }
    }
    else{
      this.NewDeliveryForm.patchValue({
        ProductCode: null,
        SelectedProductID: 0,
        selectedCheckValue: null,
        ProductQuantity: 0,
        ProductReceivedQuantity: 0,
        ProductExceptionQuantity: 0,
        Comments: null,
      });
    }
  }

  variation(id, value){
    this.invoiceManagementService.getVariations(id).subscribe(
      data =>{
        this.productVariations = data;
        if(this.productVariations.length>0){
        const newValue = { ID: 0, Name: '--Select Product Variation--' };
        this.productVariations.unshift(newValue);
        }
        else{
          this.productVariations = [];
        }
        },
        error => {
          this.errorMessage = "Error while fetching product variation data";
        });
        this.ProductVariation = value;
        this.NewDeliveryForm.patchValue({
          ProductVariation: value,
        });
  }


  getAllProducts() {
    this.productmanagementService.getProducts()
      .subscribe(
        data => {
          this.product = [];
          this.productCopy = data;
          let filtered: any[] = [];
          if(this.SelectedSupplierID!=0){
            for (let i = 0; i < this.productCopy.length; i++) {
              let pro = this.productCopy[i];
              if (pro.SupplierID == this.SelectedSupplierID) {
                filtered.push(pro);
              }
            }
            this.product = filtered;
            if(this.product.length>0){
              const newValue = { ID: 0, Name: '--Select Product--' };
              this.product.unshift(newValue);
              this.NewDeliveryForm.patchValue({
                SelectedProductID: 0,
              });
            }
            if(this.isExited == true){
              var prod = this.productCopy.filter(x=> x.Name == this.SelectedProductName);
              this.InvoiceNewDeliverableLineItemsDetails.ProductName = prod[0].Name;
              this.SelectedProductID = prod[0].ID;
              this.NewDeliveryForm.patchValue({
                SelectedProductID: prod[0].ID,
                ProductCode: prod[0].SupplierProductCode,
                ProductQuantity: 0,
                selectedCheckValue: '',
                ProductReceivedQuantity: 0,
                ProductExceptionQuantity: 0,
                Comments: '',
              });
            }
          }
        },
        error => {
          this.errorMessage = "Error while fetching product data";
          return;
        });
  }

  validNewDeliveryDetails() {
    this.IsValidControls = false;
    if (this.InvoiceNewDeliverableLineItem.SupplierID == null || this.InvoiceNewDeliverableLineItem.SupplierID == 0) {
      this.IsValidControls = true;
      this.errorMessageSuppliers = "Please select supplier";
      return;
    }
    else if (this.InvoiceNewDeliverableLineItem.InvoiceDate == null || this.InvoiceNewDeliverableLineItem.InvoiceDate == "") {
      this.IsValidControls = true;
      this.errorMessageInvoiceDate = "Please select date";
      return;
    }
    else if (this.InvoiceNewDeliverableLineItemsDetails.ProductID == null || this.InvoiceNewDeliverableLineItemsDetails.ProductID == 0) {
      this.IsValidControls = true;
      this.errorMessageSelectedProduct = "Please select product";
      return;
    }
    else if (this.InvoiceNewDeliverableLineItemsDetails.Quantity == null) {
      this.IsValidControls = true;
      this.errorMessageQuantity = "Please enter quantity";
      return;
    }
    else if ((this.InvoiceNewDeliverableLineItemsDetails.Quantity == null || this.InvoiceNewDeliverableLineItemsDetails.Quantity == 0) && this.InvoiceNewDeliverableLineItemsDetails.IsException == true) {
      this.IsValidControls = true;
      this.errorMessageQuantity = "Please enter quantity";
      return;
    }
    else if (this.InvoiceNewDeliverableLineItem.SupplierID == null || this.InvoiceNewDeliverableLineItem.InvoiceDate == null) {
      this.IsValidControls = true;
      return;
    }
    else if (this.InvoiceNewDeliverableLineItemsDetails.ProductID == null || this.InvoiceNewDeliverableLineItemsDetails.Quantity == null) {
      this.IsValidControls = true;
      return;
    }
    else if (this.InvoiceNewDeliverableLineItemsDetails.IsReceived == false && this.InvoiceNewDeliverableLineItemsDetails.IsException == false) {
      this.IsValidControls = true;
      this.errorMessageIsReceived = "Please select Is Received OR Is Exception";
      return;
    }
    else if (this.InvoiceNewDeliverableLineItemsDetails.IsException == true && (this.InvoiceNewDeliverableLineItemsDetails.ReceivedQuantity == null )) {
      this.IsValidControls = true;
      this.errorMessageExceptionAndReceivedQuantity = "";
      this.errorMessageReceivedQuantity = "Please enter received quantity";
      return;
    }
    else if (this.InvoiceNewDeliverableLineItemsDetails.IsException == true && (this.InvoiceNewDeliverableLineItemsDetails.ExceptionQuantity == null)) {
      this.IsValidControls = true;
      this.errorMessageExceptionAndReceivedQuantity = "";
      this.errorMessageExceptionQuantity = "Please enter exception quantity";
      return;
    }
    else if ((this.InvoiceNewDeliverableLineItemsDetails.IsException == true) && this.InvoiceNewDeliverableLineItemsDetails.ExceptionQuantity == 0) {
      this.IsValidControls = true;
      this.errorMessageExceptionAndReceivedQuantity = "";
      this.errorMessageExceptionQuantity = "Exception quantity can't be zero"
      return;
    }
    else if (this.InvoiceNewDeliverableLineItemsDetails.IsException == true && (this.InvoiceNewDeliverableLineItemsDetails.ReceivedQuantity) + (this.InvoiceNewDeliverableLineItemsDetails.ExceptionQuantity) == (this.InvoiceNewDeliverableLineItemsDetails.Quantity) == false) {
      this.errorMessageExceptionQuantity = "";
      this.errorMessageReceivedQuantity = "";
      this.errorMessageExceptionAndReceivedQuantity = "Recevied Quantity and Exception Quantity Not matching with quantity";
      this.IsValidControls = true;
      return;
    }
    else if (this.InvoiceNewDeliverableLineItemsDetails.IsException == true && (this.InvoiceNewDeliverableLineItemsDetails.Comments == "" || this.InvoiceNewDeliverableLineItemsDetails.Comments == null || this.InvoiceNewDeliverableLineItemsDetails.Comments == undefined)) {
      this.IsValidControls = true;
      this.errorMessageExceptionQuantity = "";
      this.errorMessageReceivedQuantity = "";
      this.errorMessageExceptionAndReceivedQuantity = "";
      this.errorMessageComments = "Please enter comments";
      return;
    }
    else {
      this.IsValidControls = false;
    }

    

    if (this.InvoiceNewDeliverableLineItemsDetails.Quantity<0) {
      this.errorMessageQuantity = "Enter proper quantity ";
      this.IsValidControls = true;
      return;
    }
    else {
      this.IsValidControls = false;
    }

    if (this.InvoiceNewDeliverableLineItemsDetails.IsException == true && this.InvoiceNewDeliverableLineItemsDetails.ReceivedQuantity<0) {
      this.errorMessageReceivedQuantity = "Enter proper received quantity ";
      this.IsValidControls = true;
      return;
    }
    else {
      this.IsValidControls = false;
    }

    if (this.InvoiceNewDeliverableLineItemsDetails.IsException == true && this.InvoiceNewDeliverableLineItemsDetails.ExceptionQuantity<0) {
      this.errorMessageExceptionQuantity = "Enter proper exception quantity ";
      this.IsValidControls = true;
      return;
    }
    else {
      this.IsValidControls = false;
    }
    return this.IsValidControls;
  }

  addDetails(){
    this.InvoiceNewDeliverableLineItemsDetails.DeliverableNumber = this.InvoiceNewDeliverableLineItem.DeliverableNumber;
    this.InvoiceNewDeliverableLineItemsDetails.SupplierID = this.SelectedSupplierID;
    if(this.InvoiceNewDeliverableLineItemsDetails.IsReceived == true){
      this.InvoiceNewDeliverableLineItemsDetails.Comments="";
      this.InvoiceNewDeliverableLineItemsDetails.ExceptionQuantity = 0;
      this.InvoiceNewDeliverableLineItemsDetails.ReceivedQuantity = 0;
    }
    if(this.InvoiceNewDeliverableLineItemsDetails.ProductVariationID == null){
      this.InvoiceNewDeliverableLineItemsDetails.ProductVariationID = 0;
    }
    this.pendingDeliveryDetailsService.addNewDeliveryDetails(this.InvoiceNewDeliverableLineItemsDetails).subscribe(
      data=> {
        this.successMessageNewDelivery = "Delivery details added successfully";
        this.DeliverySubmit = false;
        this.pendingDeliveryDetailsService.getDetailsByDeliverableNum(this.InvoiceNewDeliverableLineItem.DeliverableNumber).subscribe(
          data => {
            if(data.length>0){
              this.newDeliveryDetails = data;
              this.productVariations = [];
            }
            else{
              this.newDeliveryDetails = [];
              this.errorMessageDeliverableNumber = "No details found";
            }
          }, error => {}
        );
      },
      error => {
        this.errorMessage = "Error while adding  data";
      }
    );
  }

  addNewDeliveryDetails(){
    if (this.validNewDeliveryDetails() == false) { 
      this.InvoiceNewDeliverableLineItem.IsDeliverySubmitted = false;
      this.addDetails();
      this.viewOtherQuantity = false;
    this.NewDeliveryForm.patchValue({
      SelectedProductID: 0,
      ProductVariation: 0,
      ProductCode: "",
      ProductQuantity: 0,
      selectedCheckValue: null,
      ProductReceivedQuantity: 0,
      ProductExceptionQuantity: 0,
      Comments: "",
    });
    }
  }

  onSelectProductVariation(event){
    let query = event.value;
    this.ProductVariation = query;
    this.InvoiceNewDeliverableLineItemsDetails.ProductVariationID = query;
    if(query>=0){
      for (let j = 0; j < this.newDeliveryDetails.length; j++){
        let newDeliverable = this.newDeliveryDetails[j];
        if(newDeliverable['ProductVariationID']==query && newDeliverable['ProductId']==this.InvoiceNewDeliverableLineItemsDetails.ProductID){
          if(newDeliverable['IsException'] == true){
            this.selectedCheckValues = "Is Exception";
            this.viewOtherQuantity = true;
            }
            else{
              this.selectedCheckValues = "Is Received";
              this.viewOtherQuantity = false;
            }
          this.InvoiceNewDeliverableLineItemsDetails.ReceivedQuantity = newDeliverable['ReceivedQuantity'];
          this.InvoiceNewDeliverableLineItemsDetails.ExceptionQuantity = newDeliverable['ExceptionQuantity'];
          this.InvoiceNewDeliverableLineItemsDetails.Comments = newDeliverable['Comments'];
          this.InvoiceNewDeliverableLineItemsDetails.ProductVariationID = newDeliverable['ProductVariationID'];
          this.InvoiceNewDeliverableLineItemsDetails.SupplierCode = newDeliverable['SupplierCode'];
          this.NewDeliveryForm.patchValue({
            ProductVariation: newDeliverable['ProductVariationID'],
            ProductCode: newDeliverable['SupplierProductCode'],
            ProductQuantity:newDeliverable['Quantity'],
            selectedCheckValue: this.selectedCheckValues,
            ProductReceivedQuantity: newDeliverable['ReceivedQuantity'],
            ProductExceptionQuantity: newDeliverable['ExceptionQuantity'],
            Comments: newDeliverable['Comments'],
        });
      }
      else{
        this.viewOtherQuantity = false;
      this.NewDeliveryForm.patchValue({
        ProductVariation: query,
        ProductQuantity: 0,
        selectedCheckValue: null,
        ProductReceivedQuantity: 0,
        ProductExceptionQuantity: 0,
        Comments: "",
      });
    }
    }
    }
    
  }

  formatDate(date) {
    if (date != null && date != "") {
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();

      return year + '-' + (monthIndex < 9 ? '0' + (monthIndex + 1) : (monthIndex + 1)) + '-' + (day <= 9 ? '0' + day : day);
    }
    else {
      return null;
    }
  }

  getDetailsByDeliverableNum(searchDeliverableNumber){
    this.successMessage="";
    this.errorMessageDeliverableNumber = "";
    this.errorMessageNewDelivery = "";
    this.successMessageNewDelivery="";
    this.infoMessageSubmitted = "";
    this.viewOtherQuantity = false;
    this.InvoiceNewDeliverableLineItem.DeliverableNumber = searchDeliverableNumber;
    if(searchDeliverableNumber == 0 || searchDeliverableNumber == null){
      this.NewDeliveryForm.controls['SupplierID'].enable();
      this.NewDeliveryForm.controls['SupplierDate'].enable();
    }
    this.NewDeliveryForm.patchValue({
      DeliverableNumber: this.InvoiceNewDeliverableLineItem.DeliverableNumber,
      SupplierID: 0,
      SelectedProductID: 0,
      SupplierDate: null,
      ProductVariation: 0,
      ProductCode: "",
      ProductQuantity: 0,
      selectedCheckValue: null,
      ProductReceivedQuantity: 0,
      ProductExceptionQuantity: 0,
      Comments: "",
    });
    if (this.InvoiceNewDeliverableLineItem.DeliverableNumber != null || this.InvoiceNewDeliverableLineItem.DeliverableNumber!=0) {
      this.newDeliveryDetails = [];
      if(this.InvoiceNewDeliverableLineItem.DeliverableNumber<0){
        this.errorMessageDeliverableNumber = "Enter proper Deliverable Number ";
        this.InvoiceNewDeliverableLineItem.SupplierID = 0;
        this.InvoiceNewDeliverableLineItem.DeliverableNumber = 0;
        this.NewDeliveryForm.patchValue({
          DeliverableNumber: 0,
          SupplierID: this.InvoiceNewDeliverableLineItem.SupplierID,
          SelectedProductID: 0,
        });
      }
      if (this.InvoiceNewDeliverableLineItem.DeliverableNumber>0) {
        this.pendingDeliveryDetailsService.getDetailsByDeliverableNum(this.InvoiceNewDeliverableLineItem.DeliverableNumber)
          .subscribe(
            data => {
              if (data.length > 0) {
                this.InvoiceNewDeliverableLineItem.InvoiceDate = null;
                this.newDeliveryDetails = data;
                this.DeliverySubmit = data[0].IsDeliverySubmitted;
                this.ProductsExist = data[0].ProductsExists;
                this.addDeliveryDisable = true;
                if (this.DeliverySubmit == true) {
                  this.addNewDeliveryDetDisable = true;
                  this.infoMessageSubmitted = "This details already submitted";
                }
                this.addNewDeliveryDetDisable = false;
                this.searchedDeliverableNum = true;
                this.disableDetails = true;
                this.InvoiceNewDeliverableLineItem.SupplierID = this.newDeliveryDetails[0].SupplierID;
                this.SelectedSupplierID = this.InvoiceNewDeliverableLineItem.SupplierID;
                if (this.SelectedSupplierID > 0) {
                  let filtered: any[] = [];
                  for(let j=0; j<this.suppliers.length; j++){
                    let sup = this.suppliers[j];
                    if(sup['ID']==this.SelectedSupplierID){
                      this.SupplierName = sup['Name'];
                    }
                  }
                  for (let i = 0; i < this.productCopy.length; i++) {
                    let pro = this.productCopy[i];
                    if (pro.SupplierID == this.SelectedSupplierID) {
                      filtered.push(pro);
            
                    }
                  }
                  this.product = filtered;
                  if(this.product.length>0){
                  const newValue = { ID: 0, Name: '--Select Product--' };
                  this.product.unshift(newValue);
                  }
                }
                var deliverableDate = this.formatDate(new Date(this.newDeliveryDetails[0].DeliveryDate));
                this.InvoiceNewDeliverableLineItem.InvoiceDate = deliverableDate;
                this.NewDeliveryForm.patchValue({
                  DeliverableNumber: this.InvoiceNewDeliverableLineItem.DeliverableNumber,
                  SupplierID: this.SelectedSupplierID,
                  SupplierDate: this.InvoiceNewDeliverableLineItem.InvoiceDate,
                });
                this.NewDeliveryForm.controls['SupplierID'].disable();
                this.NewDeliveryForm.controls['SupplierDate'].disable();
              }
              else {
                this.addNewDeliveryDetDisable = true;
                this.searchedDeliverableNum = false;
                this.newDeliveryDetails = [];
                this.InvoiceNewDeliverableLineItem.SupplierID = 0;
                this.SelectedSupplierID = this.InvoiceNewDeliverableLineItem.SupplierID;
                this.errorMessageNewDelivery = "";
                this.errorMessageDeliverableNumber = "No details found";
                this.addDeliveryDisable = false;
                this.NewDeliveryForm.patchValue({
                  SupplierID: this.SelectedSupplierID,
                  SupplierDate: null,
                });
                this.NewDeliveryForm.controls['SupplierID'].enable();
                this.NewDeliveryForm.controls['SupplierDate'].enable();
              }
            });
    }

  }
  else {
    this.newDeliveryDetails = [];
    this.errorMessageDeliverableNumber = "Enter  Delivery Number "; 
    this.NewDeliveryForm.patchValue({
      SupplierID: 0,
      SupplierDate: null,
    });
    this.NewDeliveryForm.controls['SupplierID'].enable();
    this.NewDeliveryForm.controls['SupplierDate'].enable();
  }
}

  onRowEditInit(newDeliveryDetails){
    if(newDeliveryDetails.IsException == true){
    this.selectedCheckValues = "Is Exception";
    this.viewOtherQuantity = true;
    }
    else{
      this.selectedCheckValues = "Is Received";
      this.viewOtherQuantity = false;
    }
    this.variation(newDeliveryDetails.ProductId, newDeliveryDetails.ProductVariationID);
    this.InvoiceNewDeliverableLineItemsDetails.ProductID = newDeliveryDetails.ProductId;
    this.InvoiceNewDeliverableLineItemsDetails.ReceivedQuantity = newDeliveryDetails.ReceivedQuantity;
    this.InvoiceNewDeliverableLineItemsDetails.ExceptionQuantity = newDeliveryDetails.ExceptionQuantity;
    this.InvoiceNewDeliverableLineItemsDetails.Comments = newDeliveryDetails.Comments;
    this.InvoiceNewDeliverableLineItemsDetails.ProductVariationID = newDeliveryDetails.ProductVariationID;
    this.NewDeliveryForm.patchValue({
      SelectedProductID: newDeliveryDetails.ProductId,
      ProductVariation: newDeliveryDetails.ProductVariationID,
      ProductCode: newDeliveryDetails.SupplierProductCode,
      ProductQuantity: newDeliveryDetails.Quantity,
      selectedCheckValue: this.selectedCheckValues,
      ProductReceivedQuantity: newDeliveryDetails.ReceivedQuantity,
      ProductExceptionQuantity: newDeliveryDetails.ExceptionQuantity,
      Comments: newDeliveryDetails.Comments,
    });
  }

  onRowEditCancel(newDeliveryDetails, ri, event){
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete this Invoice Line Item?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteDeliverableInv(newDeliveryDetails);
      },
      reject: () => {
      }
  });
  }

  deleteDeliverableInv(newDeliveryDetails){
    if(newDeliveryDetails.ProductVariationID==null){
      newDeliveryDetails.ProductVariationID = 0;
    }
    this.pendingDeliveryDetailsService.deleteDeliverableDetails(this.InvoiceNewDeliverableLineItem.DeliverableNumber, newDeliveryDetails.ProductId, newDeliveryDetails.ProductVariationID).subscribe(
      data=> {
        this.pendingDeliveryDetailsService.getDetailsByDeliverableNum(this.InvoiceNewDeliverableLineItem.DeliverableNumber).subscribe(
          data => {
            if(data.length>0){
              this.successMessageNewDelivery = "Delivery details deleted successfully";
              this.newDeliveryDetails = data;
            }
            else{
              this.newDeliveryDetails = [];
              this.successMessageNewDelivery = "Delivery details deleted successfully";
            }
          },
          error =>{}
        );
    },
    error=>{});

  }

  deliverySubmit(position: string) {
    this.position = position;
    if(this.newDeliveryDetails.length>0){
    this.InvoiceNewDeliverableLineItem.IsDeliverySubmitted = true;
    this.InvoiceNewDeliverableLineItem.AllLineItemData = this.newDeliveryDetails;
    this.confirmationService.confirm({
      header: 'Are you sure to submit this delivery?',
      message: "This submission will submit the current delivery as well allocate all the quantity to mapped listings",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.InvoiceNewDeliverableLineItem.CreatedBy = localStorage.getItem("userId");
        this.pendingDeliveryDetailsService.addNewDelivery(this.InvoiceNewDeliverableLineItem).subscribe(
          data=> {
            this.successMessage="";
            this.successMessageNewDelivery = "";
            this.addDeliveryDisable = false;
                  this.successMessageSubmitDelivery = 'Delivery submited successfully!';
                  this.newDeliveryDetails = [];
                  this.addNewDeliveryDetDisable = true;
                  this.NewDeliveryForm.patchValue({
                    ID: 0,
                    DeliverableNumber: 0,
                    SupplierID: 0,
                    SupplierDate: 0,
                    SelectedProductID: -1,
                    ProductVariation: 0,
                    ProductCode: '',
                    ProductQuantity: 0,
                    selectedCheckValue: null,
                    ProductReceivedQuantity: 0,
                    ProductExceptionQuantity: 0,
                    Comments: null,
                  });
                  this.NewDeliveryForm.controls['ProductCode'].setErrors(null);
                  this.NewDeliveryForm.controls['SupplierID'].enable();
                  this.NewDeliveryForm.controls['SupplierDate'].enable();
          }
        )
      },
      reject: () => {
        this.errorMessage = "You have cancelled";
      },
      key: "positionDialog"
    });
    }
    else{
      this.errorMessageNewDelivery = "There is no details to submit";
    }

  }

  receiveDeliverableLineItem(InvoiceDeliverableItem) {
    this.router.navigate(['/AdminDashboard/Pricing/DeliveryDetails/'], {
      queryParams: { invoiceNo: InvoiceDeliverableItem.SupplierInvoiceID, supplierId: InvoiceDeliverableItem.SupplierID, isExceptionOrReceive: this.isExceptionOrReceive}
   });
  }

  clear(table: Table) {
    table.clear();
    this.inputSearch = "";
    this.receiveSupplierID = 0;
    this.recevieInvoiceNo = "";
    this.selectedCategory = this.categories[0];
    this.InvoiceDeliverableItems = this.InvDeliverableItems;
    this.tempAllItems = this.InvoiceDeliverableItems;
    this.onChangeCategory(this.selectedCategory);
  }

  reload(){
    this.InvDeliverableItems.length = 0;
    this.selectedCategory = this.categories[0];
    this.getPendingDetails();
    this.InvoiceDeliverableItems = [];
    this.NewDeliveryForm.patchValue({
      ID: '',
      DeliverableNumber: '',
      SupplierID: 0,
      SupplierDate: '',
      SelectedProductID: '',
      ProductVariation: 0,
      ProductCode: '',
      ProductQuantity: '',
      selectedCheckValue: '',
      ProductReceivedQuantity: '',
      ProductExceptionQuantity: '',
      Comments: '',
    });
    this.getAllProducts();
  }
}
