<!--<div class="app-outer-container page-background">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-6">
        <div class="card card-body card-radius">
          <h2 class="headingalign text-center">Forgot Password</h2>
          <form name="form" (ngSubmit)="f.form.valid && ForgetPassword()" #f="ngForm">
            <div class="form-group row">
              <div class="col-md-12 searchtextAlign">
                <p><small>Enter your email address below to reset your password.</small></p>
              </div>
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" [disabled]="loading" class="form-control" name="email" placeholder="Email address" [(ngModel)]="forgetpassword.email"
                         #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email autocomplete="off"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required" class="leftpadding">Email is required</div>
                    <div *ngIf="email.errors.email" class="leftpadding">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="form-group row">
              <div class="col-md-12">
                <button type="submit" [disabled]="loading" class="btn round-button red-grad-button buttonMargin">Submit</button>
                <span *ngIf="loading" class="rightalign">
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </span>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="validationerror">
                  <span><small>{{validationerror}} <br /><kbd *ngIf="resendtext"><span><a (click)="ResendLink()">or Click here to resend email verification link</a></span></kbd></small></span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-3">
      </div>
    </div>
  </div>
</div>-->
<!--<div class="app-outer-container page-background">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-6">
        <div class="card card-body card-radius">
          <h2 class="headingalign text-center">Sign in</h2>
          <form name="form" (ngSubmit)="f.form.valid && Userlogin()" #f="ngForm">
            <div class="form-group row">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" name="email" placeholder="Email" [(ngModel)]="login.email"
                         #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email autocomplete="off"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required" class="leftpadding">Email is required</div>
                    <div *ngIf="email.errors.email" class="leftpadding">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="form-group row">
              <div class="col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></span>
                  </div>
                  <input type="password" class="form-control" name="password" placeholder="Password"
                         [(ngModel)]="login.password" #password="ngModel"
                         [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" autocomplete="off"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
                    <div *ngIf="password.errors.required" class="leftpadding">Password is required</div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="form-group row">
              <div class="col-md-12">
                <button type="submit" class="btn round-button red-grad-button buttonMargin">Sign in</button>
                <button type="button" class="btn round-button red-grad-button mgl-20 buttonMargin" [routerLink]='["/register"]'>Register</button>
                <span *ngIf="loading" class="rightalign">
                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </span>
              </div>
              <div class="col-md-12 buttonMargin">
                <a [routerLink]='["/ForgetPassword"]'>Forgot password?</a>
              </div>
              <div class="col-md-12">
                <p class="alert alert-danger" *ngIf="validationerror">
                  <span><small>{{validationerror}} <br /><kbd *ngIf="resendtext"><span><a (click)="ResendLink()">or Click here to resend email verification link</a></span></kbd></small></span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-3">
      </div>
    </div>
  </div>
</div>-->


<div class=" main-content">
  <div class=" header bg-gradient-danger py-7 py-lg-8 pt-lg-9">
    <div class=" container">
      <div class=" header-body text-center mb-7">
        <div class=" row justify-content-center">
          <div class=" col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class=" text-white">Forgot Password!</h1>

            <p class=" text-lead text-white">
              Retail Helper
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0"
           y="0"
           viewBox="0 0 2560 100"
           preserveAspectRatio="none"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </div>

  <div class=" container mt--8 pb-5">
    <div class=" row justify-content-center">
      <div class=" col-lg-5 col-md-7">
        <div class=" card bg-secondary border-0 mb-0">
          <div class=" card-body px-lg-5 py-lg-5">
            <div class=" text-center text-muted mb-4">
              <small> Enter your email address below to reset your password. </small>
            </div>

            <form name="form" (ngSubmit)="f.form.valid && ForgetPassword()" #f="ngForm">
              <div class="form-group mb-3"
                   [ngClass]="{ focused: focus === true }">
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="ni ni-email-83"></i>
                    </span>
                  </div>
                  <!--<input class="form-control"
                  placeholder="Email"
                  type="email"
                  (focus)="focus = true"
                  (blur)="focus = false" />-->

                  <input type="text" [disabled]="loading" class="form-control" name="email" placeholder="Email address" [(ngModel)]="forgetpassword.email"
                         #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" email autocomplete="off"
                         (keydown.space)="$event.preventDefault()" required />
                  <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                    <div *ngIf="email.errors.required" class="leftpadding">Email is required</div>
                    <div *ngIf="email.errors.email" class="leftpadding">Email must be a valid email address</div>
                  </div>
                </div>
              </div>

              <div class="text-center">
                <!--<button type="button" class="btn btn-primary my-4">
                  Sign in
                </button>-->
                <!--<button type="submit" class="btn btn-primary my-4">Sign in</button>-->

                <div class="col-md-12">
                  <button type="submit" [disabled]="loading" class="btn btn-primary my-4">Submit</button>
                  <span *ngIf="loading" class="rightalign">
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </span>
                </div>
                <div class="col-md-12">
                  <p class="alert alert-danger" *ngIf="validationerror">
                    <span><small>{{validationerror}} <br /><kbd *ngIf="resendtext"><span><a (click)="ResendLink()">or Click here to resend email verification link</a></span></kbd></small></span>
                  </p>
                </div>

              </div>
            </form>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
