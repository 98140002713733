import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { HttpClientModule, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { NoCacheHeadersInterceptor } from './Cache-Interceptor';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

/** Custom modules import */
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

/** PrimeNg module import */
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DataViewModule } from 'primeng/dataview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ConfirmationService, MessageService } from "primeng/api";
import { TooltipModule } from 'primeng/tooltip';
import {TabViewModule} from 'primeng/tabview';

/**Theme component import */
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { NavMenuComponent } from './layouts/nav-menu/nav-menu.component';
import { LayoutAdminComponent } from './layout-admin/layout-admin.component';


import { AppRoutingModule } from './app-routing.module';
import { RetailHelperInterceptorService } from './retail-helper-interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidebarComponent,
    FooterComponent,
    NavMenuComponent,
    LayoutAdminComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    BsDropdownModule.forRoot(),
    PerfectScrollbarModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    //FormBuilder,
    //FormGroup,
    //FormArray,
    SharedModule,
    //NgModule,
    TableModule,
    InputTextModule,
    PanelModule,
    DropdownModule,
    CheckboxModule,
    CollapseModule,
    BrowserAnimationsModule,
    ButtonModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    ToastModule,
    ProgressBarModule,
    AutoCompleteModule,
    DataViewModule,
    RadioButtonModule,
    ConfirmPopupModule,
    TooltipModule,
    TabViewModule,
    RouterModule.forRoot([
    ]),
  ],
  //exports: [FormBuilder],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: RetailHelperInterceptorService,
    multi: true
  },ConfirmationService, MessageService, ],
  bootstrap: [AppComponent],
})
export class AppModule { }
