import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//Import Models
import { Supplier } from '../../models/supplier';
//Import Service
import { SupplierService } from '../../services/supplier.service';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ProductComponent } from '../product/product.component';
import { InvoiceComponent } from '../../../pricing/Components/invoice/invoice.component';
import { PendingDeliveryDetailsComponent } from '../../../pricing/Components/pending-delivery-details/pending-delivery-details.component';
import { NavMenuComponent } from '../../../../layouts/nav-menu/nav-menu.component';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css'],
  providers: [SupplierService, MessageService, PendingDeliveryDetailsComponent, NavMenuComponent, InvoiceComponent],
  preserveWhitespaces: true
})
export class SupplierComponent implements OnInit {
  @Input() isShownSupplierForm: boolean = false;
  @Input() header: boolean = true;
  @Input() grid: boolean = true;
  @Output() isSupVal = new EventEmitter<any>();
  public supplier: Supplier = new Supplier(null, null, null);
  clonedSupplier: { [s: string]: Supplier; } = {};
  supplierForm: FormGroup;
  supplierViewForm: FormGroup;
  //public isShownSupplierForm: boolean;
  errorMessage = "";
  successMessage = "";
  public suppliers = [];
  public loading: boolean = false;
  public inputSearch = "";
  errorMessageSup: string = "";
  successMessageSup: string = "";
  supValue: string = "";

  constructor(
    public supplierService: SupplierService,
    private messageService: MessageService,
    private fromBuilder: FormBuilder,
    private productComponent: ProductComponent,
    private pendingDetails: PendingDeliveryDetailsComponent) { }

  ngOnInit(): void {
    this.isShownSupplierForm = false;
    this.errorMessage = "";
    this.successMessage = "";
    this.supplierForm = this.fromBuilder.group({
      ID: [''],
      Name: ['', Validators.required,],
      IsDeleted: [false],
    });
    this.supplierForm.controls['Name'].setErrors(null);
    this.getAllSuppliers();
  }
  get f() { return this.supplierForm.controls }

  showSupplierForm() {
    this.errorMessage = "";
    this.successMessage = "";
    this.clearSupplierForm();
    this.isShownSupplierForm = true;
  }

  Close(){
    this.errorMessageSup = "";
    this.successMessageSup = "";
    this.isShownSupplierForm = false;
    this.clearSupplierForm();
    this.isSupVal.emit({isShownSup: this.isShownSupplierForm, supValue: this.supValue});
  }

  showAllSuppliers() {
    this.isShownSupplierForm = false;
  }

  addSupplier() {
    this.errorMessage = "";
    this.successMessage = "";
    if (this.supplierForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      return;
    }
    this.supplierForm.value["ID"] = this.supplierForm.value["ID"] != 0 ? this.supplierForm.value["ID"] : 0;
    //this.supplierService.saveSupplier(this.supplierForm.value)
    //  .subscribe(
    //    data => {
    //      this.successMessage = "Supplier Added";
    //      this.getAllSuppliers();
    //      return;
    //    },
    //    error => {
    //      this.errorMessage = "Error while adding supplier data";
    //      return;
    //    })

    if (this.supplierForm.value["ID"] > 0) {
      this.updateSupplier(this.supplierForm.value);
    }
    else {
      this.saveSupplier(this.supplierForm.value);
    }
  }


  getAllSuppliers() {
    this.loading = true;
    this.supplierService.getAllSuppliers()
      .subscribe(
        data => {
          //this.supplierService.suppliers = data;
          this.suppliers = data;
          this.loading = false;
        },
        error => {
          this.errorMessage = "Error while fetching supplier data";
          return;
        })
  }



  saveSupplier(supplier: Supplier) {
    this.supValue = this.supplierForm.get('Name').value;
    this.supplierService.saveSupplier(this.supplierForm.value)
      .subscribe(
        data => {
          this.clearSupplierForm();
          this.successMessageSup = "Supplier Added";
          this.errorMessageSup = "";
          this.productComponent.getAllSuppliers();
          this.pendingDetails.addNewDelivery();
          this.getAllSuppliers();
          return;
        },
        error => {
          this.errorMessageSup = "Error while saving supplier data";
          this.successMessageSup = "";
          return;
        });

  }

  updateSupplier(supplier: Supplier) {
    this.supValue = this.supplierForm.get('Name').value;
    this.supplierService.updateSupplier(supplier)
      .subscribe(
        data => {
          this.clearSupplierForm();
          this.errorMessageSup = "";
          this.successMessageSup = "Supplier Updated";
          this.pendingDetails.getAllSuppliers();
          this.getAllSuppliers();
          return;
        },
        error => {
          this.successMessageSup = "";
          this.errorMessageSup = "Error while updating supplier data";
          return;
        })

  }

  deleteSupplier(supplier) {
    this.errorMessage = "";
    this.successMessage = "";
    this.supplierService.deleteSupplier(supplier)
      .subscribe(
        data => {
          this.successMessage = "Supplier Deleted";
        },
        error => {
          this.errorMessage = "Error while deleting supplier data";
          return;
        })

  }



  hideMessage() {
    setTimeout(() => {
      this.errorMessage = "";
    }, 5000);
  }
  clear(table: Table) {
    table.clear();
    this.inputSearch = "";
  }

  clearSupplierForm() {
    this.supplierForm.setValue({
      ID: 0,
      Name: '',
      IsDeleted: false,
    });
    this.supplierForm.controls['Name'].setErrors(null);
  }

  onRowEditInit(supplier: Supplier) {
    this.successMessage = "";
    this.errorMessage = "";
    this.isShownSupplierForm = true;;
    this.clonedSupplier[supplier.ID] = { ...supplier };
    this.supplierForm.setValue({
      ID: this.clonedSupplier[supplier.ID].ID,
      Name: this.clonedSupplier[supplier.ID].Name,
      IsDeleted: this.clonedSupplier[supplier.ID].IsDeleted,
    });
  }

  onRowEditCancel(supplier: Supplier, index: number) {
    this.errorMessage = "";
    this.successMessage = "";
    this.suppliers[index] = this.clonedSupplier[supplier.ID];
    delete this.suppliers[supplier.ID];
    this.deleteSupplier(supplier);
    //this.getAllSuppliers();
  }

  onRowEditSave(supplier: Supplier) {
    if (supplier.ID > 0) {
      delete this.clonedSupplier[supplier.ID];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Supplier is updated' });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error is updating supplier detail' });
    }
  }

}
