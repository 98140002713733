import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceLineItems } from '../models/invoice-line-items.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
   }

   getAllPendingAlerts(IsReviewed): Observable<any> {
    return this.http.get('/api/Alerts/getPendingAlerts/'+IsReviewed, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getAllLatestPendingAlerts(IsReviewed): Observable<any> {
    return this.http.get('/api/Alerts/getPendingLatestAlerts/'+IsReviewed, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getCountPendingAlerts(): Observable<any> {
    return this.http.get('/api/Alerts/getCountPendingAlerts', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getCountLatestPendingAlerts(): Observable<any> {
    return this.http.get('/api/Alerts/getCountLatestPendingAlerts', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getCountExceptionDetails(): Observable<any> {
    return this.http.get('/api/Alerts/getCountExceptionDetails', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getLatestCountExceptionDetails(): Observable<any> {
    return this.http.get('/api/Alerts/getCountLatestExceptionDetails', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getAllDeliveryExceptionAlerts(): Observable<any> {
    return this.http.get('/api/Alerts/getAllDeliveryExceptionAlerts', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getAllLatestDeliveryExceptionAlerts(): Observable<any> {
    return this.http.get('/api/Alerts/getAllLatestDeliveryExceptionAlerts', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  markAlertsToReviewed(alertID: number): Observable<any> {
    return this.http.get('/api/Alerts/markAlertsToReviewed/'+alertID, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  addProductAlert(invoice: InvoiceLineItems){
    return this.http.post('/api/Alerts/addProductAlert', invoice, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

}
