import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MapDeliverableService {
  public httpOptions: {};
  
  constructor(private http: HttpClient) { 
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getUnmapDeliverableDetails(): Observable<any> {
    return this.http.get('/api/GetUnMapDeliverable/getUnMapDeliverables', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }
  
  getInvoiceDetailsToMap(DeliverableNo, SupplierID, IsInvoiceReceived, InvoiceNo): Observable<any> {
    return this.http.get('/api/GetUnMapDeliverable/MapDeliverableDetails/'+DeliverableNo+'/'+SupplierID+'/'+IsInvoiceReceived+'/'+InvoiceNo, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

}
