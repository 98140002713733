<!--<div class="app-outer-container page-background">
    <div class="container">
        <div class="card card-body card-radius pnf">
            <h3 class="blinking">Page not found!</h3>
        </div>
    </div>
</div>-->


<div class=" main-content">
  <div class=" header bg-gradient-danger py-7 py-lg-8 pt-lg-9">
    <div class=" container">
      <div class=" header-body text-center mb-7">
        <div class=" row justify-content-center">
          <div class=" col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 class=" text-white">Page not found!</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg x="0"
           y="0"
           viewBox="0 0 2560 100"
           preserveAspectRatio="none"
           version="1.1"
           xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </div>

 
</div>
