<div class=" header bg-danger" *ngIf="header">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">
          <h6 class=" h2 text-white d-inline-block mb-0">Product</h6>
          <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
            <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> Product </a>
              </li>
              <li aria-current="page" class=" breadcrumb-item active">Product</li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">
          <button class=" btn btn-sm btn-neutral" type="button" label="New" (click)="showProductForm()" pButton></button>
          <button class=" btn btn-sm btn-neutral" type="button" label="Show Products" (click)="showAllProducts()" pButton></button>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog header="Product" [closable]="false" [(visible)]="isShownProductForm" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '75%'}" [baseZIndex]="10000" [modal]="true">
  <div class="offset-lg-11 justify-content-end">
    <button  class="p-button-rounded p-button-secondary p-button-text btn-close" type="button" pButton pRipple (click)="Close()" icon="pi pi-times">
    </button></div>
  <form [formGroup]="productForm">
    <div class=" card">
      <div class=" card-body">
        <div class="form-group row">

          <div class="col-md-6 col-lg-3 col-sm-12">
            <div class="card" style="min-height: 380px;">
              <div class=" card-header">
                <h3 class=" mb-0">Product Attributes</h3>
              </div>
              <div class=" card-body">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input type="text" id="inputtext-left" class="form-control" formControlName="Name" pInputText>
                      <label for="inputtext-left">Product Name</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="suppliers" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                                  optionLabel="Name" optionValue="ID" formControlName="SupplierID" [(ngModel)]="selectedSupID">
                      </p-dropdown>
                      <label for="inputtext-left">Select Supplier</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="SupplierProductCode" class="form-control" type="text" formControlName="SupplierProductCode" pInputText>
                      <label for="inputtext-left">Supplier ProductCode</label>
                    </span>

                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="BarCode" class="form-control" type="text" formControlName="BarCode" pInputText>
                      <label for="inputtext-left">BarCode</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 col-sm-12">
            <div class="card" style="min-height: 380px;">
              <div class=" card-header">
                <h3 class=" mb-0">Product Setting</h3>
              </div>
              <div class=" card-body">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-checkbox binary="true" class="form-control" label="Is MultiPack" inputId="IsMultiPack" formControlName="IsMultiPack"></p-checkbox>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-checkbox binary="true" class="form-control" label="Has Variations" inputId="HasVariations" formControlName="HasVariations"></p-checkbox>
                    </span>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="vatTypes" [virtualScroll]="true" [filter]="false"
                                  optionLabel="VATCode" optionValue="ID" formControlName="VatTypeID" [autoDisplayFirst]="false"></p-dropdown>
                      <label for="inputtext-left">Select VatType</label>
                    </span>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <span class="p-fluid">
                        <p-autoComplete inputId="dropdown" styleClass="primeng-dropdown" [suggestions]="locationsForMap" (completeMethod)="filterLocations($event)" field="Label_FinalLocationName" [multiple]="true"
                                        formControlName="SelectedLocationIDs" optionLabel="Label_FinalLocationName" optionValue="ID">
                        </p-autoComplete>
                        <label for="inputtext-left">Select Locations</label>
                      </span>

                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 col-sm-12">
            <div class="card" style="min-height: 380px;">
              <div class=" card-header">
                <h3 class=" mb-0">Product Dimension</h3>
              </div>

              <div class=" card-body">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="ProductUnitWeight" class="form-control" type="number" formControlName="ProductUnitWeight" pInputText>
                      <label for="inputtext-left">Product UnitWeight</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="ProductLength" class="form-control" type="number" formControlName="ProductLength" pInputText>
                      <label for="inputtext-left">Product Length</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="ProductHeight" class="form-control" type="number" formControlName="ProductHeight" pInputText>
                      <label for="inputtext-left">Product Height</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="ProductWidth" class="form-control" type="number" formControlName="ProductWidth" pInputText>
                      <label for="inputtext-left">Product Width</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 col-sm-12">
            <div class="card" style="min-height: 380px;">
              <div class=" card-header">
                <h3 class=" mb-0">Package Dimension</h3>
              </div>

              <div class=" card-body">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="PackedUnitWeight" class="form-control" type="number" formControlName="PackedUnitWeight" pInputText>
                      <label for="inputtext-left">Packed UnitWeight</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="PackedLength" class="form-control" type="number" formControlName="PackedLength" pInputText>
                      <label for="inputtext-left">Packed Length</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="PackedHeight" class="form-control" type="number" formControlName="PackedHeight" pInputText>
                      <label for="inputtext-left">Packed Height</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input id="PackedWidth" class="form-control" type="number" formControlName="PackedWidth" pInputText>
                      <label for="inputtext-left">Packed Width</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-6 col-lg-3 col-sm-12">
            <div class="card" style="min-height: 380px;">
              <div class=" card-header">
                <h3 class=" mb-0">Shipping Information</h3>
              </div>
              <div class=" card-body">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="packageFormats" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                                  optionLabel="Name" optionValue="ID" formControlName="RoyalMailPackageFormat">
                      </p-dropdown>
                      <label for="inputtext-left">Select PackageFormater</label>
                    </span>
                  </div>
                </div>

                <!--<div ng-show="packageFormats.length > 0">
                  <div *ngFor="let packageFormat of packageFormats">
                    <div class="form-group row" *ngIf="packageFormat.ID > 0">
                      <div class="col-md-12">
                        <span class="p-float-label">
                          <input type="number" id="inputtext-left" class="form-control" formControlName="MaxQuantityfor{{packageFormat.Name | removeSpaces}}" min="0" required pInputText>
                          <label for="inputtext-left">Max Quantity for {{packageFormat.Name}}</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>-->

                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input type="number" id="inputtext-left" class="form-control" formControlName="MaxQuantityforLetter" (keypress)="numberOnly($event)" pInputText>
                      <label for="inputtext-left">Max Quantity for Letter</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input type="number" id="inputtext-left" class="form-control" formControlName="MaxQuantityforLargeLetter" (keypress)="numberOnly($event)" pInputText>
                      <label for="inputtext-left">Max Quantity for Large Letter</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input type="number" id="inputtext-left" class="form-control" formControlName="MaxQuantityforParcel" (keypress)="numberOnly($event)" pInputText>
                      <label for="inputtext-left">Max Quantity for Parcel</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 col-sm-12">
            <div class="card" style="min-height: 380px;">
              <div class=" card-header">
                <h3 class=" mb-0">Carrier Preference</h3>
              </div>
              <div class=" card-body">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="carriers1" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false" (onChange)="onSelectcarriers1Change($event)"
                                  optionLabel="CarrierName" optionValue="ID" formControlName="selectedCarriersID1" [(ngModel)]="selectedCarriersID1">
                      </p-dropdown>
                      <label for="inputtext-left">Carriers (Preference 1)</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="carriers2" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false" (onChange)="onSelectcarriers2Change($event)"
                                  optionLabel="CarrierName" optionValue="ID" formControlName="selectedCarriersID2" [disabled]="isDisabledCarriersID2" [(ngModel)]="selectedCarriersID2">
                      </p-dropdown>
                      <label for="inputtext-left">Carriers (Preference 2)</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="carriers3" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                                  optionLabel="CarrierName" optionValue="ID" formControlName="selectedCarriersID3" [disabled]="isDisabledCarriersID3" [(ngModel)]="selectedCarriersID3">
                      </p-dropdown>
                      <label for="inputtext-left">Carriers (Preference 3)</label>
                    </span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--for Seasons-->
          <div class="col-md-6 col-lg-3 col-sm-12">
            <div class="card" style="min-height: 380px;">
              <div class=" card-header">
                <h3 class=" mb-0">Product Details</h3>
              </div>
              <div class=" card-body">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="seasons" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                                  optionLabel="Name" optionValue="ID" formControlName="SeasonID" [(ngModel)]="selectedSeasonID">
                      </p-dropdown>
                      <label for="inputtext-left">Select Season</label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--End Seasons-->
        </div>

        <button type="button" label="Submit" (click)="addProduct()" pButton></button>
        <label ng-model="successMessage" ng-bind="successMessage" class="success">{{successMessage}}</label>
        <label ng-model="errorMessage" ng-bind="errorMessage" class="error">{{errorMessage}}</label>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header="Add Product Variation" [(visible)]="isShownAddProductVariationForm" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '800px'}">
  <form [formGroup]="addProductVariationOptionForm">
    <div class=" card">
      <div class=" card-body">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="inputtext-left">Add Option (e.g: Design)</label>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <span class="p-float-label">
              <input type="text" id="inputtext-left" class="form-control" formControlName="OptionName" pInputText>
              <label for="inputtext-left">Enter option name</label>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <button type="button" label="Add" (click)="addProductVariationOption()" pButton></button>
            <label ng-model="successMessageVariationOption" ng-bind="successMessageVariationOption" class="success">{{successMessageVariationOption}}</label>
            <label ng-model="errorMessageVariationOption" ng-bind="errorMessageVariationOption" class="error">{{errorMessageVariationOption}}</label>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form [formGroup]="addNewProductVariationForm">
    <div class=" card">
      <div class=" card-body">
        <div class="form-group row">
          <div class="col-md-12">
            <label for="inputtext-left">Add Variation</label>
          </div>
        </div>
        <div class="form-group row" hidden>
          <div class="col-md-12">
            <span class="p-float-label">
              <input type="text" id="inputtext-left" class="form-control" formControlName="ProductID" pInputText>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <span class="p-float-label">
              <input type="text" id="inputtext-left" class="form-control" formControlName="ProductName" pInputText disabled>
              <label for="inputtext-left">Product Name</label>
            </span>
          </div>
        </div>

        <div formArrayName="items" *ngFor="let item of items.controls; let i = index">
          <div formGroupName="{{i}}">
            <div class="form-group row">
              <div class="col-md-5">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="productVariationOptions" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                                  optionLabel="Name" optionValue="Name" formControlName="Name" (onChange)="onSelectProductVariationOptionChange($event)">
                      </p-dropdown>
                      <label for="inputtext-left">Select Option</label>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <input type="text" id="inputtext-left" class="form-control" formControlName="Value" pInputText>
                      <label for="inputtext-left">Enter the value</label>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <p-toast></p-toast>
                <p-confirmPopup></p-confirmPopup>
                <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="removeItem(i)"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <button type="button" label="Add New" (click)="addItem()" pButton></button>
            <button type="button" label="Save" (click)="addNewProductVariation()" pButton></button>
            <button type="button" label="Reset" (click)="productVariationReset()" pButton></button>
            <label ng-model="successMessageNewProductVariation" ng-bind="successMessageNewProductVariation" class="success">{{successMessageNewProductVariation}}</label>
            <label ng-model="errorMessageNewProductVariation" ng-bind="errorMessageNewProductVariation" class="error">{{errorMessageNewProductVariation}}</label>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <div class="alert alert-info">
              <strong>Info!</strong> Mapped/Associated/In Stock Product Variations are not allowed to delete.
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="deskContent">
    <div class=" col-lg-12 card-wrapper" style="margin-top: 10px;">
      <div class=" card">
        <div class=" card-header">
          <h3 class=" mb-0">Product Variation Details</h3>
        </div>
        <div class=" card-body">
          <p-table #dt4 [value]="productVariations" dataKey="id" [rows]="25" [showCurrentPageReport]="true" selectionMode="single"
                   [rowsPerPageOptions]="[25,50,100]" [loading]="loading" styleClass="p-datatable-productVariations" [paginator]="true"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                   [globalFilterFields]="['Name','ProductVariationCode']">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt4)"></button>
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="search" (input)="dt4.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" [(ngModel)]="inputSearchProductVariations" />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Variation
                    <p-columnFilter type="text" field="productVariations.Name" display="menu"></p-columnFilter>
                  </div>
                </th>
                <th>
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Variation Product Code
                    <p-columnFilter type="text" field="productVariations.ProductVariationCode" display="menu"></p-columnFilter>
                  </div>
                </th>
                <th style="width:8rem">Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-productVariations let-editing="editing" let-ri="rowIndex">
              <tr [pEditableRow]="productVariations">
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="productVariations.Name" required>
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{productVariations.Name}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="productVariations.ProductVariationCode">
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{productVariations.ProductVariationCode}}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td style="text-align:center">
                  <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInitProductVariations(productVariations)" class="p-button-rounded p-button-text"></button>
                  <p-toast></p-toast>
                  <p-confirmPopup></p-confirmPopup>
                  <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancelProductVariations(productVariations, ri, $event)" class="p-button-rounded p-button-text p-button-danger"></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>

  <div class="phoneContent">
    <div class=" col-lg-12 card-wrapper " style="margin-top: 10px;">
      <div class="card">
        <div class=" card-header">
          <h3 class=" mb-0">Product Variation Details</h3>
        </div>
        <p-dataView #dv5 [value]="productVariations" [paginator]="true" [rows]="25" filterBy="Name,ProductVariationCode"
                    [sortField]="sortField" [sortOrder]="sortOrder" layout="grid" [loading]="loading">
          <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
              <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                <i class="pi pi-search"></i>
                <input class="inputSearch" type="search" pInputText placeholder="Search keyword" (input)="dv.filter($event.target.value)" [(ngModel)]="inputSearchProductVariations">
              </span>
              <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
            </div>
          </ng-template>
          <ng-template let-productVariation pTemplate="listItem">
            <div class="p-col-12">
              <div class=" card">
                <div class=" card-header">
                  <div class=" row align-items-center">
                    <div class=" col-9" style="margin-left: -15px;">
                      <h5 class=" h3 mb-0">{{productVariation.Name}}</h5>
                    </div>
                    <div class=" col-3 text-right">
                      <a class="" href="javascript:void(0)">
                        <div class=" col-3 text-right">
                          <div class=" col-6">
                            <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="onRowEditInitProductVariations(productVariation)"></button>
                          </div>
                          <div class=" col-6 text-right">
                            <p-toast></p-toast>
                            <p-confirmPopup></p-confirmPopup>
                            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="onRowEditCancelProductVariations(productVariation, ri, $event)"></button>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class=" card-body">
                  <p class=" card-text mb-1">
                    <label>
                      Variation Product Code: {{ productVariation.ProductVariationCode}}
                    </label>
                  </p>
                  <!--<div class=" row align-items-center">
                    <div class=" col-6">
                      <p class=" card-text mb-1">
                        <i><label>By: </label></i>
                      </p>
                      <p class=" card-text mb-1">
                        <i><label>{{ productVariation.ModifiedBy}}</label></i>
                      </p>
                    </div>
                    <div class=" col-6 text-right">
                      <p class=" card-text mb-1">
                        <i><label>Modified Date: </label></i>
                      </p>
                      <p class=" card-text mb-1">
                        <i><label>{{ productVariation.ModifiedDate | date:'dd-MM-yyyy'}}</label></i>
                      </p>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template let-productVariation pTemplate="gridItem">
            <div class="p-col-12 p-md-4">
              <div class=" card">
                <div class=" card-header">
                  <div class=" row align-items-center">
                    <div class=" col-9" style="margin-left: -15px;">
                      <h5 class=" h3 mb-0">{{productVariation.Name}}</h5>
                    </div>
                    <div class=" col-3 text-right">
                      <a class="" href="javascript:void(0)">
                        <div class=" col-3 text-right">
                          <div class=" col-6">
                            <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="onRowEditInitProductVariations(productVariation)"></button>
                          </div>
                          <div class=" col-6 text-right">
                            <p-toast></p-toast>
                            <p-confirmPopup></p-confirmPopup>
                            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="onRowEditCancelProductVariations(productVariation, ri, $event)"></button>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class=" card-body">
                  <div class="product-grid-item-content">
                    <p class=" card-text mb-1">
                      <label>
                        Variation Product Code: {{ productVariation.ProductVariationCode}}
                      </label>
                    </p>
                  </div>
                  <!--<div class=" row align-items-center">
                    <div class=" col-6">
                      <p class=" card-text mb-1">
                        <i><label>By: </label></i>
                      </p>
                      <p class=" card-text mb-1">
                        <i><label>{{ productVariation.ModifiedBy}}</label></i>
                      </p>
                    </div>
                    <div class=" col-6 text-right">
                      <p class=" card-text mb-1">
                        <i>
                          <label>Modified Date:</label>
                        </i>
                      </p>
                      <p class=" card-text mb-1">
                        <i><label>{{ productVariation.ModifiedDate | date:'dd-MM-yyyy'}}</label></i>
                      </p>
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
      </div>
    </div>
  </div>
</p-dialog>


<div class="deskContent" *ngIf="desk">
  <div class=" col-lg-12 card-wrapper" style="margin-top: 10px;">
    <div class=" card">
      <div class=" card-header">
        <h3 class=" mb-0">Product Details</h3>
        <!--<div class="loading">
          <div class="loader" [attr.disabled]="loading ? 'true' : null"></div>
        </div>-->
        <label ng-model="successGridMessage" ng-bind="successGridMessage" class="success">{{successGridMessage}}</label>
        <label ng-model="errorGridMessage" ng-bind="errorGridMessage" class="error">{{errorGridMessage}}</label>
      </div>
      <div class=" card-body">
        <p-table #dt2 [value]="productLocations" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
                 [rowsPerPageOptions]="[25,50,100]" [loading]="loading" styleClass="p-datatable-productLocations" [paginator]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 [globalFilterFields]="['ID','Name','SupplierProductCode','Label_SupplierName','BarCode','MappedLocations','Label_PackageFormaterName']">
          <ng-template pTemplate="caption">
            <div class=" row">
              <div class=" col-lg-3 col-3">
                <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt2)"></button>
              </div>
              <div class=" col-lg-3 col-3">
                <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="suppliers" [(ngModel)]="SelectedSupplierID" placeholder="Select Supplier" (onChange)="filterData()"
                            [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                            optionLabel="Name" optionValue="ID">
                </p-dropdown>
              </div>
              <div class=" col-lg-3 col-3">
                <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="seasons" [(ngModel)]="selectedSeasonID" placeholder="Select Season" (onChange)="filterData()"
                            [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                            optionLabel="Name" optionValue="ID">
                </p-dropdown>
              </div>
              
              <div class=" col-lg-3 col-3 text-right">
                <input class="inputSearch" pInputText type="search" (input)="filterData()" placeholder="Search keyword" [(ngModel)]="inputSearch" />
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th hidden>ID</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Product
                  <!--<p-columnFilter type="text" field="Name" display="menu"></p-columnFilter>-->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Supplier ProductCode
                  <!--<p-columnFilter type="text" field="SupplierProductCode" display="menu"></p-columnFilter>-->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Supplier
                  <!--<p-columnFilter type="text" field="Label_SupplierName" display="menu"></p-columnFilter>-->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  BarCode
                  <!--<p-columnFilter type="text" field="BarCode" display="menu"></p-columnFilter>-->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  MappedLocations
                  <!--<p-columnFilter type="text" field="MappedLocations" display="menu"></p-columnFilter>-->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Package Formater
                  <!--<p-columnFilter type="text" field="Label_PackageFormaterName" display="menu"></p-columnFilter>-->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Season
                  <!--<p-columnFilter type="text" field="Seasons" display="menu"></p-columnFilter>-->
                </div>
              </th>
              <th style="width:8rem;text-align:center">Actions</th>
              <th style="width:8rem;text-align:center">Variations</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-productLocations let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="productLocations">
              <td hidden pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="productLocations.ID">
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{productLocations.ID}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="productLocations.Name" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{productLocations.Name}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="productLocations.SupplierProductCode" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{productLocations.SupplierProductCode}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="productLocations.Label_SupplierName" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{productLocations.Label_SupplierName}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="productLocations.BarCode" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{productLocations.BarCode}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="productLocations.MappedLocations">
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{productLocations.MappedLocations}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="productLocations.Label_PackageFormaterName" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{productLocations.Label_PackageFormaterName}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input *ngIf="productLocations.SeasonID !== 0" pInputText type="text" [(ngModel)]="productLocations.Label_SeasonName" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <span *ngIf="productLocations.SeasonID !== 0">
                      {{ productLocations.Label_SeasonName }}
                    </span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align:center">
                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(productLocations)" class="p-button-rounded p-button-text"></button>
                <p-toast></p-toast>
                <p-confirmPopup></p-confirmPopup>
                <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(productLocations, ri, $event)" class="p-button-rounded p-button-text p-button-danger"></button>
              </td>
              <td style="text-align:center">
                <button *ngIf="productLocations.HasVariations" pButton pRipple type="button" pInitEditableRow icon="pi pi-vimeo" class="p-button-rounded p-button-text"
                        (click)="onRowEditProductVariation(productLocations)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<div class="phoneContent" *ngIf="phone">
  <div class=" col-lg-12 card-wrapper " style="margin-top: 10px;">
    <div class="card">
      <div class=" card-header">
        <h3 class=" mb-0">Product Details</h3>
        <label ng-model="successGridMessage" ng-bind="successGridMessage" class="success">{{successGridMessage}}</label>
        <label ng-model="errorGridMessage" ng-bind="errorGridMessage" class="error">{{errorGridMessage}}</label>
      </div>
      <p-dataView #dv [value]="productLocations" [paginator]="true" [rows]="25" filterBy="ID,Name,SupplierProductCode,Label_SupplierName,BarCode,MappedLocations,Label_PackageFormaterName"
                  [sortField]="sortField" [sortOrder]="sortOrder" layout="grid" [loading]="loading">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
            <span class="p-input-icon-left p-mb-2 p-mb-md-0">
              <i class="pi pi-search"></i>
              <input class="inputSearch" type="search" pInputText placeholder="Search keyword" (input)="filterData()" [(ngModel)]="inputSearch">
            </span>
            <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="suppliers" [(ngModel)]="SelectedSupplierID" placeholder="Select Supplier" (onChange)="filterData()"
                        [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                        optionLabel="Name" optionValue="ID">
            </p-dropdown>
              <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="seasons" [(ngModel)]="selectedSeasonID" placeholder="Select Season" (onChange)="filterData()"
                          [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                          optionLabel="Name" optionValue="ID">
              </p-dropdown>
            <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
          </div>
        </ng-template>
        <ng-template let-productLocation pTemplate="listItem">
          <div class="p-col-12">
            <div class=" card">
              <div class=" card-header">
                <div class=" row align-items-center">
                  <div class=" col-9" style="margin-left: -15px;">
                    <h5 class=" h3 mb-0">{{productLocation.Name + " ( " + productLocation.SupplierProductCode +" ) "}}</h5>
                  </div>
                  <div class=" col-3 text-right">
                    <a class="" href="javascript:void(0)">
                      <div class=" col-3 text-right">
                        <div class=" col-6">
                          <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="onRowEditInit(productLocation)"></button>
                        </div>
                        <div class=" col-6 text-right">
                          <p-toast></p-toast>
                          <p-confirmPopup></p-confirmPopup>
                          <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="onRowEditCancel(productLocation, ri, $event)"></button>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class=" card-body">
                <p class=" card-text mb-1">
                  <label>Supplier: {{ productLocation.Label_SupplierName}}</label>
                </p>
                <p class=" card-text mb-1">
                  <label>BarCode: {{ productLocation.BarCode}}</label>
                </p>
                <p class=" card-text mb-1">
                  <label>Mapped Locations: {{ productLocation.MappedLocations}}</label>
                </p>
                <p class=" card-text mb-1">
                  <label>Package Formater: {{ productLocation.Label_PackageFormaterName}}</label>
                </p>
                <p class=" card-text mb-1">
                  <label>Seasons: {{ productLocation.Label_SeasonName}}</label>
                </p>
                <!--<div class=" row align-items-center">
                  <div class=" col-6">
                    <p class=" card-text mb-1">
                      <i><label>By: </label></i>
                    </p>
                    <p class=" card-text mb-1">
                      <i><label>{{ productLocation.ModifiedBy}}</label></i>
                    </p>
                  </div>
                  <div class=" col-6 text-right">
                    <p class=" card-text mb-1">
                      <i><label>Modified Date: </label></i>
                    </p>
                    <p class=" card-text mb-1">
                      <i><label>{{ productLocation.ModifiedDate | date:'dd-MM-yyyy'}}</label></i>
                    </p>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template let-productLocation pTemplate="gridItem">
          <div class="p-col-12 p-md-4">
            <div class=" card">
              <div class=" card-header">
                <div class=" row align-items-center">
                  <div class=" col-9" style="margin-left: -15px;">
                    <h5 class=" h3 mb-0">{{productLocation.Name + " ( " + productLocation.SupplierProductCode +" ) "}}</h5>
                  </div>
                  <div class=" col-3 text-right">
                    <a class="" href="javascript:void(0)">
                      <div class=" col-3 text-right">
                        <div class=" col-6">
                          <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="onRowEditInit(productLocation)"></button>
                        </div>
                        <div class=" col-6 text-right">
                          <p-toast></p-toast>
                          <p-confirmPopup></p-confirmPopup>
                          <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="onRowEditCancel(productLocation, ri, $event)"></button>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class=" card-body">
                <div class="product-grid-item-content">
                  <p class=" card-text mb-1">
                    <label>Supplier: {{ productLocation.Label_SupplierName}}</label>
                  </p>
                  <p class=" card-text mb-1">
                    <label>BarCode: {{ productLocation.BarCode}}</label>
                  </p>
                  <p class=" card-text mb-1">
                    <label>Mapped Locations: {{ productLocation.MappedLocations}}</label>
                  </p>
                  <p class=" card-text mb-1">
                    <label>Package Formater: {{ productLocation.Label_PackageFormaterName}}</label>
                  </p>
                  <p class=" card-text mb-1">
                    <label>Seasons: {{ productLocation.Label_SeasonName}}</label>
                  </p>
                </div>
                <!--<div class=" row align-items-center">
                  <div class=" col-6">
                    <p class=" card-text mb-1">
                      <i><label>By: </label></i>
                    </p>
                    <p class=" card-text mb-1">
                      <i><label>{{ productLocation.ModifiedBy}}</label></i>
                    </p>
                  </div>
                  <div class=" col-6 text-right">
                    <p class=" card-text mb-1">
                      <i>
                        <label>Modified Date:</label>
                      </i>
                    </p>
                    <p class=" card-text mb-1">
                      <i><label>{{ productLocation.ModifiedDate | date:'dd-MM-yyyy'}}</label></i>
                    </p>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
