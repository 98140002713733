import { Routes } from "@angular/router";
import { ProdDashboardComponent } from './prod-dashboard/prod-dashboard.component';
import { LocationComponent } from './components/location/location.component';
import { ProductComponent } from './components/product/product.component';
import { SupplierComponent } from './components/supplier/supplier.component';
import { LocationtypeComponent } from './components/locationtype/locationtype.component';
import {MultipackcreationComponent} from './components/multipackcreation/multipackcreation.component';
import { AuthGuard } from "src/app/shared/guards/auth.guard";
import { SeasonsComponent } from "./components/seasons/seasons.component";



export const ProductRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "ProdDashboard",
        component: ProdDashboardComponent,
      },
      {
        path: "Product",
        component: ProductComponent
      },
      {
        path: "Location",
        component: LocationComponent
      },
      {
        path: "Supplier",
        component: SupplierComponent
      },
      {
        path: "Season",
        component: SeasonsComponent
      },
      {
        path: "LocationType",
        component: LocationtypeComponent
      }, {
        path: "MultipackCreation",
        component: MultipackcreationComponent
      },
    ], canActivate: [AuthGuard],
  },
];
