import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//Import Models
import { LocationTypes } from '../../models/locationtypes';
//Import Service
import { LocationtypeService } from '../../services/locationtype.service';
import { MessageService,ConfirmationService } from 'primeng/api';

//primeng
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-locationtype',
  templateUrl: './locationtype.component.html',
  styleUrls: ['./locationtype.component.css'],
  providers: [LocationtypeService, MessageService,ConfirmationService,PrimeNGConfig],
  preserveWhitespaces: true

})
export class LocationtypeComponent implements OnInit {
  public locationtype: LocationTypes = new LocationTypes(null, null, null);
  clonedLocationtype: { [s: string]: LocationTypes; } = {};
  LocationTypeForm: FormGroup;
  public loading: boolean;
  public isShownLocationTypeForm: boolean;
  errorMessage = "";
  successMessage = "";
  public inputSearch = "";

  public locationTypes = [];
  constructor(public locationTypeService: LocationtypeService,private confirmationService: ConfirmationService,
    private primengConfig: PrimeNGConfig,
    private messageService: MessageService,
    private fromBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.isShownLocationTypeForm = false;

    this.LocationTypeForm = this.fromBuilder.group({
      ID: [''],
      Name: ['', Validators.required,],
      IsDeleted: [false],
    });

    this.getAllLocationTypes();
  }
  get f() { return this.LocationTypeForm.controls }

  showLocationTypeForm() {
    this.errorMessage = "";
    this.successMessage = "";
    //this.clearSupplierForm();
    this.isShownLocationTypeForm = true;
  }

  showAllLocationType() {
    this.isShownLocationTypeForm = false;
  }

  addLocationType() {
    this.errorMessage = "";
    this.successMessage = "";
    if (this.LocationTypeForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      return;
    }
    this.LocationTypeForm.value["ID"] = this.LocationTypeForm.value["ID"] != 0 ? this.LocationTypeForm.value["ID"] : 0;
    if (this.LocationTypeForm.value["ID"] > 0) {
      this.updateLocationType(this.LocationTypeForm.value);
    }
    else {
      this.saveLocationType(this.LocationTypeForm.value);
    }
  }


  getAllLocationTypes() {
    this.locationTypeService.getAllLocationTypes()
      .subscribe(
        data => {
          this.locationTypes = data;
        },
        error => {
          this.errorMessage = "Error while fetching location type data";
          return;
        })
  }



  saveLocationType(locationtype: LocationTypes) {
    this.locationTypeService.saveLocationType(this.LocationTypeForm.value)
      .subscribe(
        data => {
          this.clearLocationTypeForm();
          this.successMessage = "LocationType Added";
          this.hideMessage();
          this.getAllLocationTypes();
          return;
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Locationtype exists..!";
          }
          else {
            this.errorMessage = "Error while adding locationtype data";
          }
          this.hideMessage();
          return;
        })

  }

  updateLocationType(locationType: LocationTypes) {
    this.locationTypeService.updateLocationType(locationType)
      .subscribe(
        data => {
          this.clearLocationTypeForm();
          this.successMessage = "LocationType Updated";
          this.hideMessage();
          this.getAllLocationTypes();
          return;
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Locationtype exists..!";
          }
          else {
            this.errorMessage = "Error while updating locationtype data";
          }
          this.hideMessage();
          return;
        })

  }

  deleteLocationType(locationtype) {
    this.locationTypeService.deleteLocationType(locationtype)
      .subscribe(
        data => {
          this.successMessage = "Locationtype Deleted";
          this.messageService.add({severity:'success', detail:'Locationtype Deleted'});

          this.hideMessage();
          this.getAllLocationTypes();

        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Locationtype mapped..!";
            this.messageService.add({severity:'error', detail:'LocationType already mapped.Cannot delete LocationType'});

          }
          else {
            this.errorMessage = "Error while deleting locationtype data";
            this.messageService.add({severity:'error', detail:'Error while deleting locationtype data'});

          }
          this.hideMessage();
          this.getAllLocationTypes();
          return;
        })

  }



  hideMessage() {
    setTimeout(() => {
      this.errorMessage = "";
      this.successMessage="";
    }, 2000);
  }

  clearLocationTypeForm() {
    this.LocationTypeForm.setValue({
      ID: 0,
      Name: '',
      IsDeleted: false,
    });
  }

  onRowEditInit(locationtype: LocationTypes) {
    this.successMessage = "";
    this.errorMessage = "";
    this.isShownLocationTypeForm = true;;
    this.clonedLocationtype[locationtype.ID] = { ...locationtype };
    this.LocationTypeForm.setValue({
      ID: this.clonedLocationtype[locationtype.ID].ID,
      Name: this.clonedLocationtype[locationtype.ID].Name,
      IsDeleted: this.clonedLocationtype[locationtype.ID].IsDeleted,
    });
    window.scrollTo(0,0);
  }

  onRowEditCancel(locationtype: LocationTypes, index: number,event:Event) {
 
    this.locationTypes.indexOf[index] = this.clonedLocationtype[locationtype.ID];
  //  delete this.locationTypes[locationtype.ID];

    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete LocationType?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteLocationType(locationtype);
      },
      reject: () => {
      }
    });

  }

  onRowEditSave(locationtype: LocationTypes) {
    if (locationtype.ID > 0) {
      delete this.clonedLocationtype[locationtype.ID];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Supplier is updated' });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error is updating supplier detail' });
    }
  }
  clear(table: Table) {
    table.clear();
    this.inputSearch = "";
  }

//Toast message

cleartoast() {
  this.messageService.clear();
}

}

