
<nav class="navbar navbar-top navbar-expand navbar-dark bg-danger border-bottom"
     id="navbar-main">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Search form -->
      <form class="navbar-search navbar-search-light form-inline mr-sm-3"
            id="navbar-search-main">
        <!--<div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <input class="form-control"
                   placeholder="Search"
                   type="text"
                   (focus)="focus = true"
                   (blur)="focus = false" />
          </div>
        </div>
        <button type="button"
                class="close"
                (click)="closeSearch()"
                data-action="search-close"
                data-target="#navbar-search-main"
                aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>-->
      </form>
      <!-- Navbar links -->
      <ul class="navbar-nav align-items-center ml-md-auto">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div class="pr-3 sidenav-toggler sidenav-toggler-dark"
               data-action="sidenav-pin"
               data-target="#sidenav-main"
               (click)="openSidebar()">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
        <li class="nav-item d-sm-none">
          <!--<a class="nav-link" (click)="openSearch()">
            <i class="ni ni-zoom-split-in"></i>
          </a>-->
        </li>
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <button class="btn btn-sm btn-neutral" type="button" label="Report Download" pTooltip="ASIN Report" (click)="report()" pButton></button>
          <button class="btn btn-sm btn-neutral btn-notify" type="button" (click)="notification('top-right')"><i class="fas fa-bell">({{PendingAlertsCount+ExceptionDetailsCount}})</i></button>
          <!--<a class="nav-link dropdown-toggle" role="button" dropdownToggle>
            <i class="ni ni-bell-55"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
               *dropdownMenu>-->
            <!-- Dropdown header -->
            <!--<div class="px-3 py-3">
              <h6 class="text-sm text-muted m-0">
                You have <strong class="text-primary">13</strong> notifications.
              </h6>
            </div>-->
            <!-- List group -->
            <!--<div class="list-group list-group-flush">
              <a href="javascript:void()"
                 class="list-group-item list-group-item-action">
                <div class="row align-items-center">
                  <div class="col-auto">-->
                    <!-- Avatar -->
                    <!--<img alt="Image placeholder"
                         src="assets/img/theme/team-1.jpg"
                         class="avatar rounded-circle" />
                  </div>
                  <div class="col ml--2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div><h4 class="mb-0 text-sm">{{userName}}</h4></div>
                      <div class="text-right text-muted">
                        <small>2 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Let's meet at Starbucks at 11:30. Wdyt?
                    </p>
                  </div>
                </div>
              </a>
              <a href="javascript:void()"
                 class="list-group-item list-group-item-action">
                <div class="row align-items-center">
                  <div class="col-auto">-->
                    <!-- Avatar -->
                    <!--<img alt="Image placeholder"
                         src="assets/img/theme/team-2.jpg"
                         class="avatar rounded-circle" />
                  </div>
                  <div class="col ml--2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div><h4 class="mb-0 text-sm">{{userName}}</h4></div>
                      <div class="text-right text-muted">
                        <small>3 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      A new issue has been reported for Argon.
                    </p>
                  </div>
                </div>
              </a>
              <a href="javascript:void()"
                 class="list-group-item list-group-item-action">
                <div class="row align-items-center">
                  <div class="col-auto">-->
                    <!-- Avatar -->
                    <!--<img alt="Image placeholder"
                         src="assets/img/theme/team-3.jpg"
                         class="avatar rounded-circle" />
                  </div>
                  <div class="col ml--2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div><h4 class="mb-0 text-sm">{{userName}}</h4></div>
                      <div class="text-right text-muted">
                        <small>5 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Your posts have been liked a lot.
                    </p>
                  </div>
                </div>
              </a>
              <a href="javascript:void()"
                 class="list-group-item list-group-item-action">
                <div class="row align-items-center">
                  <div class="col-auto">-->
                    <!-- Avatar -->
                    <!--<img alt="Image placeholder"
                         src="assets/img/theme/team-4.jpg"
                         class="avatar rounded-circle" />
                  </div>
                  <div class="col ml--2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div><h4 class="mb-0 text-sm">{{userName}}</h4></div>
                      <div class="text-right text-muted">
                        <small>2 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Let's meet at Starbucks at 11:30. Wdyt?
                    </p>
                  </div>
                </div>
              </a>
              <a href="javascript:void()"
                 class="list-group-item list-group-item-action">
                <div class="row align-items-center">
                  <div class="col-auto">-->
                    <!-- Avatar -->
                    <!--<img alt="Image placeholder"
                         src="assets/img/theme/team-5.jpg"
                         class="avatar rounded-circle" />
                  </div>
                  <div class="col ml--2">
                    <div class="d-flex justify-content-between align-items-center">
                      <div><h4 class="mb-0 text-sm">{{userName}}</h4></div>
                      <div class="text-right text-muted">
                        <small>3 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      A new issue has been reported for Argon.
                    </p>
                  </div>
                </div>
              </a>
            </div>-->
            <!-- View all -->
            <!--<a href="javascript:void()"
               class="dropdown-item text-center text-primary font-weight-bold py-3">View all</a>
          </div>-->
        </li>
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <!--<a class="nav-link dropdown-toggle"
             href="javascript:void(0)"
             role="button"
             dropdownToggle>
            <i class="ni ni-ungroup"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right"
               *dropdownMenu>
            <div class="row shortcuts px-4">
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-red">
                  <i class="ni ni-calendar-grid-58"></i>
                </span>
                <small>Calendar</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-orange">
                  <i class="ni ni-email-83"></i>
                </span>
                <small>Email</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-info">
                  <i class="ni ni-credit-card"></i>
                </span>
                <small>Payments</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-green">
                  <i class="ni ni-books"></i>
                </span>
                <small>Reports</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-purple">
                  <i class="ni ni-pin-3"></i>
                </span>
                <small>Maps</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-yellow">
                  <i class="ni ni-basket"></i>
                </span>
                <small>Shop</small>
              </a>
            </div>
          </div>-->
        </li>
      </ul>
      <!-- User -->
      <ul class="navbar-nav align-items-center ml-auto ml-md-0">
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <!--<img alt="Image placeholder"
                     src="assets/img/theme/team-4.jpg" />-->
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold">{{userName}}</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right"
               *dropdownMenu>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <!--<a routerLinkActive="active"
               [routerLink]="['/user-profile']"
               class="dropdown-item">
              <i class="ni ni-single-02"></i> <span>My profile</span>
            </a>
            <a routerLinkActive="active"
               [routerLink]="['/user-profile']"
               class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i> <span>Settings</span>
            </a>
            <a routerLinkActive="active"
               [routerLink]="['/user-profile']"
               class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i> <span>Activity</span>
            </a>
            <a routerLinkActive="active"
               [routerLink]="['/examples/user-profile']"
               class="dropdown-item">
              <i class="ni ni-support-16"></i> <span>Support</span>
            </a>-->
            <div class="dropdown-divider"></div>
            <a href="javascript:void()" class="dropdown-item" (click)="Logout()">
              <i class="ni ni-user-run"></i> <span>Logout</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div *ngIf="sidenavOpen === true"
     class="backdrop d-xl-none"
     (click)="toggleSidenav()">
</div>
<p-dialog class="dialog" header="Notifications" id="notification" [(visible)]="notify" [style]="{width: '50vw'}" [baseZIndex]="10000" [modal] = "true" [position]="pos"
                [draggable]="false" [resizable]="false" >
                <p-tabView>
                  <p-tabPanel>
                    <ng-template pTemplate = "header">
                    <span>{{showReviewedAlerts ? 'Reviewed' : 'Pending'}} Alerts({{PendingAlertsCount}})</span>
                    </ng-template>
                    <p-table #dt3 [value]="PendingAlerts" dataKey="ID" [rows]="25" [showCurrentPageReport]="true"
                    [scrollable]="true" scrollHeight="250px"
                    [rowsPerPageOptions]="[25,50,100]" [loading]="loader" styleClass="p-datatable-PendingAlerts" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
                    [globalFilterFields]="['ProductCode','InvoiceNumber','ProductName']">
                    <ng-template pTemplate="caption">
                      <div class="row">
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <p-checkbox class="mx-1" (onChange)="getAllPendingAlerts(showReviewedAlerts)" name="group1" [(ngModel)]="showReviewedAlerts" inputId="IR" binary="true" label="Reviewed Alerts"></p-checkbox>
                        </div>
                    </div><br/>
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            <button type="button" *ngIf="!showReviewedAlerts" class="btn btn-primary" pButton (click)="markAlertsToReviewed()" label="Mark As Reviewed"  [disabled]="markedAlerts.length == 0"></button>
                        </div>
                        <div class="offset-lg-6 col-lg-3 offset-md-6 col-md-3 offset-sm-6 col-sm-3 offset-xs-6 col-xs-3">
                            <button type="button" class="btn btn-primary float-right" pButton (click)="showAllPendingAlerts(showReviewedAlerts)" label="Show All {{showReviewedAlerts ? 'Reviewed' : 'Pending'}} Alerts"  [disabled]="loader || !showPendingAlert"></button>
                        </div>
                    </div><br/>
                        <!-- <div class="row">
                            <div class="col-lg-2">
                                <p-checkbox class="mx-1" (onChange)="getAllPendingAlerts(showReviewedAlerts)" name="group1" [(ngModel)]="showReviewedAlerts" inputId="IR" binary="true" label="Reviewed Alerts"></p-checkbox>
                            </div>
                            <div class="offset-lg-2 col-lg-3">
                              <button type="button" *ngIf="!showReviewedAlerts" class="btn btn-primary" pButton (click)="markAlertsToReviewed()" label="Mark As Reviewed"  [disabled]="markedAlerts.length == 0"></button>
                            </div>
                            <div class="offset-lg-2 col-lg-3">
                                <button type="button" class="btn btn-primary" pButton (click)="showAllPendingAlerts()" label="Show All Pending Alerts"  [disabled]="loader || !showPendingAlert"></button>
                            </div>
                        </div><br/> -->
                      <div class=" row">
                        <div class=" col-lg-4 col-4 col-md-4 col-sm-4 col-xs-4">
                          <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clearSearch(dt3)"></button>
                        </div>
                        <div class="col-lg-8 col-8 col-md-8 col-sm-8 col-xs-8 text-right">
                          <input class="inputSearch" pInputText type="search" (input)="dt3.filterGlobal($event.target.value, 'contains')" placeholder="Search by Product Title or Invoice No or Product Code" [(ngModel)]="inputSearchKey" />
                        </div>
                      </div><br/>
                    </ng-template>
                        <ng-template pTemplate="header">
                        <tr>
                            <th hidden>ID</th>
                            <th *ngIf="!showReviewedAlerts">
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Reviewed?
                                <!-- <p-columnFilter type="text" field="Reviewed" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Invoice No
                                <!-- <p-columnFilter type="text" field="InvoiceNo" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Invoice Date
                                <!-- <p-columnFilter type="text" field="Invoice Date" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Product Title
                                <!-- <p-columnFilter type="text" field="ProductTitle" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Product Code
                                <!-- <p-columnFilter type="text" field="ProductCode" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Supplier
                                <!-- <p-columnFilter type="text" field="Supplier" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Alert Type
                                <!-- <p-columnFilter type="text" field="AlertType" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Current Price
                                <!-- <p-columnFilter type="text" field="currentPrice" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                                Old Price
                                <!-- <p-columnFilter type="text" field="oldPrice" display="menu"></p-columnFilter> -->
                            </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Alert Message
                                    <!-- <p-columnFilter type="text" field="alertMessage" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                        </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" *ngIf="PendingAlerts.length == 0">
                        <tr>
                            <td colspan="9">No Data Found!</td>
                        </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-PendingAlerts let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="PendingAlerts">
                            <td hidden pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="PendingAlerts.AlertID">
                                </ng-template>
                                <ng-template pTemplate="output">
                                {{PendingAlerts.AlertID}}
                                </ng-template>
                            </p-cellEditor>
                            </td>
                            <td *ngIf="!showReviewedAlerts">
                                <p-checkbox class="mx-4" (onChange)="getMarkedAlerts(PendingAlerts)" name="group1" [(ngModel)]="PendingAlerts.IsReviewed" inputId="IR" binary="true"></p-checkbox>
                            </td>
                            <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="PendingAlerts.InvoiceNumber" required>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <a (click)="editPendingInvoice(PendingAlerts)" href="javascript:void(0)" >{{PendingAlerts.InvoiceNumber}}</a>
                                </ng-template>
                            </p-cellEditor>
                            </td>
                            <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="PendingAlerts.InvoiceDate" required> 
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{PendingAlerts.InvoiceDate | date: 'dd-MMM-yyyy'}} 
                                </ng-template>
                            </p-cellEditor>
                            </td>
                            <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="PendingAlerts.ProductName" required> 
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{PendingAlerts.ProductName}} 
                                </ng-template>
                            </p-cellEditor>
                            </td>
                            <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="PendingAlerts.ProductCode"> 
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{PendingAlerts.ProductCode}} 
                                </ng-template>
                            </p-cellEditor>
                            </td>
                            <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="PendingAlerts.SupplierName" required> 
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{PendingAlerts.SupplierName}} 
                                </ng-template>
                            </p-cellEditor>
                            </td>
                            <td>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="PendingAlerts.AlertType" required> 
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{PendingAlerts.AlertType}} 
                                </ng-template>
                            </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="PendingAlerts.UnitPrice" required> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{PendingAlerts.UnitPrice}} 
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="PendingAlerts.OldUnitPrice" required> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{PendingAlerts.OldUnitPrice}} 
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="PendingAlerts.AlertMessage" required> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{PendingAlerts.AlertMessage}} 
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                        </tr>
                        </ng-template>
                    </p-table>
                  </p-tabPanel>
                  <p-tabPanel>
                    <ng-template pTemplate = "header">
                      <span>Delivery Exception Alerts({{ExceptionDetailsCount}})</span>
                      </ng-template>
                    <p-table #dt3 [value]="ExceptionAlerts" dataKey="ID" [rows]="25" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[25,50,100]" [loading]="loader" styleClass="p-datatable-ExceptionAlerts" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
                    [globalFilterFields]="['ProductCode','SupplierInvoiceID','ProductName']">
                    <ng-template pTemplate="caption">
                      <div class="row">
                        <div class="offset-lg-8 col-lg-4">
                            <button type="button" *ngIf="!showReviewedAlerts" class="btn btn-primary float-right" pButton (click)="ShowAllExceptionAlerts()" label="Show All Delivery Exception Alerts"  [disabled]="loader || !showExceptionAlert"></button>
                        </div>
                      </div><br/>
                        <!-- <div class="row">
                            <div class="offset-lg-8 col-lg-4">
                                <button type="button" *ngIf="!showReviewedAlerts" class="btn btn-primary" pButton (click)="ShowAllExceptionAlerts()" label="Show All Delivery Exception Alerts"  [disabled]="loader || !showExceptionAlert"></button>
                            </div>
                        </div><br/> -->
                      <div class=" row">
                        <div class=" col-lg-4 col-4">
                          <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clearSearch(dt3)"></button>
                        </div>
                        <div class="col-lg-8 col-8 text-right">
                          <input class="inputSearch" pInputText type="search" (input)="dt3.filterGlobal($event.target.value, 'contains')" placeholder="Search by Product Title or Invoice No or Product Code" [(ngModel)]="inputSearchKey"  />
                        </div>
                      </div><br/>
                    </ng-template>
                        <ng-template pTemplate="header">
                        <tr>
                            <th hidden>ID</th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Resolve
                                    <!-- <p-columnFilter type="text" field="Resolve" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Invoice No
                                    <!-- <p-columnFilter type="text" field="InvoiceNo" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Product Title
                                    <!-- <p-columnFilter type="text" field="ProductTitle" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Product Code
                                    <!-- <p-columnFilter type="text" field="ProductCode" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Supplier
                                    <!-- <p-columnFilter type="text" field="Supplier" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Received Qty
                                    <!-- <p-columnFilter type="text" field="ReceivedQty" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Exception Qty
                                    <!-- <p-columnFilter type="text" field="ExceptionQty" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Total Qty
                                    <!-- <p-columnFilter type="text" field="TotalQty" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                            <th>
                                <div class="p-d-flex p-jc-between p-ai-center">
                                    Comments
                                    <!-- <p-columnFilter type="text" field="Comments" display="menu"></p-columnFilter> -->
                                </div>
                            </th>
                        </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" *ngIf="ExceptionAlerts.length == 0">
                            <tr>
                                <td colspan="9">No Data Found!</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-ExceptionAlerts let-editing="editing" let-ri="rowIndex">
                            <tr [pEditableRow]="ExceptionAlerts">
                                <td hidden pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="ExceptionAlerts.DeliverableNumber">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                    {{ExceptionAlerts.DeliverableNumber}}
                                    </ng-template>
                                </p-cellEditor>
                                </td>
                                <td>
                                  <a (click)="resolveException(ExceptionAlerts)" href="javascript:void(0)" >Resolve</a>
                                </td>
                                <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="ExceptionAlerts.InvoiceNo" required>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ExceptionAlerts.InvoiceNo}}
                                    </ng-template>
                                </p-cellEditor>
                                </td>
                                <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ExceptionAlerts.ProductName" required> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ExceptionAlerts.ProductName}} 
                                    </ng-template>
                                </p-cellEditor>
                                </td>
                                <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ExceptionAlerts.ProductCode" required> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ExceptionAlerts.ProductCode}} 
                                    </ng-template>
                                </p-cellEditor>
                                </td>
                                <td>
                                  <p-cellEditor>
                                      <ng-template pTemplate="input">
                                          <input pInputText type="text" [(ngModel)]="ExceptionAlerts.SupplierName" required> 
                                      </ng-template>
                                      <ng-template pTemplate="output">
                                          {{ExceptionAlerts.SupplierName}} 
                                      </ng-template>
                                  </p-cellEditor>
                                  </td>
                                <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                    <input pInputText type="text" [(ngModel)]="ExceptionAlerts.ReceivedQuantity"> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ExceptionAlerts.ReceivedQuantity}} 
                                    </ng-template>
                                </p-cellEditor>
                                </td>
                                <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ExceptionAlerts.ExceptionQuantity" required> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ExceptionAlerts.ExceptionQuantity}} 
                                    </ng-template>
                                </p-cellEditor>
                                </td>
                                <td>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="ExceptionAlerts.Quantity" required> 
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ExceptionAlerts.Quantity}} 
                                    </ng-template>
                                </p-cellEditor>
                                </td>
                                <td>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" [(ngModel)]="ExceptionAlerts.Comments" required> 
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ExceptionAlerts.Comments}} 
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                  </p-tabPanel>
              </p-tabView>
  </p-dialog>

