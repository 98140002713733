import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule} from '@angular/forms';
//import { FormsModule, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProdDashboardComponent } from './prod-dashboard/prod-dashboard.component';
import { RouterModule } from "@angular/router";

import { ProductRoutes } from './product.routing';
import { ProductComponent } from './components/product/product.component';
import { LocationComponent } from './components/location/location.component';
import { SupplierComponent } from './components/supplier/supplier.component';
import { LocationtypeComponent } from './components/locationtype/locationtype.component';
import { MultipackcreationComponent } from './components/multipackcreation/multipackcreation.component';

/** PrimeNg module import */
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DataViewModule } from 'primeng/dataview';
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ConfirmationService, MessageService } from "primeng/api";
import { TooltipModule } from 'primeng/tooltip';
import { SeasonsComponent } from './components/seasons/seasons.component';


@NgModule({
  declarations: [ProdDashboardComponent, ProductComponent, LocationComponent, SupplierComponent, LocationtypeComponent,MultipackcreationComponent, SeasonsComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ButtonModule,
    RouterModule.forChild(ProductRoutes),
    ReactiveFormsModule,
    FormsModule,
    TableModule,
    InputTextModule,
    PanelModule,
    DropdownModule,
    CheckboxModule,
    ButtonModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    ToastModule,
    ProgressBarModule,
    AutoCompleteModule,
    DataViewModule,
    ConfirmPopupModule,
    TooltipModule,
  ],
  exports: [SupplierComponent, ProductComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
  providers: [ConfirmationService, MessageService, ProductComponent,],
})
export class ProductModule { }
