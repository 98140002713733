import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
  children?: ChildrenSubItems2[];
}
export interface ChildrenSubItems2 {
  path?: string;
  title?: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "components",
    title: "Dashboards",
    type: "sub",
    icontype: "ni-shop text-primary",
    isCollapsed: true,
    children: [
      // { path: "Inventory", title: "Inventory", type: "link" },
      // { path: "Pricing", title: "Pricing", type: "link" },
      // { path: "Order", title: "Order", type: "link" },
      //{ path: "Product", title: "Product", type: "link"},
      // {
      //   path: "components",
      //   isCollapsed: true,
      //   title: "Product Dashboard",
      //   type: "sub",
      //   children: [
      //     { path: "Product", title: "Product", type: "link" },
      //     { title: "Supplier" },
      //     { title: "Warehouse" },
      //     { title: "Location" },

      //   ]
      // }


    ]
  },
  {
    path: "Products",
    title: "Product",
    type: "sub",
    icontype: "ni-ungroup text-orange",
    collapse: "examples",
    isCollapsed: true,
    children: [
      //{ path: "ProdDashboard", title: "Product Dashboard", type: "link" },
      { path: "Product", title: "Product", type: "link" },
      { path: "Supplier", title: "Supplier", type: "link" },
      { path: "Season", title: "Season", type: "link" },
      //{ path: "", title: "Warehouse", type: "link" },
      { path: "Location", title: "Location", type: "link" },
      { path: "LocationType", title: "LocationType", type: "link" },
      { path: "MultipackCreation", title: "Multipack Creation", type: "link" },


    ]
  },
  {
    path: "Warehouse",
    title: "Warehouse",
    type: "sub",
    icontype: "ni-ui-04 text-info",
    collapse: "examples",
    isCollapsed: true,
    children: [
      //{ path: "", title: "Stock In", type: "link" },
      { path: "LocationMapping", title: "Location Mapping", type: "link" },
      //{ path: "", title: "Product Data", type: "link" },
      //{ path: "", title: "Return Entry", type: "link" },
      //{ path: "", title: "FBA Shipments", type: "link" },

    ]
  },
  {
    path: "Purchasing",
    title: "Purchasing",
    type: "sub",
    icontype: "ni-single-copy-04 text-pink",
    collapse: "examples",
    isCollapsed: true,
    children: [
      //{ path: "", title: "Suppliers", type: "link" },
      //{ path: "", title: "Products", type: "link" },
      //{ path: "", title: "Multipacks", type: "link" },
    ]
  },
  {
    path: "Sales",
    title: "Sales",
    type: "sub",
    icontype: "ni-align-left-2 text-default",
    collapse: "examples",
    isCollapsed: true,
    children: [
      { path: "Listings", title: "Listings", type: "link" },
      //{ path: "", title: "Pricing", type: "link" },
      //{ path: "", title: "Reports", type: "link" },
      { path: "RestockDate", title: "Restock Date", type: "link" },
      { path: "ListingsInventoryReport", title: "Listings Inventory Report", type: "link" }
    ]
  },
  {
    path: "Accounting",
    title: "Accounting",
    type: "sub",
    icontype: "ni-map-big text-primary",
    collapse: "examples",
    isCollapsed: true,
    children: [
      //{ path: "", title: "Invoice Entry", type: "link" },
    ]
  },
  {
    path: "Customer Support",
    title: "Customer Support",
    type: "sub",
    icontype: "ni-archive-2 text-green",
    collapse: "examples",
    isCollapsed: true,
    children: [
      //{ path: "", title: "Messages", type: "link" },
      //{ path: "", title: "Returns", type: "link" },
      //{ path: "", title: "Refunds", type: "link" },
      //{ path: "", title: "Replacements", type: "link" },
    ]
  },
  {
    path: "Pricing",
    title: "Pricing",
    type: "sub",
    icontype: "ni-ungroup text-orange",
    collapse: "examples",
    isCollapsed: true,
    children: [
      { path: "InvoiceDashboard", title: "Invoice Dashboard", type: "link"},
      { path: "Invoice", title: "Invoice", type: "link" },
      { path: "ProductDetails", title: "Product Details", type: "link" },
      { path: "InvoiceDetails", title: "Invoice Details", type: "link" },
      { path: "PendingDeliveryDetails", title: "Pending Delivery Details", type: "link" },
      { path: "ShowAllDeliveryDetails", title: "All Delivery Details", type: "link" },
      { path: "RestockReport", title: "Restock Report", type: "link" },
      { path: "ProductProcurementReport", title: "Product Procurement Report", type: "link" },
    ]
  },
  {
    path: "Research",
    title: "Research",
    type: "sub",
    icontype: "ni-books text-blue ",
    collapse: "examples",
    isCollapsed: true,
    children: [
      {path: "ResearchedProducts", title:"Researched Products", type: "link"}
    ]
  },

  // {
  //   path: "/components",
  //   title: "Pricing",
  //   type: "sub",
  //   icontype: "ni-ui-04 text-info",
  //   collapse: "components",
  //   isCollapsed: true,
  //   children: [
  //     { path: "buttons", title: "Buttons", type: "link" },
  //     { path: "cards", title: "Cards", type: "link" },
  //     { path: "grid", title: "Grid", type: "link" },
  //     { path: "notifications", title: "Notifications", type: "link" },
  //     { path: "icons", title: "Icons", type: "link" },
  //     { path: "typography", title: "Typography", type: "link" },
  // {
  //   path: "multilevel",
  //   isCollapsed: true,
  //   title: "Multilevel",
  //   type: "sub",
  //   collapse: "multilevel",
  //   children: [
  //     { title: "Third level menu" },
  //     { title: "Just another link" },
  //     { title: "One last link" }
  //   ]
  // }
  //   ]
  // },
  // {
  //   path: "/forms",
  //   title: "Order",
  //   type: "sub",
  //   icontype: "ni-single-copy-04 text-pink",
  //   collapse: "forms",
  //   isCollapsed: true,
  //   children: [
  //     { path: "elements", title: "Elements", type: "link" },
  //     { path: "components", title: "Components", type: "link" },
  //     { path: "validation", title: "Validation", type: "link" }
  //   ]
  // }
  // {
  //   path: "/tables",
  //   title: "Tables",
  //   type: "sub",
  //   icontype: "ni-align-left-2 text-default",
  //   collapse: "tables",
  //   isCollapsed: true,
  //   children: [
  //     { path: "tables", title: "Tables", type: "link" },
  //     { path: "sortable", title: "Sortable", type: "link" },
  //     { path: "ngx-datatable", title: "Ngx Datatable", type: "link" }
  //   ]
  // },
  // {
  //   path: "/maps",
  //   title: "Maps",
  //   type: "sub",
  //   icontype: "ni-map-big text-primary",
  //   collapse: "maps",
  //   isCollapsed: true,
  //   children: [
  //     { path: "google", title: "Google Maps", type: "link" },
  //     { path: "vector", title: "Vector Map", type: "link" }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   title: "Widgets",
  //   type: "link",
  //   icontype: "ni-archive-2 text-green"
  // },
  // {
  //   path: "/charts",
  //   title: "Charts",
  //   type: "link",
  //   icontype: "ni-chart-pie-35 text-info"
  // },
  // {
  //   path: "/calendar",
  //   title: "Calendar",
  //   type: "link",
  //   icontype: "ni-calendar-grid-58 text-red"
  // }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = false;
    });
  }
  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
}
