export class InvoiceNewDeliverableLineItems {
    constructor(

        public Name :string,
        public SupplierCode:string,
        public SupplierID :number,
        public SupplierInvoiceID:string,
        public ProductID:number,
        public InvoiveDetailID :number,
        public Quantity:number,
        public SupplierName:string,
        public IsReceived:boolean,
        public IsException:boolean,
        public Comments:string,
        public InvoiceDate: string,
        public IsInvoiceReceived:boolean,
        public IsDeliverySubmitted:boolean,
        public DeliverableNumber:number,
        public ProductsExists:boolean,
        public CreatedBy: string,
        public AllLineItemData : object
     )
     {}
}

export class InvoiceNewDeliverableLineItemsDetails {
    constructor(

        public ProductName:string,
        public SupplierCode:string,
        public ProductID:number,
        public DeliverableNumber: number,
        public Quantity:number,
        public IsReceived:boolean,
        public IsException:boolean,
        public SupplierID :number,
        public SupplierInvoiceID:string,
        public InvoiceDate: string,
        public Comments:string,
        public ID:number,
        public ReceivedQuantity:number,
        public ExceptionQuantity:number,
        public ProductVariationID:number,
        public CreatedBy: string,
     )
     {}
}
