import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//Import primeng
import { SelectItem } from 'primeng/api';
import { MessageService,ConfirmationService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';

//Import models
import { Location } from '../../models/location';
//Import service
import { LocationService } from '../../services/location.service';

interface location {
  name: string,
  ModifiedDate: string,
  ID: string
}
interface sublocation {
  name: string;
  ModifiedDate: string;
  ID: string;
}

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css'],
  providers: [LocationService, MessageService,ConfirmationService,PrimeNGConfig],

  preserveWhitespaces: true

})
export class LocationComponent implements OnInit {
  locationForm: FormGroup;
  ParentlocationForm: FormGroup;
  errorMessage = "";
  successMessage = "";
  public inputSearch = "";
  public inputSearchsubLocation="";
  warehouse: SelectItem[];
  SelectWarehouse: SelectItem;

  LocationType: SelectItem[];
  SelectLocationType: SelectItem;

  public location: [];
  SelectLocation: location;

  subLocation: sublocation[];
  SelectSublocation: sublocation;




  //list data
  Listwarehouse: boolean = true;
  ListlocType: boolean = false;
  Listlocation: boolean = false;
  ListSublocation: boolean = false;

  //button 
  updatebtn: boolean = false;
  submitbtn: boolean = true;

  //range value
  rangeArray = [];
  rangeTotxt: boolean = true;

  //table binding
  clonedLocation: { [s: string]: Location; } = {};

  constructor(private router: Router,
    private formBuilder: FormBuilder, private locationService: LocationService,
    private messageService: MessageService,private confirmationService: ConfirmationService,private primengConfig: PrimeNGConfig

  ) {

  }

  ngOnInit(): void {


    this.primengConfig.ripple = true;

    this.getAllWarehouse();

    this.locationForm = this.formBuilder.group({
      ID: [''],
      Name: ['Null'],
      WarehouseID: [''],
      LocationTypeID: [''],
      ParentLocationID: [''],
      Prefix: [''],
      RangeFrom: [''],
      RangeTo: [''],
      
    });

  }
  get f() { return this.locationForm.controls; }


  //GET method for bindind Data to dropdown-start
  getAllWarehouse() {
    this.errorMessage = "";
    this.successMessage = "";
    this.locationService.getAllWarehouse()
      .subscribe(
        data => {
          this.warehouse = data;
        },
        error => {
          this.errorMessage = "Error while fetching warehouse data";
          this.hideMessage();
          return;
        })
  }

  getLocationType() {
    this.errorMessage = "";
    this.successMessage = "";
    this.locationService.getAllLocationType()
      .subscribe(
        data => {
          this.LocationType = data;
        },
        error => {
          this.errorMessage = "Error while fetching locationType data";
          this.hideMessage();
          return;
        })
  }

  getAllLocation(LocationTypeID) {
    var warehouseID = this.locationForm.value.WarehouseID
    this.locationService.getLocations(LocationTypeID, warehouseID)
      .subscribe(
        data => {
          this.location = data;
        },
        error => {
          this.errorMessage = "Error while fetching all location data";
          this.hideMessage();
          return;
        })
  }

  getSubLocation(ParentLocID) {
    var warehouseID = this.locationForm.value.WarehouseID;
    var locTypeID = this.locationForm.value.LocationTypeID;
    this.locationService.getSubLocations(ParentLocID, locTypeID, warehouseID)
      .subscribe(
        data => {
          this.subLocation = data;
        },
        error => {
          this.errorMessage = "Error while fetching sub-location data";
          this.hideMessage();
          return;
        })
  }
  //GET method for bindind Data to dropdown-end

  //Dropdown change event for dropdown - start
  onChangewarehouse(SelectWarehouse) {
    this.errorMessage = "";
    this.successMessage = "";
    this.getLocationType();
    if (this.SelectWarehouse != null || 0) {
      this.ListlocType = true;
      this.Listwarehouse = false;
    }
    else {
      this.ListlocType = false;
      this.Listwarehouse = false;
    }
  }

  onChangelocType(SelectLocationType) {
    this.errorMessage = "";
    this.successMessage = "";
    this.getAllLocation(SelectLocationType);
    if (this.SelectWarehouse != null || 0 && this.SelectLocationType != null || 0) {
      this.ListlocType = false;
      this.Listwarehouse = false;
      this.Listlocation = true;
      this.ListSublocation = false;
    }
    else {
      this.ListlocType = false;
      this.Listwarehouse = false;
      this.Listlocation = false;
      this.ListSublocation = false;
    }
  }

  onChangelocation(SelectLocation) {
    this.getSubLocation(SelectLocation);
    if (this.SelectWarehouse != null || 0 && this.SelectLocationType != null || 0
      && this.SelectLocation != null || 0) {
      this.ListlocType = false;
      this.Listwarehouse = false;
      this.Listlocation = false;
      this.ListSublocation = true;
    }
    else {
      this.ListlocType = false;
      this.Listwarehouse = false;
      this.Listlocation = false;
      this.ListSublocation = false;
    }
  }
  //Dropdown change event for dropdown - end

  //Number Regex -start
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  //Number Regex -end

  //regular regex
  keyPressAlphaNumericWithCharacters(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[A-Za-z]/.test(inp)) {
      this.rangeTotxt = false;
    } else {
      this.rangeTotxt = true;
    }
    if (/[A-Za-z0-9]/.test(inp)) {
      this.rangeTotxt = false;
    } else {
      this.rangeTotxt = true;
    }
    if (/[0-9]/.test(inp)) {
      this.rangeTotxt = true;
    } else {
      this.rangeTotxt = false;
    }

  }

  //POST method for location and sublocation - start
  AddLocation() {
    this.errorMessage = "";
    this.successMessage = "";

    this.locationForm.value["RangeFrom"] = this.locationForm.value["RangeFrom"] != 0 ? this.locationForm.value["RangeFrom"] : 0;

    if (this.locationForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      this.hideMessage();
      return;
    }

    if (this.locationForm.value["RangeFrom"] == 0) {
      this.errorMessage = "RangeFrom value is mandatory";
      this.hideMessage();
      return;
    }

    this.locationForm.value["ID"] = this.locationForm.value["ID"] != 0 ? this.locationForm.value["ID"] : 0;

    if (this.locationForm.value["ID"] > 0) {
      if (this.locationForm.value["RangeTo"] == "") {
        this.updateLocation(this.locationForm.value);
      }
      else {
        this.updateLocationRange(this.locationForm.value);
      }
    }
    else {
      if (this.locationForm.value["RangeTo"] == "") {
        this.CreateLocation(this.locationForm.value);
      }
      else {
        this.saveLocation(this.locationForm.value);
      }

    }

  }

  ///For multiple location & sub location
  saveLocation(location: Location) {
    this.errorMessage = "";
    this.successMessage = "";
    if (this.locationForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      this.hideMessage();
      return;
    }
    
    this.locationService.saveLocation(this.locationForm.value)
      .subscribe(
        data => {
          this.successMessage = "New location range added. If location exists in range then will modified";
          this.hideMessage();
          if (this.locationForm.value["ParentLocationID"]) {
            this.getSubLocation(this.locationForm.value['ParentLocationID']);
          }
          else {
            this.getAllLocation(this.locationForm.value['LocationTypeID']);
          }
          this.clearLocationForm();

        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Location already exists..!";
          }
          else {
            this.errorMessage = "Error while adding location data";
          }
          this.hideMessage();
          return;
        })
  }

  ////For create location & sublocation
  CreateLocation(location: Location) {
    this.errorMessage = "";
    this.successMessage = "";
    if (this.locationForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      this.hideMessage();
      return;
    }
    this.locationService.addNewLocation(this.locationForm.value)
      .subscribe(
        data => {
          this.successMessage = "Location Added";
          this.hideMessage();
          if (this.locationForm.value["ParentLocationID"]) {
            this.getSubLocation(this.locationForm.value['ParentLocationID']);
          }
          else {
            this.getAllLocation(this.locationForm.value['LocationTypeID']);
          }
          this.clearLocationForm();
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Location already exists..!";
          }
          else {
            this.errorMessage = "Error while adding location data";
          }
          this.hideMessage();
          return;
        })
  }
  //POST method for location and sublocation -end




  //PUT method for location and sublocation- start
  updateLocation(location: Location) {
    this.errorMessage = "";
    this.successMessage = "";
    this.locationService.updateLocation(this.locationForm.value)
      .subscribe(
        data => {
          this.updatebtn = false;
          this.submitbtn = true;
          this.successMessage = "Location Updated";
          this.hideMessage();
          if (this.locationForm.value["ParentLocationID"]) {
            this.getSubLocation(this.locationForm.value['ParentLocationID']);
          }
          else {
            this.getAllLocation(this.locationForm.value['LocationTypeID']);
          }
          this.clearLocationForm();
          return;
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Location already exists..!";
          }
          else {
            this.errorMessage = "Error while updating location data";
          }
          this.hideMessage();
          return;
        })

  }
  updateLocationRange(locations: Location) {
    this.errorMessage = "";
    this.successMessage = "";
    this.locationService.updateLocationRange(this.locationForm.value)
      .subscribe(
        data => {
          this.updatebtn = false;
          this.submitbtn = true;
          this.successMessage = "Existing location range will not add and new location range added";
          this.hideMessage();
          if (this.locationForm.value["ParentLocationID"]) {
            this.getSubLocation(this.locationForm.value['ParentLocationID']);
          }
          else {
            this.getAllLocation(this.locationForm.value['LocationTypeID']);
          }
          this.clearLocationForm();
          return;
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Location already exists..!";
          }
          else {
            this.errorMessage = "Error while updating location data";
          }
          this.hideMessage();
          return;
         
        })
  }
  //PUT method for location and sublocation -end



  //Delete location & sublocation -start
  deleteLocation(location) {
    this.errorMessage = "";
    this.successMessage = "";
    this.locationService.deleteLocation(location)
      .subscribe(
        data => {
          this.successMessage = "Location Deleted";
          this.hideMessage();
          if (this.locationForm.value["ParentLocationID"]) {
            this.getSubLocation(this.locationForm.value['ParentLocationID']);
          }
          else {
            this.getAllLocation(this.locationForm.value['LocationTypeID']);
          }
          this.clearLocationForm();
        },
        error => {
          this.errorMessage = "Error while deleting location data";
          this.hideMessage();
          return;
        })
  }
  //Delete location & sublocation -end

  //clear data
  clearLocationForm() {
    this.locationForm.patchValue({
      Prefix: '',
      RangeFrom: '',
      RangeTo: '',
      ID:''
    });
  }



  //hide message
  hideMessage() {
    setTimeout(() => {
      this.errorMessage = "";
      this.successMessage = "";
    }, 10000);
  }


  ///update location &  sublocation table

  onRowEditInit(location: Location) {
    this.errorMessage = "";
    this.successMessage = "";
    this.updatebtn = true;
    this.submitbtn = false;
    this.clonedLocation[location.ID] = { ...location };
    this.locationForm.patchValue({
      ID: this.clonedLocation[location.ID].ID,
      RangeFrom: this.clonedLocation[location.ID].Name,
    });
    window.scrollTo(100,100);

  }

  onRowEditCancel(locations: Location, index: number) {
    this.errorMessage = "";
    this.successMessage = "";
    this.location.indexOf[index] = this.clonedLocation[locations.ID];
    delete this.location[locations.ID];
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete Location?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteLocation(locations);
      },
      reject: () => {
      }
    });
  }
  clear(table: Table) {
    var a = this.inputSearch;
    table.clear();
    this.inputSearch ="" ;
    this.inputSearchsubLocation = "";
    this.errorMessage = "";
    this.successMessage = "";
   
  }

  onRowEditInitSub(Sublocation: Location) {
    this.errorMessage = "";
    this.successMessage = "";
    this.updatebtn = true;
    this.submitbtn = false;
    
    this.clonedLocation[Sublocation.ID] = { ...Sublocation };
    this.locationForm.patchValue({
      ID: this.clonedLocation[Sublocation.ID].ID,
      RangeFrom: this.clonedLocation[Sublocation.ID].Name,
    });
    window.scrollTo(100,100);

  }

  onRowEditCancelSub(Sublocation: Location, index: number) {
    this.errorMessage = "";
    this.successMessage = "";
    this.location.indexOf[index] = this.clonedLocation[Sublocation.ID];
    delete this.location[Sublocation.ID];
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete Sub-Location?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteLocation(Sublocation);
      },
      reject: () => {
      }
    });
  }
 
}
