import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Location } from '../models/location';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getAllLocationTypes(): Observable<any> {
    return this.http.get('/api/Location/GetAllLocationTypes', this.httpOptions);
  }
  getAllLocationType(): Observable<any> {
    return this.http.get('/api/Location/GetAllLocationTypes', this.httpOptions);
  }
  getLocations(id,warehouseID): Observable<any> {
    return this.http.get('/api/Location/GetLocationDetail?id=' + id + '&&warehouseID=' + warehouseID, this.httpOptions);
  }
  
  getSubLocations(id,locTypeID,warehouseID): Observable<any> {
    return this.http.get('/api/Location/GetSubLocations?id=' + id + '&&locTypeID=' + locTypeID + '&&warehouseID=' + warehouseID, this.httpOptions);
  }

  getAllLocations(): Observable<any> {
    return this.http.get('/api/Location/GetAllLocations', this.httpOptions);
  }

  getAllLocationToMap(): Observable<any> {
    return this.http.get('/api/Location/GetAllLocationToMap', this.httpOptions);
  }

  getAllWarehouse(): Observable<any>{
    //alert(JSON.stringify("service"));
  
    return this.http.get('/api/Location/GetAllWarehouse', this.httpOptions);
  
  }

  addNewLocation(location: Location) {
    return this.http.post('/api/Location/AddNewLocation', location, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  saveLocation(location: Location) {
    return this.http.post('/api/Location/SaveLocation', location, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  updateLocation(location) {
    return this.http.post('/api/Location/UpdateLocation', location, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
  updateLocationRange(location){
    return this.http.post('/api/Location/UpdateLocationRange', location, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
  deleteLocation(locationID) {
    return this.http.post('/api/Location/DeleteLocation', locationID, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getMappedLocationsBasedOnProduct(id): Observable<any> {
    return this.http.get('/api/Location/GetMappedLocationsBasedOnProduct?id=' + id, this.httpOptions)
    //return this.http.get('/api/Location/GetLocationDetail?id=' + id)
  }
}
