<div class=" header bg-danger">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">
          <h6 class=" h2 text-white d-inline-block mb-0">Product</h6>
          <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
            <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> LocationType </a>
              </li>
              <li aria-current="page" class=" breadcrumb-item active">LocationType</li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">
          <button class=" btn btn-sm btn-neutral" type="button" label="New" (click)="showLocationTypeForm()"
                  pButton></button>
          <button class=" btn btn-sm btn-neutral" type="button" label="Show LocationType"
                  (click)="showAllLocationType()" pButton></button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShownLocationTypeForm" class=" col-lg-12 card-wrapper" style="margin-top: 10px;">
  <div class=" card">
    <form [formGroup]="LocationTypeForm">
      <div class=" card-body">
        <div class="form-group row">
          <div class="col-md-12 col-lg-6 col-sm-12">
            <div class=" card">
              <div class=" card-header">
                <h3 class=" mb-0">LocationType</h3>
              </div>
              <div class=" card-body" style=" height: 100px;">
                <div class="form-group row">
                  <div class="col-md-6">
                    <input id="LocationTypeName" class="form-control" type="text"
                           placeholder="LocationType Name" formControlName="Name" required pInputText>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" id="addButton" label="Submit" (click)="addLocationType()"
                    pButton></button>
            <label ng-model="successMessage" ng-bind="successMessage"
                   class="success">{{successMessage}}</label>
            <label ng-model="errorMessage" ng-bind="errorMessage" class="error">{{errorMessage}}</label>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class=" col-lg-12 card-wrapper" style="margin-top: 10px;">
  <div class=" card">
    <div class=" card-header">
      <h3 class=" mb-0">Location Type Details</h3>
    </div>

    <div class=" card-body">
      <p-table #dt1 [value]="locationTypes" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[25,50,100]" [loading]="loading" styleClass="p-datatable-locationTypes" [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
               [globalFilterFields]="['Name']">
        <ng-template pTemplate="caption">
          <div class=" row">
            <div class=" col-lg-6 col-6">
              <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
            </div>
            <div class=" col-lg-6 col-6">
              <input class="inputSearch" pInputText type="search" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" [(ngModel)]="inputSearch" />
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th hidden>ID</th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Location Type
                <!--<p-columnFilter type="text" field="Name" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th style="width: 8rem; text-align: center">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-locationTypes let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="locationTypes">
            <td hidden pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="locationTypes.ID">
                </ng-template>
                <ng-template pTemplate="output">
                  {{locationTypes.ID}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="locationTypes.Name" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{locationTypes.Name}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td style="text-align:center">
              <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(locationTypes)" class="p-button-rounded p-button-text"></button>
              <p-toast></p-toast>
              <p-confirmPopup></p-confirmPopup>
              <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(locationTypes, ri,$event)" class="p-button-rounded p-button-text p-button-danger"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-toast position="bottom-center"></p-toast>
    </div>
  </div>
</div>
