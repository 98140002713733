import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { Supplier } from '../models/supplier';
import { map } from 'rxjs/operators';
import { MultiPackProducts } from '../models/multipackproducts'
@Injectable({
  providedIn: 'root'
})
export class MultipackproductService {

  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getAllMultipackProduct(): Observable<any> {
    return this.http.get('/api/MutipackProduct/GetAllMultipackproduct', this.httpOptions);
  }
  getAllSuppliers(): Observable<any> {
    return this.http.get('/api/MutipackProduct/GetAllSuppliers', this.httpOptions);
  }
  getProductList(ID): Observable<any> {
    return this.http.get('/api/MutipackProduct/GetProductList?id=' + ID, this.httpOptions);
  }
  getMultipackProduct(ID): Observable<any> {
    return this.http.get('/api/MutipackProduct/GetMultipackProductList?id=' + ID, this.httpOptions);
  }
  saveMultipackProduct(product: MultiPackProducts) {
    return this.http.post('/api/MutipackProduct/saveMultipackProduct', product, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
  updateMultipackProduct(multipackproduct: MultiPackProducts) {
    return this.http.post('/api/MutipackProduct/updateMutipackProduct', multipackproduct, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
  deleteMultipackProduct(ID) {
    return this.http.post('/api/MutipackProduct/DeleteMultipackProduct', ID, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
}
