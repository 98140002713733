import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { InvoiceLineItems } from '../models/invoice-line-items.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceManagementService {

  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  deleteInvoice(invoiceLine: InvoiceLineItems) {
    return this.http.post('/api/InvoiceDetails/DeleteInvoice', invoiceLine, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  updateInvoice(invoice: InvoiceLineItems) {
    return this.http.post('/api/InvoiceDetails/UpdateInvoice', invoice, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getInvoice(invoice: InvoiceLineItems) {
    return this.http.post('/api/InvoiceDetails/GetInvoice', invoice, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getVariations(id): Observable<any> {
    return this.http.get('/api/InvoiceDetails/GetByProductID/' + id, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getAllInvoice(supplierID, supplierInvoiceID){
    return this.http.get('/api/InvoiceDetails/GetInv/' + supplierID + '/' + supplierInvoiceID , this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getlengthofInvoiceItems(id): Observable<any> {
    return this.http.get('/api/InvoiceDetails/CheckInvoiceLineItems/' + id, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getOtherCharges(): Observable<any> {
    //return this.http.get('/api/ProductManagement/GetAllProductDetails');
    return this.http.get('/api/InvoiceDetails/GetOtherChargesTypes', this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
  getExtractedIsSubmitted(invoice: InvoiceLineItems) {
    return this.http.post('/api/InvoiceDetails/ExtractSubmittedInvoice', invoice, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
}
