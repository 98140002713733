<div class=" header bg-danger">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">
          <h6 class=" h2 text-white d-inline-block mb-0">Product</h6>
          <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
            <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> Location </a>
              </li>
              <li aria-current="page" class=" breadcrumb-item active">Location</li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">
        </div>
      </div>
    </div>
  </div>
</div>

<div class=" col-lg-12 card-wrapper">
  <div class=" card">

    <div class=" card-header">
    </div>
    <div class=" card-body">
      <div class="form-group row">

        <div class="col-md-6 col-lg-6 col-sm-12">
          <form [formGroup]="locationForm">
            <div class=" card">
              <div class=" card-header">
                <h3 class=" mb-0">Location</h3>
              </div>
              <div class=" card-body" style=" height: auto;">
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown [style]="{'width':'25%'}" styleClass="primeng-dropdown" [options]="warehouse"
                                  [virtualScroll]="true" [filter]="false" optionLabel="Name" [(ngModel)]="SelectWarehouse"
                                  optionValue="ID" [autoDisplayFirst]="false" formControlName="WarehouseID"
                                  (onChange)="onChangewarehouse(SelectWarehouse)">
                      </p-dropdown>
                      <label for="warehouse">Warehouse</label>
                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown [autoDisplayFirst]="false" [style]="{'width':'25%'}" styleClass="primeng-dropdown"
                                  [(ngModel)]="SelectLocationType" [options]="LocationType" [virtualScroll]="true" [filter]="true"
                                  optionLabel="Name" optionValue="ID" (onChange)="onChangelocType(SelectLocationType)"
                                  formControlName="LocationTypeID">
                      </p-dropdown>
                      <label for="LocType">Location Type</label>

                    </span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <span class="p-float-label">
                      <p-dropdown [autoDisplayFirst]="false" [style]="{'width':'25%'}" styleClass="primeng-dropdown"
                                  [(ngModel)]="SelectLocation" [options]="location" [virtualScroll]="true" [filter]="true"
                                  optionLabel="Name" optionValue="ID" formControlName="ParentLocationID"
                                  (onChange)="onChangelocation(SelectLocation )">
                        <option>select ParentLocation</option>

                      </p-dropdown>
                      <label for="ParentLocation">Parent Location</label>

                    </span>

                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <span class="p-float-label">
                      <input id="Sub Location" class="form-control" type="text" formControlName="Prefix" pInputText>
                      <label for="prefix">Prefix</label>
                    </span>
                  </div>

                </div>

                <h5>Range:</h5>

                <div class="form-group row">
                  <div class="col-md-3">
                    <span>
                      <input [style]="{'width':'100%'}" (keypress)="keyPressAlphaNumericWithCharacters($event)"
                             id="RangeFrom" formControlName="RangeFrom" class="form-control" type="text" pInputText>
                    </span>
                  </div>
                  <div class="col-md-3" *ngIf="rangeTotxt">
                    <span>
                      <input [style]="{'width':'100%'}" (keypress)="numberOnly($event)" id="RangeTo"
                             formControlName="RangeTo" class="form-control" type="text" pInputText>
                    </span>
                  </div>
                </div>
                <div class="form-group row" *ngIf="submitbtn">
                  <div class="col-md-6">
                    <button type="button" label="Submit" (click)="AddLocation()" pButton></button>
                  </div>
                </div>
                <div class="form-group row" *ngIf="updatebtn">
                  <div class="col-md-6">
                    <button type="button" label="Update" (click)="AddLocation()" pButton></button>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <label ng-model="successMessage" ng-bind="successMessage" class="success">{{successMessage}}</label>
                    <label ng-model="errorMessage" ng-bind="errorMessage" class="error">{{errorMessage}}</label>
                  </div>

                </div>
              </div>

            </div>
          </form>

        </div>


        <div class="col-md-6 col-lg-6 col-sm-12">
          <div class="card" *ngIf="Listwarehouse">
            <div class=" card-header">
              <h3 class=" mb-0">Warehouse</h3>
            </div>
            <div class=" card-body" style=" height: 350px;">
              <ul *ngFor="let whouse of warehouse">

                <p>{{whouse.Name}}</p>

              </ul>
            </div>

          </div>
          <div class="card" *ngIf="ListlocType">
            <div class=" card-header">
              <h3 class=" mb-0">Location Type</h3>
            </div>
            <div class=" card-body" style=" height: 350px;">
              <ul *ngFor="let locationtype of LocationType">

                <p>{{locationtype.Name}}</p>

              </ul>
            </div>

          </div>
          <div class="card" *ngIf="Listlocation">
            <div class="card-header">
              <h3 class=" mb-0">Location</h3>
            </div>
            <div class=" card-body" style=" height: auto;">

              <p-table #dt1 [value]="location" dataKey="id" sortField="ModifiedDate" sortOrder="-1" [rows]="25"
                       [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,100,150]" [loading]="loading"
                       styleClass="p-datatable-Locations" [paginator]="true"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                       [globalFilterFields]="['Name']">
                <ng-template pTemplate="caption">
                  <div class=" row">
                    <div class=" col-lg-6 col-6">
                      <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                              (click)="clear(dt1)"></button>
                    </div>
                    <div class=" col-lg-6 col-6">
                      <input class="inputSearch" pInputText type="search" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                             placeholder="Search keyword" [(ngModel)]="inputSearch" />
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th hidden>ID</th>
                    <th hidden>Modify date</th>
                    <th>
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Location Name
                        <!--<p-columnFilter type="text" field="Name" display="menu"></p-columnFilter>-->
                      </div>
                    </th>
                    <th style="width: 8rem; text-align: center">Actions</th>
                  </tr>

                </ng-template>
                <ng-template pTemplate="body" let-location let-editing="editing" let-ri="rowIndex">
                  <tr [pEditableRow]="Locations">
                    <td hidden pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="location.ID">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{location.ID}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td hidden pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="location.ModifiedDate">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{location.ModifiedDate}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="location.Name" required>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{location.Name}}
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td style="text-align:center">
                      <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                              (click)="onRowEditInit(location)" class="p-button-rounded p-button-text"></button>
                      <p-toast></p-toast>
                      <p-confirmPopup></p-confirmPopup>
                      <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                              (click)="onRowEditCancel(location, ri)"
                              class="p-button-rounded p-button-text p-button-danger"></button>
                    </td>
                  </tr>
                </ng-template>



              </p-table>


            </div>

          </div>

          <div class="card" *ngIf="ListSublocation">

            <div class=" card-body" style=" height: auto;">
              <p-table #dt2 [value]="subLocation" dataKey="id" [rows]="25" sortField="ModifiedDate" sortOrder="-1"
                       [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,100]" [loading]="loading"
                       styleClass="p-datatable-SubLocations" [paginator]="true"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                       [globalFilterFields]="['Name']">
                <ng-template pTemplate="caption">
                  <div class=" row">
                    <div class=" col-lg-6 col-6">
                      <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                              (click)="clear(dt2)"></button>
                    </div>
                    <div class=" col-lg-6 col-6">
                      <input class="inputSearch" pInputText type="search" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                             placeholder="Search keyword" [(ngModel)]="inputSearchsubLocation" />
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th hidden>ID</th>
                    <th hidden>ModifiedDate</th>

                    <th>
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Sub-Location Name
                        <!--<p-columnFilter type="text" field="Name" display="menu"></p-columnFilter>-->
                      </div>
                    </th>
                    <th style="width: 8rem; text-align: center">Actions</th>
                  </tr>

                </ng-template>
                <ng-template pTemplate="body" let-subLocation let-editing="editing" let-ri="rowIndex">
                  <tr [pEditableRow]="subLocation">
                    <td hidden pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="subLocation.ID">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{subLocation.ID}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td hidden pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="subLocation.ModifiedDate">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{subLocation.ModifiedDate}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="subLocation.Name" required>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{subLocation.Name}}
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td style="text-align:center">
                      <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                              (click)="onRowEditInitSub(subLocation)" class="p-button-rounded p-button-text"></button>
                      <p-toast></p-toast>
                      <p-confirmPopup></p-confirmPopup>
                      <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                              (click)="onRowEditCancelSub(subLocation, ri)"
                              class="p-button-rounded p-button-text p-button-danger"></button>
                    </td>
                  </tr>
                </ng-template>



              </p-table>

            </div>

          </div>

        </div>

      </div>

    </div>


  </div>
</div>
