export class Productvariations {
  constructor(
    public ID: Number,
    public ProductID: Number,
    public Name: String,
    public ProductVariationCode: String,
    public Barcode: Number,
    public VariationDetail: String,
    public ProductVariationTypeID: Number,
    public Items: object,
  ) { }
}
