import { Component, OnInit,HostListener } from '@angular/core';

@Component({
  selector: 'app-layout-admin',
  templateUrl: './layout-admin.component.html',
  styleUrls: ['./layout-admin.component.css']
})
export class LayoutAdminComponent implements OnInit {
  isMobileResolution: boolean;

  constructor() {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
   }
   @HostListener("window:resize", ["$event"])
   isMobile(event) {
     if (window.innerWidth < 1200) {
       this.isMobileResolution = true;
     } else {
       this.isMobileResolution = false;
     }
   }

  ngOnInit(): void {
  }

}
