<div class=" header bg-danger" *ngIf="header">
    <div class=" container-fluid">
      <div class=" header-body">
        <div class=" row align-items-center py-4">
          <div class=" col-lg-6 col-7">
            <h6 class=" h2 text-white d-inline-block mb-0">Product</h6>
            <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
              <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
                <li class=" breadcrumb-item">
                  <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
                </li>
                <li class=" breadcrumb-item">
                  <a href="javascript:void(0)"> Season </a>
                </li>
                <li aria-current="page" class=" breadcrumb-item active">Season</li>
              </ol>
            </nav>
          </div>
  
          <div class=" col-lg-6 col-5 text-right">
            <button class=" btn btn-sm btn-neutral" type="button" label="New" (click)="showSeasonsForm()" pButton></button>
            <button class=" btn btn-sm btn-neutral" type="button" label="Show Season" (click)="showAllSeasons()" pButton></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <p-dialog header="Season" [closable]="false" [(visible)]="isShownSeasonsForm" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '25%'}" [baseZIndex]="10000" [modal]="true">
    <div class="offset-lg-11 justify-content-end">
      <button  class="p-button-rounded p-button-secondary p-button-text btn-close" type="button" pButton pRipple (click)="Close()" icon="pi pi-times">
  </button></div>
  <div class=" col-lg-12 card-wrapper" style="margin-top: 10px;">
    <div class=" card">
      <form [formGroup]="SeasonsForm">
        <div class=" card-body"> 
                  <div class="form-group row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <input id="SeasonsName" class="form-control" type="text" placeholder="Season Name" formControlName="Name" required pInputText autocomplete="off">
                    </div>
                  </div>
              <button type="button" id="addButton" label="Submit" (click)="addSeasons()" pButton></button>
              <label *ngIf="successMessageSup" class="success">{{successMessageSup}}</label>
              <label *ngIf="errorMessageSup" class="error">{{errorMessageSup}}</label>
            
         </div>
      </form>
    </div>
  </div>
  </p-dialog>
  
  
  <div class=" col-lg-12 card-wrapper" *ngIf="grid" style="margin-top: 10px;">
    <div class=" card">
      <div class=" card-header">
        <h3 class=" mb-0">Season Details</h3>
      </div>
  
      <div class=" card-body">
        <p-table #dt1 [value]="seasonsData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                 [rowsPerPageOptions]="[25,50,100]" [loading]="loading" styleClass="p-datatable-seasonsData" [paginator]="true"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 [globalFilterFields]="['Name']">
          <ng-template pTemplate="caption">
            <div class=" row">
              <div class=" col-lg-4 col-4">
                <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
              </div>
              <div class=" col-lg-8 col-8 text-right">
                <input class="inputSearch" pInputText type="search" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" [(ngModel)]="inputSearch" />
              </div>
            </div>
          </ng-template>
  
          <ng-template pTemplate="header">
            <tr>
              <th hidden>
                  ID
              </th>
              <th>Season Name
                  <!--<p-columnFilter type="text" field="Name" display="menu"></p-columnFilter>-->
              </th>
              <th style="width: 8rem; text-align: center">Actions</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-seasonsData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="seasonsData">
              <td hidden pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="seasonsData.ID">
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{seasonsData.ID}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="seasonsData.Name" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{seasonsData.Name}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align:center">
                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(seasonsData)" class="p-button-rounded p-button-text"></button>
                <!--<button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(seasonsData)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>-->
                <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(seasonsData, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  