import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//Import Service
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { NavMenuComponent } from '../../../../layouts/nav-menu/nav-menu.component';
import { Seasons } from '../../models/Seasons';
import { SeasonsService } from '../../services/seasons.service';

@Component({
  selector: 'app-seasons',
  templateUrl: './seasons.component.html',
  styleUrls: ['./seasons.component.css'],
  providers: [SeasonsService, MessageService, NavMenuComponent],
  preserveWhitespaces: true
})
export class SeasonsComponent implements OnInit {

  @Input() isShownSeasonsForm: boolean = false;
  @Input() header: boolean = true;
  @Input() grid: boolean = true;
  @Output() isSeasonsVal = new EventEmitter<any>();
  public seasons: Seasons = new Seasons(null, null, null);
  clonedSeasons: { [s: string]: Seasons; } = {};
  SeasonsForm: FormGroup;
  //public isShownSeasonsForm: boolean;
  errorMessage = "";
  successMessage = "";
  public seasonsData = [];
  public loading: boolean = false;
  public inputSearch = "";
  errorMessageSup: string = "";
  successMessageSup: string = "";
  seasonsValue: string = "";

  constructor(
    public seasonsService: SeasonsService,
    private messageService: MessageService,
    private fromBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.isShownSeasonsForm = false;
    this.errorMessage = "";
    this.successMessage = "";
    this.SeasonsForm = this.fromBuilder.group({
      ID: [''],
      Name: ['', Validators.required,],
      IsDeleted: [false],
    });
    this.SeasonsForm.controls['Name'].setErrors(null);
    this.GetAllSeasons();
  }
  get f() { return this.SeasonsForm.controls }

  showSeasonsForm() {
    this.errorMessage = "";
    this.successMessage = "";
    this.clearSeasonsForm();
    this.isShownSeasonsForm = true;
  }

  Close(){
    this.errorMessageSup = "";
    this.successMessageSup = "";
    this.isShownSeasonsForm = false;
    this.clearSeasonsForm();
    this.isSeasonsVal.emit({isShownSeasons: this.isShownSeasonsForm, seasonsValue: this.seasonsValue});
  }

  showAllSeasons() {
    this.isShownSeasonsForm = false;
  }

  addSeasons() {
    this.errorMessage = "";
    this.successMessage = "";
    if (this.SeasonsForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      return;
    }
    this.SeasonsForm.value["ID"] = this.SeasonsForm.value["ID"] != 0 ? this.SeasonsForm.value["ID"] : 0;

    if (this.SeasonsForm.value["ID"] > 0) {
      this.updateSeasons(this.SeasonsForm.value);
    }
    else {
      this.saveSeasons(this.SeasonsForm.value);
    }
  }


  GetAllSeasons() {
    this.loading = true;
    this.seasonsService.GetAllSeasons()
      .subscribe(
        data => {
          //this.seasonsService.seasonsData = data;
          this.seasonsData = data;
          this.loading = false;
        },
        error => {
          this.errorMessage = "Error while fetching Seasons data";
          return;
        })
  }



  saveSeasons(seasons: Seasons) {
    this.seasonsValue = this.SeasonsForm.get('Name').value;
    this.seasonsService.SaveSeasons(this.SeasonsForm.value)
      .subscribe(
        data => {
          this.clearSeasonsForm();
          this.successMessageSup = "Season Added";
          this.errorMessageSup = "";
          this.GetAllSeasons();
          return;
        },
        error => {
          this.errorMessageSup = "Error while saving Season data";
          this.successMessageSup = "";
          return;
        });

  }

  updateSeasons(seasons: Seasons) {
    this.seasonsValue = this.SeasonsForm.get('Name').value;
    this.seasonsService.UpdateSeasons(seasons)
      .subscribe(
        data => {
          this.clearSeasonsForm();
          this.errorMessageSup = "";
          this.successMessageSup = "Season Updated";
          this.GetAllSeasons();
          return;
        },
        error => {
          this.successMessageSup = "";
          this.errorMessageSup = "Error while updating Season data";
          return;
        })

  }

  DeleteSeasons(seasons) {
    this.errorMessage = "";
    this.successMessage = "";
    this.seasonsService.DeleteSeasons(seasons)
      .subscribe(
        data => {
          this.clearSeasonsForm();
          this.errorMessageSup = "";
          this.successMessage = "Season Deleted";
          this.GetAllSeasons();
          return;

        },
        error => {
          this.errorMessage = "Error while deleting Season data";
          return;
        })

  }



  hideMessage() {
    setTimeout(() => {
      this.errorMessage = "";
    }, 5000);
  }
  clear(table: Table) {
    table.clear();
    this.inputSearch = "";
  }

  clearSeasonsForm() {
    this.SeasonsForm.setValue({
      ID: 0,
      Name: '',
      IsDeleted: false,
    });
    this.SeasonsForm.controls['Name'].setErrors(null);
  }

  onRowEditInit(seasons: Seasons) {
    this.successMessage = "";
    this.errorMessage = "";
    this.isShownSeasonsForm = true;;
    this.clonedSeasons[seasons.ID] = { ...seasons };
    this.SeasonsForm.setValue({
      ID: this.clonedSeasons[seasons.ID].ID,
      Name: this.clonedSeasons[seasons.ID].Name,
      IsDeleted: this.clonedSeasons[seasons.ID].IsDeleted,
    });
  }

  onRowEditCancel(seasons: Seasons, index: number) {
    this.errorMessage = "";
    this.successMessage = "";
    this.seasonsData[index] = this.clonedSeasons[seasons.ID];
    delete this.seasonsData[seasons.ID];
    this.DeleteSeasons(seasons);
    //this.getAllSeasons();
  }

  onRowEditSave(seasons: Seasons) {
    if (seasons.ID > 0) {
      delete this.clonedSeasons[seasons.ID];
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Seasons is updated' });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error is updating Season detail' });
    }
  }

}
