<div class=" header bg-danger">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">
          <h6 class=" h2 text-white d-inline-block mb-0">Pricing</h6>
          <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
            <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> Invoice </a>
              </li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">
          <button class="btn btn-sm btn-neutral" type="button" label="New" (click)="newInvoiceForm()" pButton></button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--<div class="deskContent">-->
<div class=" col-lg-12 card-wrapper" style="margin-top: 10px;">
  <div class=" card">
    <div class="card-body">
      <!--<p-fieldset legend="Invoice Form">-->
      <form [formGroup]="InvoiceForm">
        <p-fieldset legend="Invoice">
          <div class="row">
            <div class="col-lg-4">
              <div class="row align-items-end">
                <div class="col-lg-10">
                  <label class="p-text-bold" for="Supplier">Supplier</label>
                  <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="suppliers" [(ngModel)]="invoiceLineItems.SupplierID" placeholder="Select Supplier" (onChange)="onSelectSupplierChange($event)"
                              [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                              optionLabel="Name" optionValue="ID" formControlName="SupplierID" required>
                  </p-dropdown>
                  <span *ngIf="errorMessageSuppliers" class="error">{{ errorMessageSuppliers }}</span>
                </div>
                <div class="col-lg-2">
                  <p-button styleClass="float-right" (click)="showAddSupplier()" icon="fa fa-plus" pTooltip="Add new supplier"></p-button>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <label class="p-text-bold" for="Number">Number</label><br />
              <input id="InvoiceNumber" type="text" name="InvoiceNumber" pInputText [(ngModel)]="invoiceLineItems.SupplierInvoiceID"
                     (keyup)="onChangeInvoiceNumber(invoiceLineItems.SupplierID, invoiceLineItems.SupplierInvoiceID)"
                     (keyup)="checkInvoiceNumber(invoiceLineItems.SupplierInvoiceID)"
                     class="form-control" formControlName="InvoiceNumber" autocomplete="off" required />
              <span *ngIf="errorMessageInvoiceNumber" class="error">{{ errorMessageInvoiceNumber }}</span>
            </div>
            <div class="col-lg-2">
              <label class="p-text-bold" for="Date">Date</label><br />
              <p-calendar [(ngModel)]="selectDate"
                          [showIcon]="true"
                          [readonlyInput]="true"
                          dateFormat="dd-mm-yy"
                          (ngModelChange)="storeSelectedDate(selectDate)"
                          (onSelect)="checkInvoiceDate($event)"
                          showButtonBar="true" 
                          formControlName="SupplierDate" required>
              </p-calendar>
              <span *ngIf="errorMessageInvoiceDate" class="error">{{ errorMessageInvoiceDate }}</span>
          </div>  
            <div class="col-lg-2">
              <label class="p-text-bold" for="Discount">Discount(%)</label>
              <input id="input" type="number" pInputText [(ngModel)]="invoiceLineItems.DiscountPercentage" class="form-control" formControlName="SupplierDiscount"
                     (focusout)="checkInvoiceDiscount(invoiceLineItems.DiscountPercentage)" (keyup)="onChangeDiscount(invoiceLineItems.DiscountPercentage)" />
              <span *ngIf="errorMessageDiscountPercentage" class="error">{{ errorMessageDiscountPercentage }}</span>
            </div>
          </div>
        </p-fieldset><br />
        <p-fieldset legend="Invoice Line Items">
          <br />
          <div class="row">
            <div class="col-lg-4">
              <label class="p-text-bold" for="ProductCode">Product Code</label><br />
              <input id="input" type="text" name="ProductCode" pInputText [(ngModel)]="invoiceLineItems.SupplierProductCode" (keyup)="onChangeProductCode(invoiceLineItems.SupplierProductCode)" class="form-control" formControlName="ProductCode" required
                     autocomplete="off" />
              <span *ngIf="errorMessageProductCode" class="error">{{ errorMessageProductCode }}</span>
            </div>
            <div class="col-lg-4">
              <div class="row align-items-end">
                <div class="col-lg-10">
                  <label class="p-text-bold" for="ProductTitle">Product Title</label>
                  <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="product" placeholder="Select Product" (onChange)="onSelectProductChange($event)"
                              [(ngModel)]="SelectedProductID" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                              optionLabel="Name" optionValue="ID" formControlName="SelectedProductID" required>
                  </p-dropdown>
                  <span *ngIf="errorMessageSelectedProduct" class="error">{{ errorMessageSelectedProduct }}</span>
                </div>
                <div class="col-lg-2">
                  <p-button styleClass="float-right" (click)="showAddProduct()" icon="fa fa-plus" pTooltip="Add new product"></p-button>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-1" style="margin-top: 30px;">
              <label for="AddNewProduct" style="visibility: hidden;">Add</label> -->
            <!-- <p-dialog header="Add New Product" [(visible)]="AddProduct" [modal]="true" [responsive]="true" [width]="1200" [minWidth]="500" [minY]="70"
                      [maximizable]="true" [baseZIndex]="10000">
              <div class="form-group row">
                <div class="col-lg-3">
                  <label for="ProductName">Product Name</label>&nbsp;&nbsp;
                  <input type="text" name="ProductName" pInputText>
                </div>
                <div class="col-lg-3">
                  <label for="ProductCode">Product Code</label>&nbsp;&nbsp;
                  <input type="text" name="ProductCode" pInputText>
                </div>
                <div class="col-lg-3">
                  <label for="vatTypes">VAT Type</label>&nbsp;&nbsp;
                  <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="vatTypes" [virtualScroll]="true" [filter]="true"
                              [(optionLabel)]="VATCode" optionValue="ID" [autoDisplayFirst]="false" placeholder="Select VAT Type">
                  </p-dropdown>
                </div>
                <div class="col-lg-3">
                  <label for="BarCode">Bar Code</label><br />
                  <input type="text" name="BarCode" pInputText>
                </div>
              </div>
              <p-footer>
                <button type="button" pButton icon="pi pi-check" (click)="display=false" label="Add"></button>
                <button type="button" pButton icon="pi pi-close" (click)="display=false" label="Cancel" class="ui-button-secondary"></button>
              </p-footer>
            </p-dialog> -->
            <!-- <a name="add"><i class="fa fa-plus"></i></a> -->
            <!-- <p-button (click)="showAddProduct()" icon="fa fa-plus" pTooltip="Add new product"></p-button> -->
            <!-- </div> -->

            <div class="col-lg-2" *ngIf="productVariations.length>0">
              <label class="p-text-bold" for="ProductVariation">Product Variation</label>
              <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="productVariations" [virtualScroll]="true" [filter]="true"
                          [(ngModel)]="ProductVariation" (onChange)="onSelectProductVariation($event)"
                          optionLabel="Name" optionValue="ID" formControlName="ProductVariation" [autoDisplayFirst]="false" placeholder="Select Product Variation">
              </p-dropdown>
            </div>
            <div class="col-lg-2" [ngClass]="{'col-lg-4': productVariations.length===0}">
              <label class="p-text-bold" for="vatTypes">VAT Type</label>&nbsp;&nbsp;
              <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="vatTypes" [virtualScroll]="true" [filter]="true"
                          (onChange)="onSelectVATChange($event)" [(ngModel)]="invoiceLineItems.VATTypeID" optionLabel="VATCode" optionValue="ID"
                          formControlName="VatTypeID" [autoDisplayFirst]="false" placeholder="Select VAT Type" required>
              </p-dropdown>
              <span *ngIf="errorMessageVATTypes" class="error">{{ errorMessageVATTypes }}</span>
            </div>
          </div><br />
          <div class="row">
            <div class="col-lg-4">
              <label class="p-text-bold" for="Quantity">Quantity</label><br />
              <input id="input" type="number" pInputText [(ngModel)]="invoiceLineItems.Quantity" step="0"
                     (keyup)="onChangeQuantity($event.target.value)" class="form-control" formControlName="ProductQuantity"
                     required />
              <span *ngIf="errorMessageQuantity" class="error">{{ errorMessageQuantity }}</span>
            </div>
            <div class="col-lg-4">
              <label class="p-text-bold" for="UnitPrice">Unit Price</label><br />
              <input id="input" type="number" pInputText [(ngModel)]="invoiceLineItems.UnitPrice"
                     (keyup)="onChangeUnitPrice($event.target.value)"
                     (keyup)="NetAmount(invoiceLineItems.Quantity, invoiceLineItems.UnitPrice)" class="form-control" formControlName="ProductUnitPrice"
                     required />
              <span *ngIf="errorMessageUnitPrice" class="error">{{ errorMessageUnitPrice }}</span>
            </div>
            <div class="col-lg-4">
              <label class="p-text-bold" for="NetPrice">Net Price</label><br />
              <input id="input" type="number" pInputText [disabled]="disabled" [(ngModel)]="invoiceLineItems.NetPrice"
                     class="form-control" formControlName="ProductNetPrice" />
            </div>
          </div><br />
          <div class="text-right">
            <button type="button" pButton (click)="addInvoice('top')" label="Add" [disabled]="DisabledAdd || invoiceLineItems.IsInvoiceSubmitted==true"></button>
          </div>
          <p-dialog class="prev-unitprice" id="Check" header="Check" [(visible)]="checkInvoice" [position]="position" [style]="{width: '50vw'}" [baseZIndex]="10000"
                    [draggable]="false" [resizable]="false">
            <div class="p-text-center">
              The {{invoiceLineItems.UnitPrice}} current price of product {{invoiceLineItems.Label_ProductName}} is higher than previous bought price
            </div>
            <p-table class="align-items-center" [value]="previousUnitPrice">
              <ng-template pTemplate="header">
                <tr>
                  <th>Invoice Date</th>
                  <th>Previous Price</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-previousUnitPrice>
                <tr>
                  <td>{{previousUnitPrice.InvoiceDate | date: 'dd-MM-yyyy'}}</td>
                  <td>{{previousUnitPrice.UnitPrice}}</td>
                </tr>
              </ng-template>
            </p-table>
            <p-footer>
              <button type="button" pButton icon="pi pi-check" (click)="onCheckInvoiceAccepted()" label="Ok"></button>
              <button type="button" pButton icon="pi pi-close" (click)="onCheckInvoiceCancelled()" label="Cancel" class="ui-button-secondary"></button>
            </p-footer>
          </p-dialog>
          <div class="text-left p-text-bold" *ngIf="errorMessageInv">
            <label [(ngModel)]="errorMessageInv" class="error">{{errorMessageInv}}</label>
          </div>
          <div class="text-left p-text-bold" *ngIf="successMessageInv">
            <label [(ngModel)]="successMessageInv" class="success">{{successMessageInv}}</label>
          </div>
        </p-fieldset><br />
        <p-table #dt2 [value]="invoiceItems" dataKey="ID" [loading]="loading" styleClass="p-datatable-invoiceItems">

          <ng-template pTemplate="header">
            <tr>
              <th hidden>ID</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Product Code
                  <!-- <p-columnFilter type="text" field="ProductCode" display="menu"></p-columnFilter> -->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Product Title
                  <!-- <p-columnFilter type="text" field="ProductTitle" display="menu"></p-columnFilter> -->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  VAT Type
                  <!-- <p-columnFilter type="text" field="vatTypes" display="menu"></p-columnFilter> -->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Quantity
                  <!-- <p-columnFilter type="text" field="Quantity" display="menu"></p-columnFilter> -->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Unit Price
                  <!-- <p-columnFilter type="text" field="UnitPrice" display="menu"></p-columnFilter> -->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Post Discount Unit Price
                  <!-- <p-columnFilter type="text" field="PostDiscountUnitPrice" display="menu"></p-columnFilter> -->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Net Price
                  <!-- <p-columnFilter type="text" field="NetPrice" display="menu"></p-columnFilter> -->
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  VAT Price
                  <!-- <p-columnFilter type="text" field="VATPrice" display="menu"></p-columnFilter> -->
                </div>
              </th>
              <th style="width: 8rem; text-align: center">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Action
                  <!-- <p-columnFilter type="text" field="Action" display="menu"></p-columnFilter> -->
                </div>
              </th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-invoiceLine let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="invoiceLine" *ngIf="invoiceLine.SupplierProductCode!=null && invoiceLine.ProductTitle!=''">
              <td hidden pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.ID">
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.ID}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.SupplierProductCode" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.SupplierProductCode}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.ProductTitle" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.Label_ProductName}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.VatCode" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.Label_VATTypeName}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.Quantity" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.Quantity}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.UnitPrice">
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.UnitPrice | number:'1.4-4'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.DiscountedUnitPrice" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.DiscountedUnitPrice | number:'1.4-4'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.NetPrice" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.NetPrice | number:'1.4-4'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="invoiceLine.VatPrice" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{invoiceLine.VatPrice | number:'1.4-4'}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align:center">
                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(invoiceLine)" class="p-button-rounded p-button-text" [disabled]="invoiceLineItems.IsInvoiceSubmitted==true"></button>
                <p-toast></p-toast>
                <p-confirmPopup></p-confirmPopup>
                <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(invoiceLine, ri, $event)" class="p-button-rounded p-button-text p-button-danger" [disabled]="invoiceLineItems.IsInvoiceSubmitted==true"></button>
              </td>
            </tr>
          </ng-template>
        </p-table><br />
        <p-accordion>
          <p-accordionTab header="Non Product Line Items (Ex: Carrier/Postage)" [toggleable]="true" [selected]="false">
            <div class="row">
              <div class="col-lg-3">
                <div class="row align-items-end">
                  <div class="col-lg-10">
                    <label class="p-text-bold" for="OtherChargeType">Other Charge Type</label><br />
                    <p-dropdown inputId="dropdownOtherChargeType" styleClass="primeng-dropdown" [options]="OtherChargeType" placeholder="Select Other Charge Type"
                                (onChange)="onSelectOtherChargeType($event)" [(ngModel)]="SelectedOtherChargeType"
                                [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                                optionLabel="Name" optionValue="ID" formControlName="OtherChargeTypeID" required>
                    </p-dropdown>
                    <!--<span *ngIf="errorOtherChargesTypes" class="error">{{ errorOtherChargesTypes }}</span>-->
                  </div>
                  <div class="col-lg-2">
                    <p-button styleClass="float-right" (click)="showAddOtherChargeType()" icon="fa fa-plus" pTooltip="Add New Other Charges"></p-button>
                  </div>
                </div>
                <!-- <label for="OtherChargeType">Other Charge Type</label><br />
                <p-dropdown inputId="dropdownOtherChargeType" styleClass="primeng-dropdown" [options]="OtherChargeType" placeholder="Select Other Charge Type"
                            (onChange)="onSelectOtherChargeType($event)" [(ngModel)]="SelectedOtherChargeType"
                            [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                            optionLabel="Name" optionValue="ID" formControlName="OtherChargeTypeID">
                </p-dropdown><br /><br /> -->

              </div>
              <!-- <div class="col-lg-1" style="margin-top: 30px;">
                <label for="AddNewOtherChargeType" style="visibility: hidden;">Add</label> -->
              <p-dialog id="AddNewOtherChargeType" header="Add New Other Charge Type" [(visible)]="AddOtherChargeType" [modal]="true" [responsive]="true" [width]="500" [minWidth]="200" [minY]="70"
                        [maximizable]="true" [baseZIndex]="10000">
                <div class="container-fluid">
                  <div class="row" [formGroup]="OtherChargeNewForm">
                    <div class="col-lg-6">
                      <label class="p-text-bold" for="OtherChargeNewName"> Name</label><br />
                      <input id="OtherChargeNewName" type="text" name="OtherChargeNewName" [(ngModel)]="otherChargeNewName" pInputText autocomplete="off" required formControlName="otherChargeNewName" />
                      <p *ngIf="addOtherChargeErrorMsg" class="error">{{addOtherChargeErrorMsg}}</p>
                    </div>
                    <div class="col-lg-6">
                      <label class="p-text-bold" for="OtherChargeNewDescription">Description</label><br />
                      <input id="OtherChargeNewDescription" type="text" name="Description" pInputText [(ngModel)]="otherChargeNewDescription" autocomplete="off" formControlName="otherChargeNewDescription" />
                    </div>
                  </div>
                </div>
                <p-footer>
                  <button type="button" pButton icon="pi pi-check" (click)="AddOtherChargeTypeValue()" label="Add"></button>
                  <button type="button" pButton icon="pi pi-close" (click)="CancelOtherChargeTypeValue()" label="Cancel" class="ui-button-secondary"></button>
                </p-footer>
                <label *ngIf="addOtherChargeMsgSuccess" class="success p-text-bold">{{addOtherChargeMsgSuccess}}</label>
                <label *ngIf="addOtherChargeMsgError" class="error p-text-bold">{{addOtherChargeMsgError}}</label>
              </p-dialog>
              <!-- <a name="add"><i class="fa fa-plus"></i></a> -->
              <!-- <p-button (click)="showAddOtherChargeType()" icon="fa fa-plus"></p-button> -->
              <!-- </div> -->
              <div class="col-lg-3">
                <label class="p-text-bold" for="vatTypes">VAT Type</label><br />
                <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="vatTypes" [virtualScroll]="true" [filter]="true"
                            (onChange)="onSelectOtherChargeVATChange($event)" [(ngModel)]="SelectedOtherChargeVATType"
                            optionLabel="VATCode" optionValue="ID"  [autoDisplayFirst]="false" placeholder="Select VAT Type"
                            formControlName="OtherChargeVATTypeID" required>
                </p-dropdown>
                <span *ngIf="errorOtherChargesVATTypes" class="error">{{ errorOtherChargesVATTypes }}</span>
              </div>
              <div class="col-lg-3">
                <label class="p-text-bold" for="Amount">Amount</label><br />
                <input id="input" type="number" pInputText formControlName="Amount" [(ngModel)]="otherChargesTypes.Amount" (keyup)="onEnterAmount(otherChargesTypes.Amount)" class="form-control" required />
                <span *ngIf="errorOtherChargesAmount" class="error">{{ errorOtherChargesAmount }}</span>
              </div>
              <div class="col-lg-3">
                <label class="p-text-bold" for="Comments">Comments</label><br />
                <textarea rows="1" pInputTextarea placeholder="Comments" formControlName="Comments" [(ngModel)]="otherChargesTypes.Description" (keyup)="onEnterDescription(otherChargesTypes.Description)" class="form-control"></textarea>
                <!--<span *ngIf="errorOtherChargesComment" class="error">{{ errorOtherChargesComment }}</span>-->
              </div>
            </div><br />
            <div class="text-right">
              <button type="button" pButton (click)="AddOtherChargeTypes()" label="Add" [disabled]="DisabledAddOtherCharges || invoiceLineItems.IsInvoiceSubmitted==true"></button>
            </div><br />
            <div class="text-left p-text-bold" *ngIf="errorMessageNon">
              <label [(ngModel)]="errorMessageNon" class="error">{{errorMessageNon}}</label>
            </div>
            <div class="text-left p-text-bold" *ngIf="successMessageNon">
              <label [(ngModel)]="successMessageNon" class="success">{{successMessageNon}}</label>
            </div>
            <div class="text-left p-text-bold" *ngIf="addOtherChargeMsg">
              <label [(ngModel)]="addOtherChargeMsg" class="success">{{addOtherChargeMsg}}</label>
            </div>
            <p-table #dt2 [value]="nonProd" dataKey="ID" [loading]="load" styleClass="p-datatable-nonProd">
              <ng-template pTemplate="header">
                <tr>
                  <th hidden>ID</th>
                  <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Other Charge Type
                      <!-- <p-columnFilter type="text" field="OtherChargeType" display="menu"></p-columnFilter> -->
                    </div>
                  </th>
                  <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                      VAT Type
                      <!-- <p-columnFilter type="text" field="vatTypes" display="menu"></p-columnFilter> -->
                    </div>
                  </th>
                  <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Amount
                      <!-- <p-columnFilter type="text" field="Amount" display="menu"></p-columnFilter> -->
                    </div>
                  </th>

                  <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Net Price
                      <!-- <p-columnFilter type="text" field="Net Price" display="menu"></p-columnFilter> -->
                    </div>
                  </th>
                  <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                      VAT Price
                      <!-- <p-columnFilter type="text" field="VATPrice" display="menu"></p-columnFilter> -->
                    </div>
                  </th>
                  <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Comments
                      <!-- <p-columnFilter type="text" field="Comments" display="menu"></p-columnFilter> -->
                    </div>
                  </th>
                  <th style="width: 8rem; text-align: center">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      Action
                      <!-- <p-columnFilter type="text" field="Action" display="menu"></p-columnFilter> -->
                    </div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-NonProductLineItems let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="NonProductLineItems">
                  <td hidden pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="NonProductLineItems.ID">
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{NonProductLineItems.ID}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="NonProductLineItems.Label_OtherChargesType" required>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{NonProductLineItems.Label_OtherChargesType}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="NonProductLineItems.Label_VATTypeName" required>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{NonProductLineItems.Label_VATTypeName}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="NonProductLineItems.Amount" required>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{NonProductLineItems.Amount}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="NonProductLineItems.NetPrice" required>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{NonProductLineItems.NetPrice}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="NonProductLineItems.VatPrice">
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{NonProductLineItems.VatPrice}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="NonProductLineItems.Description" required>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{NonProductLineItems.Description}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td style="text-align:center">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEdit(NonProductLineItems)" class="p-button-rounded p-button-text" [disabled]="invoiceLineItems.IsInvoiceSubmitted==true"></button>
                    <p-toast></p-toast>
                    <p-confirmPopup></p-confirmPopup>
                    <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowDelete(NonProductLineItems, ri, $event)" class="p-button-rounded p-button-text p-button-danger" [disabled]="invoiceLineItems.IsInvoiceSubmitted==true"></button>
                  </td>
                </tr>
              </ng-template>
            </p-table><br />
          </p-accordionTab>
        </p-accordion><br />
        <div class="row">
          <div class="col-sm-7 text-right">
            <label class="p-text-bold" for="NetTotal">Net Total</label>
          </div>
          <div class="col-lg-2" *ngIf="invItem.length>0">
            <span>{{invoiceLineItems.PerDiscountNetAmount | number:'1.4-4'}}</span>
            <!-- <input id="input" type="number" pInputText [disabled]="disabled" [(ngModel)]="invoiceLineItems.PerDiscountNetAmount" class="form-control" formControlName="NetTotal"/>  -->
          </div><br /><br />
          <div class="col-sm-7 text-right">
            <label class="p-text-bold" for="TotalVAT">Total VAT</label>
          </div>
          <div class="col-lg-2" *ngIf="invItem.length>0">
            <span>{{invoiceLineItems.VATTotal | number:'1.4-4'}}</span>
            <!-- <input id="input" type="number" pInputText [disabled]="disabled" [(ngModel)]="invoiceLineItems.VATTotal" class="form-control" formControlName="TotalVAT" />  -->
          </div><br /><br />
          <div class="col-sm-7 text-right">
            <label class="p-text-bold" for="GrandTotal">Grand Total</label>
          </div>
          <div class="col-lg-2" *ngIf="invItem.length>0">
            <span>{{invoiceLineItems.TotalAmount | number:'1.4-4'}}</span>
            <!-- <input id="input" type="number" pInputText [disabled]="disabled" [(ngModel)]="invoiceLineItems.TotalAmount" class="form-control" formControlName="GrandTotal" />  -->
          </div><br /><br />
          <div class="col-sm-7 text-right">
            <label class="p-text-bold" for="AdjustTotal">Adjust Total</label>
          </div>
          <div class="col-lg-2">
            <!-- <input id="AdjustTotal" type="text" step="0.0001"  pInputText [(ngModel)]="invoiceLineItems.AdjustTotal" class="form-control" formControlName="AdjustTotal" (keyup)="onChangeAdjustTotal(invoiceLineItems.AdjustTotal)" /> -->
            <p-inputNumber    [(ngModel)]="invoiceLineItems.AdjustTotal" inputId="AdjustTotal" mode="decimal" [minFractionDigits]="4" [maxFractionDigits]="4"  formControlName="AdjustTotal" (keyup)="onChangeAdjustTotal($event)"> </p-inputNumber>
          </div><br />
          <div class="col-lg-3 text-center">
            <button type="button" pButton (click)="SubmitInvoice('top')" label="Submit Invoice" [disabled]="invoiceLineItems.IsInvoiceSubmitted==true"></button>
            <p-confirmDialog [style]="{width: '50vw'}" key="positionDialog" [position]="position" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
            <p-toast></p-toast>

            <label [(ngModel)]="successMessage" class="success p-text-bold">{{successMessage}}</label>
            <label [(ngModel)]="errorMessage" class="error p-text-bold">{{errorMessage}}</label>
          </div>
        </div>
      </form>
      <!--</p-fieldset>-->
    </div>

  </div>
</div>
<app-supplier [isShownSupplierForm]="isShownSupplier" [header]="false" [grid]="false" (isSupVal)="exitSup($event)"></app-supplier>
<app-product class="new-prod" [isShownProductForm]="isShownProduct" [header]="false" [suppliers]="suppliers" [selectedSupID]="SelectedSupplierID" [desk]="false" [phone]="false" (isProdVal)="exitProd($event)"></app-product>
<!--</div>-->
