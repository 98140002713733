import { Component, ElementRef, OnChanges, OnInit, AfterViewInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ROUTES } from "../sidebar/sidebar.component";
import { Subscription } from 'rxjs';


import {
  Location
} from "@angular/common";
import { AlertService } from '../../Modules/pricing/services/alert.service';
import { ReportService } from '../../Modules/pricing/services/report.service';
import { PendingAlerts } from '../../Modules/pricing/models/pending-alerts.model';
import { Table } from 'primeng/table';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  public reportData: any[];
  public status: any[];
  public formula: string = "Formula 1";
  public focus;
  public listTitles: any[];
  public location: Location;
  public userName;
  sidenavOpen: boolean = true;
  loader: boolean = false;
  PendingAlertsCount: number = 0;
  ExceptionDetailsCount: number = 0;
  PendingAlerts: any[] = [];
  markedAlerts: number[] = [];
  showReviewedAlerts: boolean = false;
  showPendingAlert: boolean = true;
  ExceptionAlerts: any[] = [];
  showExceptionAlert: boolean = true;
  pos: string;
  notify: boolean = false;
  loading: boolean = false;
  inputSearchKey: string;
  counts: Subscription;

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [],
    showTitle: true,
    title: 'asfasf',
    useBom: false,
    removeNewLines: true,
    keys: ['approved', 'age', 'name']
  };

  constructor(
    location: Location,
    private router: Router,
    private alertService: AlertService,
    private reportService: ReportService,
  ) {
    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator

      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }



  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.userName = localStorage.getItem("userName");
    // this.getCountPendingAlerts();
    // this.getCountExceptionDetails();
    //this.PendingAlertsCount = this.alertService.getPendingAlertCount();
    //this.PendingAlertsCount = this.alertService.PendingAlertsCount;
    //this.counts = interval(10000).subscribe(val => {
    this.getAllPendingAlerts(this.showReviewedAlerts);
    this.getAllDeliveryExceptionAlerts();
    //});

    //this.PendingAlertsCount = this.PendingAlerts.length;
  }



  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  Logout() {
    localStorage.clear();
    this.router.navigate(["/Login"]);

  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  notification(position: string) {
    this.pos = position;
    // this.loader = true;
    this.markedAlerts = [];
    this.getAllPendingAlerts(this.showReviewedAlerts);
    this.getAllDeliveryExceptionAlerts();
    this.notify = true;
  }

  ShowAllExceptionAlerts() {
    this.notify = false;
    this.router.navigate(['/AdminDashboard/Pricing/ShowAllExceptionAlerts']);
  }

  showAllPendingAlerts(showReviewedAlerts) {
    this.notify = false;
    // let urlTree = this.router.parseUrl('/AdminDashboard/Pricing/ShowAllPendingAlerts');
    // urlTree.queryParams['showReviewedAlerts'] = showReviewedAlerts;
    // this.router.navigateByUrl(urlTree);
    this.router.navigate(['/AdminDashboard/Pricing/ShowAllPendingAlerts/'], {
      queryParams: { showReviewedAlerts: showReviewedAlerts }
    });
    //window.open('/AdminDashboard/Pricing/ShowAllPendingAlerts?showReviewedAlerts='+showReviewedAlerts, '_self');
  }

  getAllDeliveryExceptionAlerts() {
    this.loader = true;
    this.alertService.getAllLatestDeliveryExceptionAlerts()
      .subscribe(
        data => {
          this.ExceptionAlerts = data;
          this.ExceptionDetailsCount = this.ExceptionAlerts.length;
          this.loader = false;
        },
        error => {
          this.loader = false;
        });
  }

  resolveException(exceptionAlerts) {
    this.notify = false;
    this.router.navigate(['/AdminDashboard/Pricing/DeliveryDetails/'], {
      queryParams: { invoiceNo: exceptionAlerts.InvoiceNo, supplierId: exceptionAlerts.SupplierID, isExceptionOrReceive: false, DeliverableNumber: exceptionAlerts.DeliverableNumber }
    });
    //window.location.href = "/AdminDashboard/Pricing/DeliveryDetails?invoiceNo=" +exceptionAlerts.InvoiceNo + "&supplierId=" +exceptionAlerts.SupplierID + "&isExceptionOrReceive="+false +"&DeliverableNumber="+ exceptionAlerts.DeliverableNumber;
  }

  getAllPendingAlerts(showReviewedAlerts) {
    this.loader = true;
    this.alertService.getAllLatestPendingAlerts(showReviewedAlerts)
      .subscribe(
        data => {
          this.loader = false;
          let Alerts: any[] = [];
          let PendingAlert = data;
          for (let i = 0; i < PendingAlert.length; i++) {
            let pending = PendingAlert[i];
            if (pending['OldUnitPrice'] != '') {
              Alerts.push(PendingAlert[i]);
            }
            else {
              this.PendingAlerts = Alerts;
            }
          }
          this.PendingAlerts = Alerts;
          this.PendingAlertsCount = Alerts.length;
        },
        error => {
          this.loader = false;
        });
  }

  getMarkedAlerts(alert: PendingAlerts) {
    if (alert.IsReviewed) {
      this.markedAlerts.push(alert.AlertID);
    }
    else {
      this.markedAlerts.splice(this.markedAlerts.indexOf(alert.AlertID), 1);
    }
  }

  markAlertsToReviewed() {
    this.loading = true;
    for (let i = 0; i < this.markedAlerts.length; i++) {
      this.alertService.markAlertsToReviewed(this.markedAlerts[i])
        .subscribe(
          data => {
            this.loading = false;
            this.getAllPendingAlerts(false);
          },
          error => {
            this.loading = false;
            return;
          });
    }
  }

  getCountPendingAlerts() {
    this.alertService.getCountLatestPendingAlerts()
      .subscribe(
        data => {
          this.PendingAlertsCount = data[0].PendingAlertCount;
        },
        error => {
          return;
        })
  }

  getCountExceptionDetails() {
    this.alertService.getLatestCountExceptionDetails()
      .subscribe(
        data => {
          this.ExceptionDetailsCount = data[0].ExceptionDeliveryCount;
        },
        error => {
          return;
        }

      )
  }

  report() {
    this.reportService.getInvoicePriceReport().subscribe(
      data => {
        this.downloadFile(data, "ASIN Cost Price Report")
      }
    )
  }

  //downloadFile(data, filename) {
  //  let csvData = this.ConvertToCSV(data, ['ProductID', 'ProductVariationID', 'ID', 'ASIN', 'SKU', 'Name', 'UnitPrice']);
  //  //console.log(csvData)
  //  let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  //  let dwldLink = document.createElement("a");
  //  let url = URL.createObjectURL(blob);
  //  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  //  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
  //    dwldLink.setAttribute("target", "_blank");
  //  }
  //  dwldLink.setAttribute("href", url);
  //  dwldLink.setAttribute("download", filename + ".csv");
  //  dwldLink.style.visibility = "hidden";
  //  document.body.appendChild(dwldLink);
  //  dwldLink.click();
  //  document.body.removeChild(dwldLink);
  //}

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = ' ProductID, Product VariationID, ListingID, ASIN, SKU, Name, UnitPrice ';

    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        if (head == 'ProductVariationID' && array[i][head] == null) {
          array[i][head] = '';
        }
        line += (array[i][head]) + ',';
        //console.log(array[i][head]);
      }
      str += line + '\r\n';
    }
    return str;
  }
  clearSearch(table: Table) {
    table.clear();
    this.inputSearchKey = "";
  }

  downloadFile(data, filename) {



    this.status = ["approved", "rejected", "pending"];
    var data = data;

    var options = {
      title: '',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      headers: ['ProductID', 'ProductVariationID', 'ID', 'ASIN', 'SKU', 'Name', 'UnitPrice']
    };

    const fileInfo = new ngxCsv(data, filename, options);
  }
}
