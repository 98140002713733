<div class=" header bg-danger pb-6">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">
          <h6 class=" h2 text-white d-inline-block mb-0">Product</h6>

          <nav aria-label="breadcrumb"
               class=" d-none d-md-inline-block ml-md-4">
            <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>

              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> Product </a>
              </li>

              <li aria-current="page" class=" breadcrumb-item active">
                Product Dashboard
              </li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">
          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)"> New </a>

          <a class=" btn btn-sm btn-neutral" href="javascript:void(0)">
            Filters
          </a>
        </div>
      </div>

      <div class=" row">
        <div class=" col-xl-3 col-md-6">
          <div class=" card card-stats">
            <div class=" card-body">
              <div class=" row">
                <div class=" col">
                  <h5 class=" card-title text-uppercase text-muted mb-0">
                    Total traffic
                  </h5>

                  <span class=" h2 font-weight-bold mb-0"> 350,897 </span>
                </div>

                <div class=" col-auto">
                  <div class=" icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                    <i class=" ni ni-active-40"> </i>
                  </div>
                </div>
              </div>

              <p class=" mt-3 mb-0 text-sm">
                <span class=" text-success mr-2">
                  <i class=" fa fa-arrow-up"> </i> 3.48%
                </span>

                <span class=" text-nowrap"> Since last month </span>
              </p>
            </div>
          </div>
        </div>

        <div class=" col-xl-3 col-md-6">
          <div class=" card card-stats">
            <div class=" card-body">
              <div class=" row">
                <div class=" col">
                  <h5 class=" card-title text-uppercase text-muted mb-0">
                    New users
                  </h5>

                  <span class=" h2 font-weight-bold mb-0"> 2,356 </span>
                </div>

                <div class=" col-auto">
                  <div class=" icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                    <i class=" ni ni-chart-pie-35"> </i>
                  </div>
                </div>
              </div>

              <p class=" mt-3 mb-0 text-sm">
                <span class=" text-success mr-2">
                  <i class=" fa fa-arrow-up"> </i> 3.48%
                </span>

                <span class=" text-nowrap"> Since last month </span>
              </p>
            </div>
          </div>
        </div>

        <div class=" col-xl-3 col-md-6">
          <div class=" card card-stats">
            <div class=" card-body">
              <div class=" row">
                <div class=" col">
                  <h5 class=" card-title text-uppercase text-muted mb-0">
                    Sales
                  </h5>

                  <span class=" h2 font-weight-bold mb-0"> 924 </span>
                </div>

                <div class=" col-auto">
                  <div class=" icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                    <i class=" ni ni-money-coins"> </i>
                  </div>
                </div>
              </div>

              <p class=" mt-3 mb-0 text-sm">
                <span class=" text-success mr-2">
                  <i class=" fa fa-arrow-up"> </i> 3.48%
                </span>

                <span class=" text-nowrap"> Since last month </span>
              </p>
            </div>
          </div>
        </div>

        <div class=" col-xl-3 col-md-6">
          <div class=" card card-stats">
            <div class=" card-body">
              <div class=" row">
                <div class=" col">
                  <h5 class=" card-title text-uppercase text-muted mb-0">
                    Performance
                  </h5>

                  <span class=" h2 font-weight-bold mb-0"> 49,65% </span>
                </div>

                <div class=" col-auto">
                  <div class=" icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                    <i class=" ni ni-chart-bar-32"> </i>
                  </div>
                </div>
              </div>

              <p class=" mt-3 mb-0 text-sm">
                <span class=" text-success mr-2">
                  <i class=" fa fa-arrow-up"> </i> 3.48%
                </span>

                <span class=" text-nowrap"> Since last month </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class=" container-fluid mt--6">
  <div class=" row">
    <div class=" col-xl-8">
      <div class=" card bg-default">
        <div class=" card-header bg-transparent">
          <div class=" row align-items-center">
            <div class=" col">
              <h6 class=" text-light text-uppercase ls-1 mb-1">Overview</h6>

              <h5 class=" h3 text-white mb-0">Sales value</h5>
            </div>

            <div class="col">
              <ul class="nav nav-pills justify-content-end">
                <li
                  class="nav-item mr-2 mr-md-0"
                  data-toggle="chart"
                  data-target="#chart-sales"
                  (click)="data = datasets[0]; updateOptions()"
                >
                  <a
                    href="javascript:void(0)"
                    class="nav-link py-2 px-3"
                    [ngClass]="{ active: clicked === true }"
                    data-toggle="tab"
                    (click)="clicked = true; clicked1 = false"
                  >
                    <span class="d-none d-md-block">Month</span>
                    <span class="d-md-none">M</span>
                  </a>
                </li>
                <li
                  class="nav-item"
                  data-toggle="chart"
                  data-target="#chart-sales"
                  (click)="data = datasets[1]; updateOptions()"
                >
                  <a
                    href="javascript:void(0)"
                    class="nav-link py-2 px-3"
                    [ngClass]="{ active: clicked1 === true }"
                    data-toggle="tab"
                    (click)="clicked = false; clicked1 = true"
                  >
                    <span class="d-none d-md-block">Week</span>
                    <span class="d-md-none">W</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class=" card-body">
          <div class=" chart">
            <canvas class=" chart-canvas" id="chart-sales-dark"> </canvas>
          </div>
        </div>
      </div>
    </div>

    <div class=" col-xl-4">
      <div class=" card">
        <div class=" card-header bg-transparent">
          <div class=" row align-items-center">
            <div class=" col">
              <h6 class=" text-uppercase text-muted ls-1 mb-1">Performance</h6>

              <h5 class=" h3 mb-0">Total orders</h5>
            </div>
          </div>
        </div>

        <div class=" card-body">
          <div class=" chart">
            <canvas class=" chart-canvas" id="chart-bars"> </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class=" row">
    <div class=" col-xl-4">
      <div class=" card">
        <div class=" card-header"><h5 class=" h3 mb-0">Team members</h5></div>

        <div class=" card-body">
          <ul class=" list-group list-group-flush list my--3">
            <li class=" list-group-item px-0">
              <div class=" row align-items-center">
                <div class=" col-auto">
                  <a class=" avatar rounded-circle" href="javascript:void(0)">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-1.jpg"
                    />
                  </a>
                </div>

                <div class=" col ml--2">
                  <h4 class=" mb-0">
                    <a href="javascript:void(0)"> John Michael </a>
                  </h4>

                  <span class=" text-success"> ● </span>

                  <small> Online </small>
                </div>

                <div class=" col-auto">
                  <button class=" btn btn-sm btn-primary" type="button">
                    Add
                  </button>
                </div>
              </div>
            </li>

            <li class=" list-group-item px-0">
              <div class=" row align-items-center">
                <div class=" col-auto">
                  <a class=" avatar rounded-circle" href="javascript:void(0)">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-2.jpg"
                    />
                  </a>
                </div>

                <div class=" col ml--2">
                  <h4 class=" mb-0">
                    <a href="javascript:void(0)"> Alex Smith </a>
                  </h4>

                  <span class=" text-warning"> ● </span>

                  <small> In a meeting </small>
                </div>

                <div class=" col-auto">
                  <button class=" btn btn-sm btn-primary" type="button">
                    Add
                  </button>
                </div>
              </div>
            </li>

            <li class=" list-group-item px-0">
              <div class=" row align-items-center">
                <div class=" col-auto">
                  <a class=" avatar rounded-circle" href="javascript:void(0)">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-3.jpg"
                    />
                  </a>
                </div>

                <div class=" col ml--2">
                  <h4 class=" mb-0">
                    <a href="javascript:void(0)"> Samantha Ivy </a>
                  </h4>

                  <span class=" text-danger"> ● </span>

                  <small> Offline </small>
                </div>

                <div class=" col-auto">
                  <button class=" btn btn-sm btn-primary" type="button">
                    Add
                  </button>
                </div>
              </div>
            </li>

            <li class=" list-group-item px-0">
              <div class=" row align-items-center">
                <div class=" col-auto">
                  <a class=" avatar rounded-circle" href="javascript:void(0)">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-4.jpg"
                    />
                  </a>
                </div>

                <div class=" col ml--2">
                  <h4 class=" mb-0">
                    <a href="javascript:void(0)"> John Michael </a>
                  </h4>

                  <span class=" text-success"> ● </span>

                  <small> Online </small>
                </div>

                <div class=" col-auto">
                  <button class=" btn btn-sm btn-primary" type="button">
                    Add
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class=" col-xl-4">
      <div class=" card">
        <div class=" card-header"><h5 class=" h3 mb-0">To do list</h5></div>

        <div class=" card-body p-0">
          <ul class=" list-group list-group-flush" data-toggle="checklist">
            <li
              class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4"
            >
              <div class=" checklist-item checklist-item-success">
                <div class=" checklist-info">
                  <h5 class=" checklist-title mb-0">Call with Dave</h5>

                  <small> 10:30 AM </small>
                </div>

                <div>
                  <div
                    class=" custom-control custom-checkbox custom-checkbox-success"
                  >
                    <input
                      checked="checked"
                      class=" custom-control-input"
                      id="chk-todo-task-1"
                      type="checkbox"
                    />

                    <label class=" custom-control-label" for="chk-todo-task-1">
                    </label>
                  </div>
                </div>
              </div>
            </li>

            <li
              class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4"
            >
              <div class=" checklist-item checklist-item-warning">
                <div class=" checklist-info">
                  <h5 class=" checklist-title mb-0">Lunch meeting</h5>

                  <small> 10:30 AM </small>
                </div>

                <div>
                  <div
                    class=" custom-control custom-checkbox custom-checkbox-warning"
                  >
                    <input
                      class=" custom-control-input"
                      id="chk-todo-task-2"
                      type="checkbox"
                    />

                    <label class=" custom-control-label" for="chk-todo-task-2">
                    </label>
                  </div>
                </div>
              </div>
            </li>

            <li
              class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4"
            >
              <div class=" checklist-item checklist-item-info">
                <div class=" checklist-info">
                  <h5 class=" checklist-title mb-0">Argon Dashboard Launch</h5>

                  <small> 10:30 AM </small>
                </div>

                <div>
                  <div
                    class=" custom-control custom-checkbox custom-checkbox-info"
                  >
                    <input
                      class=" custom-control-input"
                      id="chk-todo-task-3"
                      type="checkbox"
                    />

                    <label class=" custom-control-label" for="chk-todo-task-3">
                    </label>
                  </div>
                </div>
              </div>
            </li>

            <li
              class=" checklist-entry list-group-item flex-column align-items-start py-4 px-4"
            >
              <div class=" checklist-item checklist-item-danger">
                <div class=" checklist-info">
                  <h5 class=" checklist-title mb-0">Winter Hackaton</h5>

                  <small> 10:30 AM </small>
                </div>

                <div>
                  <div
                    class=" custom-control custom-checkbox custom-checkbox-danger"
                  >
                    <input
                      checked="checked"
                      class=" custom-control-input"
                      id="chk-todo-task-4"
                      type="checkbox"
                    />

                    <label class=" custom-control-label" for="chk-todo-task-4">
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class=" col-xl-4">
      <div class=" card">
        <div class=" card-header"><h5 class=" h3 mb-0">Progress track</h5></div>

        <div class=" card-body">
          <ul class=" list-group list-group-flush list my--3">
            <li class=" list-group-item px-0">
              <div class=" row align-items-center">
                <div class=" col-auto">
                  <a class=" avatar rounded-circle" href="javascript:void(0)">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/bootstrap.jpg"
                    />
                  </a>
                </div>

                <div class=" col">
                  <h5>Argon Design System</h5>

                  <div class="progress-xs mb-0">
                    <progressbar type="warning" [value]="60"> </progressbar>
                  </div>
                </div>
              </div>
            </li>

            <li class=" list-group-item px-0">
              <div class=" row align-items-center">
                <div class=" col-auto">
                  <a class=" avatar rounded-circle" href="javascript:void(0)">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/angular.jpg"
                    />
                  </a>
                </div>

                <div class=" col">
                  <h5>Angular Now UI Kit PRO</h5>

                  <div class="progress-xs mb-0">
                    <progressbar type="success" [value]="100"> </progressbar>
                  </div>
                </div>
              </div>
            </li>

            <li class=" list-group-item px-0">
              <div class=" row align-items-center">
                <div class=" col-auto">
                  <a class=" avatar rounded-circle" href="javascript:void(0)">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/sketch.jpg"
                    />
                  </a>
                </div>

                <div class=" col">
                  <h5>Black Dashboard</h5>

                  <div class="progress-xs mb-0">
                    <progressbar type="danger" [value]="72"> </progressbar>
                  </div>
                </div>
              </div>
            </li>

            <li class=" list-group-item px-0">
              <div class=" row align-items-center">
                <div class=" col-auto">
                  <a class=" avatar rounded-circle" href="javascript:void(0)">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/react.jpg"
                    />
                  </a>
                </div>

                <div class=" col">
                  <h5>React Material Dashboard</h5>

                  <div class="progress-xs mb-0">
                    <progressbar type="info" [value]="90"> </progressbar>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class=" row">
    <div class=" col-xl-5">
      <div class=" card">
        <div class=" card-header"><h5 class=" h3 mb-0">Activity feed</h5></div>

        <div class=" card-header d-flex align-items-center">
          <div class=" d-flex align-items-center">
            <a href="javascript:void(0)">
              <img class=" avatar" src="assets/img/theme/team-1.jpg" />
            </a>

            <div class=" mx-3">
              <a
                class=" text-dark font-weight-600 text-sm"
                href="javascript:void(0)"
              >
                John Snow
              </a>

              <small class=" d-block text-muted"> 3 days ago </small>
            </div>
          </div>

          <div class=" text-right ml-auto">
            <button class=" btn btn-sm btn-primary btn-icon" type="button">
              <span class=" btn-inner--icon">
                <i class=" ni ni-fat-add"> </i>
              </span>

              <span class=" btn-inner--text"> Follow </span>
            </button>
          </div>
        </div>

        <div class=" card-body">
          <p class=" mb-4">
            Personal profiles are the perfect way for you to grab their
            attention and persuade recruiters to continue reading your CV
            because you’re telling them from the off exactly why they should
            hire you.
          </p>

          <img
            alt="Image placeholder"
            class=" img-fluid rounded"
            src="assets/img/theme/img-1-1000x600.jpg"
          />

          <div class=" row align-items-center my-3 pb-3 border-bottom">
            <div class=" col-sm-6">
              <div class=" icon-actions">
                <a class=" like active" href="javascript:void(0)">
                  <i class=" ni ni-like-2"> </i>

                  <span class=" text-muted"> 150 </span>
                </a>

                <a href="javascript:void(0)">
                  <i class=" ni ni-chat-round"> </i>

                  <span class=" text-muted"> 36 </span>
                </a>

                <a href="javascript:void(0)">
                  <i class=" ni ni-curved-next"> </i>

                  <span class=" text-muted"> 12 </span>
                </a>
              </div>
            </div>

            <div class=" col-sm-6 d-none d-sm-block">
              <div class=" d-flex align-items-center justify-content-sm-end">
                <div class=" avatar-group">
                  <a
                    class=" avatar avatar-xs rounded-circle"
                    tooltip="Jessica Rowland"
                    placement="top"
                    href="javascript:void(0)"
                  >
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-1.jpg"
                    />
                  </a>

                  <a
                    class=" avatar avatar-xs rounded-circle"
                    tooltip="Audrey Love"
                    placement="top"
                    href="javascript:void(0)"
                  >
                    <img
                      alt="Image placeholder"
                      class=" rounded-circle"
                      src="assets/img/theme/team-2.jpg"
                    />
                  </a>

                  <a
                    class=" avatar avatar-xs rounded-circle"
                    tooltip="Michael Lewis"
                    placement="top"
                    href="javascript:void(0)"
                  >
                    <img
                      alt="Image placeholder"
                      class=" rounded-circle"
                      src="assets/img/theme/team-3.jpg"
                    />
                  </a>
                </div>

                <small class=" pl-2 font-weight-bold"> and 30+ more </small>
              </div>
            </div>
          </div>

          <div class=" mb-1">
            <div class=" media media-comment">
              <img
                alt="Image placeholder"
                class=" avatar avatar-lg media-comment-avatar rounded-circle"
                src="assets/img/theme/team-1.jpg"
              />

              <div class=" media-body">
                <div class=" media-comment-text">
                  <h6 class=" h5 mt-0">Michael Lewis</h6>

                  <p class=" text-sm lh-160">
                    Cras sit amet nibh libero nulla vel metus scelerisque ante
                    sollicitudin. Cras purus odio vestibulum in vulputate
                    viverra turpis.
                  </p>

                  <div class=" icon-actions">
                    <a class=" like active" href="javascript:void(0)">
                      <i class=" ni ni-like-2"> </i>

                      <span class=" text-muted"> 3 likes </span>
                    </a>

                    <a href="javascript:void(0)">
                      <i class=" ni ni-curved-next"> </i>

                      <span class=" text-muted"> 2 shares </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class=" media media-comment">
              <img
                alt="Image placeholder"
                class=" avatar avatar-lg media-comment-avatar rounded-circle"
                src="assets/img/theme/team-2.jpg"
              />

              <div class=" media-body">
                <div class=" media-comment-text">
                  <h6 class=" h5 mt-0">Jessica Stones</h6>

                  <p class=" text-sm lh-160">
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
                    scelerisque ante sollicitudin. Cras purus odio, vestibulum
                    in vulputate at, tempus viverra turpis.
                  </p>

                  <div class=" icon-actions">
                    <a class=" like active" href="javascript:void(0)">
                      <i class=" ni ni-like-2"> </i>

                      <span class=" text-muted"> 10 likes </span>
                    </a>

                    <a href="javascript:void(0)">
                      <i class=" ni ni-curved-next"> </i>

                      <span class=" text-muted"> 1 share </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <div class=" media align-items-center">
              <img
                alt="Image placeholder"
                class=" avatar avatar-lg rounded-circle mr-4"
                src="assets/img/theme/team-3.jpg"
              />

              <div class=" media-body">
                <form>
                  <textarea
                    class=" form-control"
                    placeholder="Write your comment"
                    rows="1"
                  ></textarea>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=" col-xl-7">
      <div class=" row">
        <div class=" col">
          <div class=" card">
            <div class=" card-header border-0">
              <h3 class=" mb-0">Light table</h3>
            </div>

            <div class=" table-responsive">
              <table class=" table align-items-center table-flush">
                <thead class=" thead-light">
                  <tr>
                    <th class=" sort" data-sort="name" scope="col">Project</th>

                    <th class=" sort" data-sort="budget" scope="col">Budget</th>

                    <th class=" sort" data-sort="status" scope="col">Status</th>

                    <th scope="col">Users</th>

                    <th class=" sort" data-sort="completion" scope="col">
                      Completion
                    </th>

                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody class=" list">
                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/bootstrap.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            Argon Design System
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$2500 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-warning"> </i>

                        <span class=" status"> pending </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 60% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="warning" [value]="60">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret">
                        <a
                          id="button-basic"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-basic"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a class="dropdown-item" href="javascript:void(0)">
                            Action
                          </a>

                          <a class="dropdown-item" href="javascript:void(0)">
                            Another action
                          </a>

                          <a class="dropdown-item" href="javascript:void(0)">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/angular.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            Angular Now UI Kit PRO
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$1800 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-success"> </i>

                        <span class=" status"> completed </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 100% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="success" [value]="100">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret">
                        <a
                          id="button-basic"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-basic"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a href="javascript:void(0)" class="dropdown-item">
                            Action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Another action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/sketch.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            Black Dashboard
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$3150 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-danger"> </i>

                        <span class=" status"> delayed </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 72% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="danger" [value]="72">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret">
                        <a
                          id="button-basic"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-basic"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a href="javascript:void(0)" class="dropdown-item">
                            Action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Another action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/react.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            React Material Dashboard
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$4400 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-info"> </i>

                        <span class=" status"> on schedule </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 90% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="info" [value]="90">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret">
                        <a
                          id="button-basic"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-basic"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a href="javascript:void(0)" class="dropdown-item">
                            Action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Another action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/vue.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            Vue Paper UI Kit PRO
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$2200 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-success"> </i>

                        <span class=" status"> completed </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 100% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="success" [value]="100">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret">
                        <a
                          id="button-basic"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-basic"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a href="javascript:void(0)" class="dropdown-item">
                            Action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Another action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/bootstrap.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            Argon Design System
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$2500 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-warning"> </i>

                        <span class=" status"> pending </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 60% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="warning" [value]="60">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret">
                        <a
                          id="button-basic"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-basic"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a href="javascript:void(0)" class="dropdown-item">
                            Action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Another action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/angular.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            Angular Now UI Kit PRO
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$1800 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-success"> </i>

                        <span class=" status"> completed </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 100% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="success" [value]="100">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret">
                        <a
                          id="button-basic"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-basic"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a href="javascript:void(0)" class="dropdown-item">
                            Action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Another action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/sketch.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            Black Dashboard
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$3150 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-danger"> </i>

                        <span class=" status"> delayed </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 72% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="danger" [value]="72">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret" [dropup]="'true'">
                        <a
                          id="button-dropup"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-dropup"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a href="javascript:void(0)" class="dropdown-item">
                            Action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Another action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      <div class=" media align-items-center">
                        <a
                          class=" avatar rounded-circle mr-3"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/angular.jpg"
                          />
                        </a>

                        <div class=" media-body">
                          <span class=" name mb-0 text-sm">
                            Angular Now UI Kit PRO
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class=" budget">$1800 USD</td>

                    <td>
                      <span class=" badge badge-dot mr-4">
                        <i class=" bg-success"> </i>

                        <span class=" status"> completed </span>
                      </span>
                    </td>

                    <td>
                      <div class=" avatar-group">
                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Ryan Thompson"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-1.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Romina Hadid"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-2.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Alexander Smith"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-3.jpg"
                          />
                        </a>

                        <a
                          class=" avatar avatar-sm rounded-circle"
                          tooltip="Jessica Doe"
                          placement="top"
                          href="javascript:void(0)"
                        >
                          <img
                            alt="Image placeholder"
                            src="assets/img/theme/team-4.jpg"
                          />
                        </a>
                      </div>
                    </td>

                    <td>
                      <div class=" d-flex align-items-center">
                        <span class=" completion mr-2"> 100% </span>

                        <div>
                          <div class=" progress">
                            <progressbar type="success" [value]="100">
                            </progressbar>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class=" text-right">
                      <div dropdown class="dropdown no-caret" [dropup]="'true'">
                        <a
                          id="button-dropup"
                          dropdownToggle
                          role="button"
                          class="btn btn-sm btn-icon-only text-light dropdown-toggle"
                          aria-controls="dropdown-dropup"
                        >
                          <i class=" fas fa-ellipsis-v"> </i>
                        </a>

                        <div
                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                          *dropdownMenu
                        >
                          <a href="javascript:void(0)" class="dropdown-item">
                            Action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Another action
                          </a>

                          <a href="javascript:void(0)" class="dropdown-item">
                            Something else here
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class=" card-deck">
        <div class=" card bg-gradient-default">
          <div class=" card-body">
            <div class=" mb-2">
              <sup class=" text-white"> $ </sup>
              <span class=" h2 text-white"> 3,300 </span>

              <div class=" text-light mt-2 text-sm">Your current balance</div>

              <div>
                <span class=" text-success font-weight-600"> + 15% </span>
                <span class=" text-light"> ($250) </span>
              </div>
            </div>

            <button class=" btn btn-sm btn-block btn-neutral">
              Add credit
            </button>
          </div>

          <div class=" card-body">
            <div class=" row">
              <div class=" col">
                <small class=" text-light"> Orders: 60% </small>

                <div class=" progress progress-xs my-2">
                  <progressbar type="success" [value]="60"> </progressbar>
                </div>
              </div>

              <div class=" col">
                <small class=" text-light"> Sales: 40% </small>

                <div class=" progress progress-xs my-2">
                  <progressbar type="warning" [value]="40"> </progressbar>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class=" card bg-gradient-danger">
          <div class=" card-body">
            <div class=" row justify-content-between align-items-center">
              <div class=" col">
                <img
                  alt="Image placeholder"
                  src="assets/img/icons/cards/bitcoin.png"
                />
              </div>

              <div class=" col-auto">
                <span class=" badge badge-lg badge-success"> Active </span>
              </div>
            </div>

            <div class=" my-4">
              <span class=" h6 surtitle text-light"> Username </span>

              <div class=" h1 text-white">@johnsnow</div>
            </div>

            <div class=" row">
              <div class=" col">
                <span class=" h6 surtitle text-light"> Name </span>

                <span class=" d-block h3 text-white"> John Snow </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class=" row">
    <div class=" col-xl-8">
      <div class=" card">
        <div class=" card-header border-0">
          <div class=" row align-items-center">
            <div class=" col"><h3 class=" mb-0">Page visits</h3></div>

            <div class=" col text-right">
              <a class=" btn btn-sm btn-primary" href="javascript:void(0)">
                See all
              </a>
            </div>
          </div>
        </div>

        <div class=" table-responsive">
          <table class=" table align-items-center table-flush">
            <thead class=" thead-light">
              <tr>
                <th scope="col">Page name</th>

                <th scope="col">Visitors</th>

                <th scope="col">Unique users</th>

                <th scope="col">Bounce rate</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="row">/argon/</th>

                <td>4,569</td>

                <td>340</td>

                <td>
                  <i class=" fas fa-arrow-up text-success mr-3"> </i> 46,53%
                </td>
              </tr>

              <tr>
                <th scope="row">/argon/index.html</th>

                <td>3,985</td>

                <td>319</td>

                <td>
                  <i class=" fas fa-arrow-down text-warning mr-3"> </i> 46,53%
                </td>
              </tr>

              <tr>
                <th scope="row">/argon/charts.html</th>

                <td>3,513</td>

                <td>294</td>

                <td>
                  <i class=" fas fa-arrow-down text-warning mr-3"> </i> 36,49%
                </td>
              </tr>

              <tr>
                <th scope="row">/argon/tables.html</th>

                <td>2,050</td>

                <td>147</td>

                <td>
                  <i class=" fas fa-arrow-up text-success mr-3"> </i> 50,87%
                </td>
              </tr>

              <tr>
                <th scope="row">/argon/profile.html</th>

                <td>1,795</td>

                <td>190</td>

                <td>
                  <i class=" fas fa-arrow-down text-danger mr-3"> </i> 46,53%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class=" col-xl-4">
      <div class=" card">
        <div class=" card-header border-0">
          <div class=" row align-items-center">
            <div class=" col"><h3 class=" mb-0">Social traffic</h3></div>

            <div class=" col text-right">
              <a class=" btn btn-sm btn-primary" href="javascript:void(0)">
                See all
              </a>
            </div>
          </div>
        </div>

        <div class=" table-responsive">
          <table class=" table align-items-center table-flush">
            <thead class=" thead-light">
              <tr>
                <th scope="col">Referral</th>

                <th scope="col">Visitors</th>

                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th scope="row">Facebook</th>

                <td>1,480</td>

                <td>
                  <div class=" d-flex align-items-center">
                    <span class=" mr-2"> 60% </span>

                    <div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-gradient-danger"
                          role="progressbar"
                          aria-valuenow="60"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 60%;"
                        ></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row">Facebook</th>
                <td>5,480</td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">70%</span>
                    <div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-gradient-success"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 70%;"
                        ></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">Google</th>
                <td>4,807</td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">80%</span>
                    <div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-gradient-primary"
                          role="progressbar"
                          aria-valuenow="80"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 80%;"
                        ></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">Instagram</th>
                <td>3,678</td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">75%</span>
                    <div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-gradient-info"
                          role="progressbar"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 75%;"
                        ></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">twitter</th>
                <td>2,645</td>
                <td>
                  <div class="d-flex align-items-center">
                    <span class="mr-2">30%</span>
                    <div>
                      <div class="progress">
                        <div
                          class="progress-bar bg-gradient-warning"
                          role="progressbar"
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="width: 30%;"
                        ></div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
