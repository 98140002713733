import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public httpOptions: {};
  constructor(private http: HttpClient) { 
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getInvoicePriceReport(): Observable<any> {
    return this.http.get('/api/Report/getInvoicePriceReport', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }
}
