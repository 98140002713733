import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService, PrimeNGConfig, SelectItem } from 'primeng/api';
import { ProductmanagementService } from '../../../product/services/productmanagement.service';
import { VattypesService } from '../../../../shared/service/vattypes.service';
import { OtherChargesService } from '../../services/OtherChargesService';
import { InvoiceManagementService } from '../../services/InvoiceManagement.service';
import { InvoiceLineItems } from '../../models/invoice-line-items.model';
import { OtherChargesTypes } from '../../models/other-charges-type';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../services/alert.service';
import { InvoiceMapDeliverable, InvoiceMapDeliverableItem, InvoiceMapDeliverableLineItem } from '../../models/invoice-map-deliverable.model';
import { MapDeliverableService } from '../../services/map-deliverable.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css'],
  providers: [PrimeNGConfig, VattypesService, ProductmanagementService, OtherChargesService, ConfirmationService, MessageService],
  preserveWhitespaces: true,
})
export class InvoiceComponent implements OnInit {
  AdjustTotal:any;
  selectDate :any;
  AddSupplier: boolean = false;
  AddProduct: boolean = false;
  AddOtherChargeType: boolean = false;
  public disabled: boolean = true;
  errorMessage: string = "";
  InvoiceForm: FormGroup;
  NonInvoiceForm: FormGroup;
  OtherChargeNewForm: FormGroup;
  vatTypes: any[] = [];
  product: any[] = [];
  productCopy: any[] = [];
  OtherChargeType: any[] = [];
  OtherChargeTypeCopy: any[] = [];
  suppliers: any[] = [];
  public Lineinvoice = [];
  public nonProdItems = [];
  public previousUnitPrice: any = [];
  SelectedSupplierID: number;
  isValid: boolean = false;
  invoiceLineItems: InvoiceLineItems = new InvoiceLineItems(0, 0, null, null, 0,
    0.0, 0.0, 0.0, null, false,
    false, false, 0, 0, null,
    0, 0, 0.0, 0.0, 0,
    0, null, null, 0, 0, 0, null, null, null,
    null, null);
  otherChargesTypes: OtherChargesTypes = new OtherChargesTypes(0, 0, null, 0, null, null, 0, 0, null, null, null, 0, 0, 0, 0, 0, null, false, null, null, null, null);
  clonedInvoice: { [s: string]: InvoiceLineItems; } = {};
  clonedNonProd: { [s: string]: OtherChargesTypes; } = {};
  DisabledAdd: boolean = true;
  DisabledAddOtherCharges: boolean = true;
  errorMessageInv: string = "";
  productVariations: any = [];
  SupplierLoading: boolean = false;
  public InvoiceSubmitDisable: boolean;
  TotalAmount: number;
  SelectedProductID: number = 0;
  ProductVariation: number;
  invoiceItems: any = [];
  invItem: any = [];
  successGridMessage: string;
  errorGridMessage: string;
  SelectedVATType: number = 0;
  nonProd: any = [];
  SelectedOtherChargeType: number = 0;
  SelectedOtherChargeVATType: number = 0;
  public InvoiceID: number;
  position: string;
  successMessageInv: string = "";
  errorMessageNon: string = "";
  successMessageNon: string = "";
  SelectedProductVariation: number;
  TotalValues: any = [];
  TotalValuesInv: any = [];
  updatedInv: any = [];
  successMessage: string = "";
  SupplierProductCode: string = "";
  checkInvoice: boolean = false;
  InvoiceItemID: number;
  errorMessageSuppliers: string;
  errorMessageInvoiceNumber: string;
  errorMessageInvoiceDate: string;
  errorMessageDiscountPercentage: string;
  errorMessageSelectedProduct: string;
  errorMessageVATTypes: string;
  errorMessageQuantity: string;
  errorMessageUnitPrice: string;
  errorMessageProductCode: string;
  errorOtherChargesTypes: string;
  errorOtherChargesVATTypes: string;
  errorOtherChargesAmount: string;
  load: boolean = false;
  loading: boolean = false;
  otherChargeNewName: string = "";
  otherChargeNewDescription: string = "";
  addOtherChargeMsg: string = "";
  addOtherChargeErrorMsg: string = "";
  InvoiceMapDeliverableItems:InvoiceMapDeliverableItem[]=[];
  InvoiceMapDeliverableDetails:InvoiceMapDeliverable[];
  addOtherChargeMsgSuccess="";
  addOtherChargeMsgError = "";
  isShownProduct: boolean = false;
  isShownSupplier: boolean = false;
  suppliersCopy: any[] = [];
  isExited: boolean = false;
  SelectedSupplierName: string = "";
  SelectedProductName: string = "";

  constructor(private primengConfig: PrimeNGConfig, private vattypesService: VattypesService,
    private formBuilder: FormBuilder, private otherChargesService: OtherChargesService, private invoiceManagementService: InvoiceManagementService,
    private confirmationService: ConfirmationService,
    private productmanagementService: ProductmanagementService,
    private router: Router,
    private alertService: AlertService,
    private mapDeliverableService: MapDeliverableService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe) { }
  

  ngOnInit(): void {
    this.getAllSuppliers();
    this.getAllVATTypes();
    this.getAllOtherChargesType();
    this.primengConfig.ripple = true;
    this.InvoiceForm = this.formBuilder.group({
      ID: [''],
      OtherChargesID: [''],
      InvoiceID: [''],
      SupplierID: [0, Validators.required],
      InvoiceNumber: ['', Validators.required],
      SupplierDate: ['', Validators.required],
      SupplierDiscount: [0, Validators.required],
      ProductCode: ['', Validators.required],
      SelectedProductID: ['', Validators.required],
      VatTypeID: [0, Validators.required],
      ProductVariation: [''],
      LabelProductVariation: [''],
      ProductQuantity: ['', Validators.required],
      ProductUnitPrice: ['', Validators.required],
      ProductNetPrice: [''],
      OtherChargeTypeID: [''],
      OtherChargeVATTypeID: [''],
      Amount: [''],
      Comments: [''],
      NetTotal: [''],
      TotalVAT: [''],
      GrandTotal: [''],
      AdjustTotal: 0,
    });
    this.NonInvoiceForm = this.formBuilder.group({
      OtherChargesID: [''],
      InvoiceID: [''],
      OtherChargeTypeID: [''],
      OtherChargeVATTypeID: [''],
      Amount: [''],
      NetTotal: [''],
      TotalVAT: [''],
      GrandTotal: [''],
      Comments: [''],
    });
    this.OtherChargeNewForm = this.formBuilder.group({
      otherChargeNewName: [''],
      otherChargeNewDescription: [''],
    });
    this.invItem.length = 0;
    this.InvoiceForm.controls['ProductNetPrice'].disable();
    this.SelectedSupplierID= this.activatedRoute.snapshot.queryParams['supplierID'];
    this.invoiceLineItems.SupplierInvoiceID = this.activatedRoute.snapshot.queryParams['invoiceNo'];
    if (this.SelectedSupplierID != 0 && this.invoiceLineItems.SupplierInvoiceID) {
      this.InvoiceForm.patchValue({
        SupplierID: this.SelectedSupplierID,
        InvoiceNumber: this.invoiceLineItems.SupplierInvoiceID,
        SupplierDate: this.activatedRoute.snapshot.queryParams['invoiceDate'],
        SupplierDiscount: this.activatedRoute.snapshot.queryParams['discountPercentage'],
      });
      this.onChangeInvoiceNumber(this.SelectedSupplierID, this.invoiceLineItems.SupplierInvoiceID);
    }

  }

  showAddSupplier() {
    this.isShownSupplier =  true;
  }

  exitSup(value){
    this.invoiceLineItems.InvoiceDate = null;
    this.isShownSupplier = value.isShownSup;
    this.isExited = true;
    this.SelectedSupplierName = value.supValue;
    this.InvoiceForm.patchValue({
      ID: '',
      OtherChargesID: '',
      InvoiceID: '',
      SupplierID: 0,
      InvoiceNumber: '',
      SupplierDate: null,
      SupplierDiscount: 0,
      ProductCode: '',
      SelectedProductID: 0,
      VatTypeID: 0,
      ProductVariation: '',
      LabelProductVariation: '',
      ProductQuantity: 0,
      ProductUnitPrice: 0,
      ProductNetPrice: 0,
      OtherChargeTypeID: '',
      OtherChargeVATTypeID: '',
      Amount: '',
      Comments: '',
      NetTotal: '',
      TotalVAT: '',
      GrandTotal: '',
      AdjustTotal: 0,
    });
    this.invoiceLineItems.TotalAmount = '';
    this.invoiceLineItems.VATTotal = '';
    this.invoiceLineItems.PerDiscountNetAmount = '';
    this.invoiceItems = [];
    if(this.SelectedSupplierName != ""){
      this.getAllSuppliers();
    }
    this.getAllProducts();
  }

  showAddProduct() {
    this.SelectedSupplierID = this.InvoiceForm.get("SupplierID").value;
    this.isShownProduct = true;
  }

  exitProd(value){
    this.isShownProduct = value.isShownProd;
    this.isExited = true;
    this.SelectedProductName = value.prodVal;
    this.getAllProducts();
  }

  showAddOtherChargeType() {
    this.addOtherChargeMsgError ="";
    this.addOtherChargeMsgSuccess = "";
    this.addOtherChargeErrorMsg = "";
    this.OtherChargeNewForm.patchValue({
      otherChargeNewName: null,
      otherChargeNewDescription: null,
    });
    this.OtherChargeNewForm.controls['otherChargeNewName'].setErrors(null);
    this.OtherChargeNewForm.controls['otherChargeNewDescription'].setErrors(null);
    this.AddOtherChargeType = true;
  }

  AddOtherChargeTypeValue() {
    var pattern = /^[0-9a-zA-Z/\\_-]+$/;
    if (this.otherChargeNewName == "" || this.otherChargeNewName == null) {
      this.addOtherChargeErrorMsg = "Enter Name";
      this.addOtherChargeMsgError="";
      this.addOtherChargeMsgSuccess = "";
    }
    else if (!pattern.test(this.otherChargeNewName)) {
      this.addOtherChargeErrorMsg = "Enter Proper Name";
      this.addOtherChargeMsgError="";
      this.addOtherChargeMsgSuccess = "";
    }
    else {
      this.addOtherChargeErrorMsg = "";
      let description = "";
      if (this.otherChargeNewDescription == "" || this.otherChargeNewDescription == null) {
        description = "NULL";
      }
      else {
        description = this.otherChargeNewDescription;
      }
      this.otherChargesService.AddNewOtherChargeType(this.otherChargeNewName, description).subscribe(
        data => {
          this.addOtherChargeMsgError="";
          this.addOtherChargeMsgSuccess = "Adding Other Charges Successful!";
          this.getAllOtherChargesType();
          this.InvoiceForm.patchValue({
            OtherChargeTypeID: 0,
            OtherChargeVATTypeID: 0,
            Amount: 0,
          });
          this.OtherChargeNewForm.patchValue({
            otherChargeNewName: null,
            otherChargeNewDescription: null,
          });
          this.OtherChargeNewForm.controls['otherChargeNewName'].setErrors(null);
          this.OtherChargeNewForm.controls['otherChargeNewDescription'].setErrors(null);
        },
        error=>{
          this.addOtherChargeMsgError="Error while adding Other Charges";
          this.addOtherChargeMsgSuccess = "";
        }
      );
    }
  }

  CancelOtherChargeTypeValue() {
    this.AddOtherChargeType = false;
  }

  DisableAdd() {
    if (this.invoiceLineItems.UnitPrice) {
      this.InvoiceSubmitDisable = false;
    }
    else {
      this.InvoiceSubmitDisable = true;
    }
  }

  checkInvoiceDate(InvoiceDate) {

    if (!InvoiceDate) { // Check if InvoiceDate is null or undefined
      this.errorMessageInvoiceDate = "Select date";
  } else {
      // Reset error message if a date is selected
      this.errorMessageInvoiceDate = "";
  }
  }

  checkInvoiceDiscount(InvoiceDiscount) {
    if (!InvoiceDiscount) {
      this.invoiceLineItems.DiscountPercentage = 0;
      return;
    }
  }

  getAllVATTypes() {
    this.vattypesService.getAllVATTypes()
      .subscribe(
        data => {
          this.vatTypes = data;
          const newValue = { ID: 0, VATCode: '--Select VATType--' };
          this.vatTypes.unshift(newValue);

          if (this.SelectedProductID <= 0) {
            this.InvoiceForm.patchValue({
              VatTypeID: 0,
            });
          }
        },
        error => {
          this.errorMessage = "Error while fetching vat types data";
          return;
        });
  }

  getAllProducts() {
    this.productmanagementService.getProducts()
      .subscribe(
        data => {
          this.product = [];
          this.productCopy = data;
          let filtered: any[] = [];
          if (this.SelectedSupplierID != 0) {
            for (let i = 0; i < this.productCopy.length; i++) {
              let pro = this.productCopy[i];
              if (pro.SupplierID == this.SelectedSupplierID) {
                filtered.push(pro);
              }
            }
            this.product = filtered;
            if (this.product.length > 0) {
              const newValue = { ID: 0, Name: '--Select Product--' };
              this.product.unshift(newValue);
              this.InvoiceForm.patchValue({
                SelectedProductID: 0,
              });
            }
          }
          if(this.isExited == true){
            var prod = this.productCopy.filter(x=> x.Name == this.SelectedProductName);
            //console.log(prod);
            this.SelectedProductID = prod[0].ID;
            this.invoiceLineItems.ProductID = this.SelectedProductID;
            this.InvoiceForm.patchValue({
              SelectedProductID: prod[0].ID,
              ProductCode: prod[0].SupplierProductCode,
              VatTypeID: prod[0].VatTypeID,
            });
          }
        },
        error => {
          this.errorMessage = "Error while fetching product data";
          return;
        });
  }

  getAllOtherChargesType() {
    this.otherChargesService.getAllOtherChargesType()
      .subscribe(
        data => {
          this.OtherChargeType = data;
          this.OtherChargeTypeCopy = data;
          const newValue = { ID: 0, Name: '--Select Other Charges Type--' };
          this.OtherChargeType.unshift(newValue);
        },
        error => {
          this.errorMessage = "Error while fetching other charges type data";
          return;
        });
  }

  SubmitInvoice(position: string) {
    this.position = position;
    this.errorMessage = "";
    this.errorMessageNon = "";
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    if (this.invItem.length > 0) {
      this.getUnmapDeliverableDetails();
      this.confirmationService.confirm({
        header: 'Confirmation',
        message: "Are you sure you want to submit the invoice?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.invoiceManagementService.updateInvoice(this.invoiceLineItems).subscribe(
            data => {
              this.updatedInv = data;
              if (this.updatedInv['IsInvoiceSubmitted'] == true) {
                this.DisabledAdd = true;
                this.DisabledAddOtherCharges = true;
                this.invoiceItems = [];
                this.nonProd = [];
                this.IsunmapDelivebleExist();
                this.clearInvoiceForm("All");
                this.invoiceLineItems.VATTotal = 0;
                this.invoiceLineItems.TotalAmount = 0;
                this.invoiceLineItems.PerDiscountNetAmount = 0;
              }
              this.successMessage = "Invoice Form Submitted Successfully!";
            });
        },
        reject: () => {
          this.errorMessage = "You have cancelled";
        },
        key: "positionDialog"
      });
    }
    else {
      this.errorMessage = "No invoice data found for submitting";
    }
  }

  getAllSuppliers() {
    this.SupplierLoading = true;
    const newValue = { ID: 0, Name: 'Loading Suppliers ...' };
    this.suppliers.unshift(newValue);
    this.productmanagementService.getAllSuppliers()
      .subscribe(
        data => {
          this.suppliers = data;
          this.suppliersCopy = data;
          if(this.isExited == true){
            var sup = this.suppliers.filter(x=> x.Name == this.SelectedSupplierName);
            this.SelectedSupplierID = sup[0].ID;
            this.invoiceLineItems.SupplierID = sup[0].ID;
            this.InvoiceForm.patchValue({
              SupplierID: sup[0].ID,
            });
            this.productmanagementService.supplierId = this.SelectedSupplierID;
            this.isExited = false;
          }
          if (this.suppliers.length > 0) {
            const newValue = { ID: 0, Name: '--Select Supplier--' };
            this.suppliers.unshift(newValue);
          }

          if (this.SelectedSupplierID <= 0) {
            this.product = [];
          }
          else {
            this.getAllProducts();
          }
        },
        error => {
          this.errorMessage = "Error while fetching supplier data";
          return;
        });
  }

//UI Date View
  formatDateForUI(date)
  {
    if (date != null && date != "") 
      {
    
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
    
      return `${day}-${month}-${year}`;
      }
      else
      {
        return null;
      }
  }
// Function to format date in dd-mm-yyyy format
formatDate(date) {
  if (date != null && date != "") 
  {

  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
  }
  else
  {
    return null;
  }
}

storeSelectedDate(a: Date | string) {
  //console.log(a);
  // Check if the input is in the default Date object format
  if (a instanceof Date && !isNaN(a.getTime())) {
    const formattedDate = this.datePipe.transform(a, 'yyyy-MM-dd');
    this.invoiceLineItems.InvoiceDate = formattedDate;
    
  } else if (typeof a === 'string') {
    // Check if the input is in the format 'dd-mm-yyyy'
    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
    if (datePattern.test(a)) {
      const parts = a.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      this.invoiceLineItems.InvoiceDate = formattedDate;
    } else {
     // console.error('Invalid date format');
      // Handle invalid date format accordingly
    }
  } else {
   // console.error('Invalid date');
    // Handle invalid date input accordingly
  }

 //console.log(this.invoiceLineItems.InvoiceDate);
}



  onChangeInvoiceNumber(supplierID, invoiceNumber) {
    this.invoiceLineItems.InvoiceDate = null;
    this.invoiceItems = [];
    this.invItem.length = 0;
    this.nonProd = [];
    this.productVariations = [];
    this.DisabledAddOtherCharges = true;
    this.DisabledAdd = true;
    this.errorMessageNon = "";
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    this.successMessage = "";
    this.errorMessage = "";
    this.errorMessageInv = "";
    this.successMessageInv = "";
    this.invoiceLineItems.IsInvoiceSubmitted = false;
    this.InvoiceForm.patchValue({
      ID: [''],
      OtherChargesID: [''],
      InvoiceID: [''],
      SupplierDate: null,
      SupplierDiscount: 0,
      ProductCode: '',
      SelectedProductID: 0,
      ProductVariation: 0,
      VatTypeID: 0,
      ProductQuantity: 0,
      ProductUnitPrice: 0,
      ProductNetPrice: 0,
      NetTotal: 0,
      TotalVAT: 0,
      GrandTotal: 0,
      AdjustTotal: 0,
      OtherChargeTypeID: 0,
      OtherChargeVATTypeID: 0,
      Amount: 0,
      Comments: '',
    });

    this.NonInvoiceForm.patchValue({
      OtherChargeTypeID: 0,
      OtherChargeVATTypeID: 0,
      Amount: 0,
      Comments: '',
    });


    supplierID = this.SelectedSupplierID;
    if (supplierID == "" || supplierID == null) {
      this.errorMessageSuppliers = "Select Supplier"
      return;
    }
    if (invoiceNumber == "" || invoiceNumber == null) {
      this.errorMessageInvoiceNumber = "Enter Invoice number"
      return;
    }
    else if (invoiceNumber != "") {
      this.errorMessageInvoiceNumber = "";
      this.invoiceLineItems.SupplierID = supplierID;
      this.invoiceLineItems.SupplierInvoiceID = invoiceNumber;
      //console.log(typeof (this.invoiceItems));
      this.ViewInvoice(this.invoiceLineItems.SupplierInvoiceID);
      this.ViewOtherCharges(this.invoiceLineItems.SupplierInvoiceID);

    }
  }
  ViewOtherCharges(invoiceNumber) {
    this.NonInvoiceForm.patchValue({
      OtherChargeTypeID: 0,
      OtherChargeVATTypeID: 0,
      Amount: 0,
      Comments: '',
    });
    this.load = true;
    this.otherChargesService.getOtherCharges(this.SelectedSupplierID, invoiceNumber).subscribe(
      data => {
        //
        this.nonProd = data;
        this.load = false;
        if (this.nonProd.length > 0) {
          for (let i = 0; i < this.nonProd.length; i++) {
            let filter = this.nonProd[i];
            if (filter['InvoiceID'] == this.InvoiceID) {
              this.otherChargesTypes.InvoiceID = filter['InvoiceID'];
              this.otherChargesTypes.PerDiscountNetAmount = filter['PerDiscountNetAmount'];
              this.otherChargesTypes.VATTotal = filter['VATTotal'];
              this.otherChargesTypes.TotalAmount = filter['TotalAmount'];
              this.NonInvoiceForm.patchValue({
                InvoiceID: this.otherChargesTypes.InvoiceID,
                NetTotal: this.otherChargesTypes.PerDiscountNetAmount,
                TotalVAT: this.otherChargesTypes.VATTotal,
                GrandTotal: this.otherChargesTypes.TotalAmount,
              });
            }
          }
        }
        else {
          this.nonProd = [];
        }
      },
      error => {
        this.load = false;
      });
  }

  ViewInvoice(number) {
    this.invoiceLineItems.InvoiceDate = null;
    this.errorMessageNon = "";
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    this.successMessage = "";
    this.errorMessage = "";
    this.errorMessageInv = "";
    this.invoiceLineItems.IsInvoiceSubmitted = false;
    this.InvoiceForm.patchValue({
      ID: [''],
      OtherChargesID: [''],
      InvoiceID: 0,
      SupplierDate: null,
      SupplierDiscount: 0,
      ProductCode: '',
      SelectedProductID: 0,
      ProductVariation: 0,
      VatTypeID: 0,
      ProductQuantity: 0,
      ProductUnitPrice: 0,
      ProductNetPrice: 0,
      NetTotal: 0,
      TotalVAT: 0,
      GrandTotal: 0,
      AdjustTotal: 0,
      OtherChargeTypeID: 0,
      OtherChargeVATTypeID: 0,
      Amount: 0,
      Comments: '',
    });
    this.invoiceLineItems.NetPrice = 0;
    this.invoiceLineItems.TotalAmount = 0;
    this.invoiceLineItems.VATTotal = 0;
    this.invoiceLineItems.PerDiscountNetAmount = 0;
    this.loading = true;
    this.invoiceManagementService.getAllInvoice(this.SelectedSupplierID, number)
      .subscribe(
        data => {
          this.invoiceItems = data;
          this.loading = false;
          //console.log(this.invoiceItems);
          if (this.invoiceItems.length > 0) {
            for (let i = 0; i < this.invoiceItems.length; i++) {
              var item = this.invoiceItems[i];
              if (item['SupplierInvoiceID'] == number && item['SupplierID'] == this.SelectedSupplierID) {
                this.invoiceLineItems.ID = item['ID'];
                this.InvoiceItemID = item['ID'];
                this.invoiceLineItems.SupplierInvoiceID = item['SupplierInvoiceID'];
                this.invoiceLineItems.InvoiceDate = this.formatDate(new Date(item['InvoiceDate']));
                this.invoiceLineItems.DiscountPercentage = item['DiscountPercentage'];
                this.otherChargesTypes.InvoiceID = item['InvoiceID'];
                this.InvoiceID = item['InvoiceID'];
                if (this.InvoiceID == null) {
                  this.InvoiceID = item['ID'];
                }
                this.invoiceManagementService.getlengthofInvoiceItems(this.InvoiceID).subscribe(
                  data => {
                    this.invItem = data;
                  });
                this.invoiceLineItems.Label_SupplierName = item['Label_SupplierName'];
                this.invoiceLineItems.InvoiceID = item['InvoiceID'];
                this.invoiceLineItems.IsInvoiceSubmitted = item['IsInvoiceSubmitted'];
                this.TotalAmount = item['TotalAmount'];
                this.invoiceLineItems.PerDiscountNetAmount = item['PerDiscountNetAmount'];
                this.invoiceLineItems.TotalAmount = item['TotalAmount'];
                this.invoiceLineItems.VATTotal = item['VATTotal'];
                this.InvoiceForm.patchValue({
                  ID: item['ID'],
                  SupplierID: item['SupplierID'],
                  InvoiceNumber: item['SupplierInvoiceID'],
                  SupplierDate: this.formatDateForUI(new Date(item['InvoiceDate'])),
                  SupplierDiscount: item['DiscountPercentage'],
                  ProductCode: '',
                  NetTotal: this.invoiceLineItems.PerDiscountNetAmount,
                  TotalVAT: this.invoiceLineItems.VATTotal,
                  GrandTotal: this.invoiceLineItems.TotalAmount,
                });
                this.InvoiceForm.controls['ProductCode'].setErrors(null);
              }
              else {
                this.invoiceLineItems.PerDiscountNetAmount = 0;
                this.invoiceLineItems.TotalAmount = 0;
                this.invoiceLineItems.VATTotal = 0;
                this.InvoiceForm.patchValue({
                  NetTotal: 0,
                  TotalVAT: 0,
                  GrandTotal: 0,
                });
              }
            }
          }
          else {
            this.invItem.length = 0;
          }
        },
        error => {
          this.loading = false;
          this.invoiceItems = [];
          this.invoiceLineItems.PerDiscountNetAmount = 0;
          this.invoiceLineItems.TotalAmount = 0;
          this.invoiceLineItems.VATTotal = 0;
          this.InvoiceForm.patchValue({
            NetTotal: 0,
            TotalVAT: 0,
            GrandTotal: 0,
          });
        });
  }

  checkInvoiceNumber(InvoiceNumber) {
    if (!InvoiceNumber) {
      this.errorMessageInvoiceNumber = "Enter invoice number";
      return;
    }
    var pattern = /^[0-9a-zA-Z/\\_-]+$/;
    if (!pattern.test(InvoiceNumber)) {
      this.errorMessageInvoiceNumber = "Enter proper invoice number";
      return;
    }
  }

  onSelectSupplierChange(event) {

    this.invoiceLineItems.InvoiceDate =null;
    this.errorMessageInvoiceDate =null;
    this.nonProd = [];
    this.productVariations = [];
    this.invoiceItems = [];
    this.successMessage = "";
    this.invItem.length = 0;
    this.InvoiceID = 0;
    let filtered: any[] = [];
    let query = event.value;
    this.SelectedSupplierID = query;


    if (query > 0) {
      for (let i = 0; i < this.productCopy.length; i++) {
        let pro = this.productCopy[i];
        if (pro.SupplierID == query) {
          filtered.push(pro);

        }
      }
      this.product = filtered;
      if (this.product.length > 0) {
        const newValue = { ID: 0, Name: '--Select Product--' };
        this.product.unshift(newValue);
      }
    }
    else {
      this.product = [];
    }

    this.invoiceItems = [];
    this.nonProd = [];
    this.InvoiceForm.patchValue({
      ID: [''],
      OtherChargesID: [''],
      InvoiceID: [''],
      InvoiceNumber: '',
      SupplierDate: null,
      SupplierDiscount: 0,
      ProductCode: '',
      SelectedProductID: 0,
      ProductVariation: 0,
      VatTypeID: 0,
      ProductQuantity: 0,
      ProductUnitPrice: 0,
      ProductNetPrice: 0,
      OtherChargeTypeID: 0,
      OtherChargeVATTypeID: 0,
      Amount: 0,
      Comments: '',
      NetTotal: '',
      TotalVAT: '',
      GrandTotal: '',
      AdjustTotal: 0,
    });
    this.NonInvoiceForm.patchValue({
      OtherChargeTypeID: 0,
      OtherChargeVATTypeID: 0,
      Amount: [''],
      Comments: [''],
    });
    this.invoiceLineItems.TotalAmount = 0;
    this.invoiceLineItems.VATTotal = 0;
    this.invoiceLineItems.PerDiscountNetAmount = 0;

  }

  onSelectVATChange(event) {
    let query = event.value;
    this.SelectedVATType = query;
    if (query > 0) {
      for (let i = 0; i < this.vatTypes.length; i++) {
        let vat = this.vatTypes[i];
        if (vat['ID'] == this.SelectedVATType) {
          this.invoiceLineItems.Label_VATTypeName = vat['Name'];
        }
      }
    }
    else {
      this.errorMessageVATTypes = "Select VAT Type";
    }
  }
  onSelectProductVariation(event) {
    let query = event.value;
    this.invoiceLineItems.Quantity = 0;
    this.invoiceLineItems.UnitPrice = 0;
    this.invoiceLineItems.NetPrice = 0;
    if (query >= 0) {
      this.isValid = true;
      this.errorMessageInv = "";
      this.SelectedProductVariation = query;
      this.ProductVariation = query;
      this.invoiceLineItems.ProductVariationID = this.ProductVariation;
      for (let i = 0; i < this.productVariations.length; i++) {
        let filter = this.productVariations[i];
        if (filter['ID'] == this.SelectedProductVariation) {
          this.invoiceLineItems.Label_ProductVariationName = filter['Name'];
        }
      }
      for (let j = 0; j < this.invoiceItems.length; j++) {
        let inv = this.invoiceItems[j];
        if (inv['ProductID'] == this.SelectedProductID && this.invoiceLineItems.ProductVariationID == inv['ProductVariationID']) {
          this.invoiceLineItems.ProductVariationID = inv['ProductVariationID'];
          this.invoiceLineItems.Quantity = inv['Quantity'];
          this.invoiceLineItems.UnitPrice = inv['UnitPrice'];
          this.invoiceLineItems.VATTypeID = inv['VATTypeID'];
          this.invoiceLineItems.NetPrice = inv['NetPrice'];
          this.DisabledAdd = false;
        }
      }
      this.InvoiceForm.patchValue({
        ProductVariation: this.invoiceLineItems.ProductVariationID,
        ProductQuantity: this.invoiceLineItems.Quantity,
        ProductUnitPrice: this.invoiceLineItems.UnitPrice,
        ProductNetPrice: this.invoiceLineItems.NetPrice,
      });
    }
  }
  variation(id, value) {
    this.invoiceManagementService.getVariations(id).subscribe(
      data => {
        this.productVariations = data;
        if (this.productVariations.length > 0) {
          const newValue = { ID: 0, Name: '--Select Product Variation--' };
          this.productVariations.unshift(newValue);
        }
        else {
          this.productVariations = [];
        }
      },
      error => {
        this.errorMessage = "Error while fetching product variation data";
      });
    this.ProductVariation = value;
    this.InvoiceForm.patchValue({
      ProductVariation: value,
    });
  }

  onSelectProductChange(event) {
    this.successMessageInv = "";
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    this.successMessage = "";
    this.errorMessageNon = "";
    this.errorMessage = "";
    this.productVariations = [];
    this.SelectedProductVariation = 0;
    this.InvoiceForm.patchValue({
      ProductCode: '',
      VatTypeID: 0,
      ProductQuantity: 0,
      ProductUnitPrice: 0,
      ProductNetPrice: 0,
    });
    let query = event.value;
    this.SelectedProductID = query;
    //this.SelectedProductVariation = 0;
    this.invoiceLineItems.ProductID = query;
    this.invoiceLineItems.VATTypeID = this.SelectedVATType;
    if (query > 0) {
      this.variation(this.SelectedProductID, 0);
      this.invoiceManagementService.getAllInvoice(this.SelectedSupplierID, this.invoiceLineItems.SupplierInvoiceID).subscribe(
        data => {
          this.invoiceItems = data;
          for (let i = 0; i < this.product.length; i++) {
            let pro = this.product[i];
            if (pro['ID'] == this.SelectedProductID) {
              this.invoiceLineItems.SupplierProductCode = pro['SupplierProductCode'];
              this.SupplierProductCode = pro['SupplierProductCode'];
              this.invoiceLineItems.ProductID = pro['ID'];
              this.invoiceLineItems.VATTypeID = pro['VatTypeID'];
              this.DisabledAdd = true;
              this.InvoiceForm.patchValue({
                ProductCode: this.invoiceLineItems.SupplierProductCode,
                SelectedProductID: this.invoiceLineItems.ProductID,
                VatTypeID: this.invoiceLineItems.VATTypeID,
              });
            }
          }
          for (let j = 0; j < this.invoiceItems.length; j++) {
            let inv = this.invoiceItems[j];
            if (inv['ProductID'] == this.invoiceLineItems.ProductID) {
              this.invoiceLineItems.ProductVariationID = inv['ProductVariationID'];
              this.ProductVariation = inv['ProductVariationID'];
              this.invoiceLineItems.Quantity = inv['Quantity'];
              this.invoiceLineItems.UnitPrice = inv['UnitPrice'];
              this.invoiceLineItems.VATTypeID = inv['VATTypeID'];
              this.invoiceLineItems.NetPrice = inv['NetPrice'];
              this.DisabledAdd = false;
              this.InvoiceForm.patchValue({
                ProductCode: this.invoiceLineItems.SupplierProductCode,
                SelectedProductID: this.invoiceLineItems.ProductID,
                ProductVariation: this.invoiceLineItems.ProductVariationID,
                VatTypeID: this.invoiceLineItems.VATTypeID,
                ProductQuantity: this.invoiceLineItems.Quantity.toFixed(0),
                ProductUnitPrice: this.invoiceLineItems.UnitPrice.toFixed(4),
                ProductNetPrice: this.invoiceLineItems.NetPrice.toFixed(4),
              });
            }
          }

        }
      );

    }
    else {
      this.errorMessageSelectedProduct = "Select a product";
    }
    for (let i = 0; i < this.product.length; i++) {
      let pro = this.product[i];
      if (pro['ID'] == this.SelectedProductID) {
        this.invoiceLineItems.SupplierProductCode = pro['SupplierProductCode'];
        this.invoiceLineItems.ProductID = pro['ID'];
        this.invoiceLineItems.VATTypeID = pro['VatTypeID'];
        this.InvoiceForm.patchValue({
          ProductCode: this.invoiceLineItems.SupplierProductCode,
          SelectedProductID: this.invoiceLineItems.ProductID,
          VatTypeID: this.invoiceLineItems.VATTypeID,
        });
      }
    }
  }


  onChangeProductCode(SupplierCode) {
    this.DisabledAdd = true;
    this.successMessageInv = "";
    this.errorMessageNon = "";
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    this.successMessage = "";
    this.errorMessageNon = "";
    this.errorMessage = "";
    this.InvoiceForm.patchValue({
      SelectedProductID: 0,
      VatTypeID: 0,
      ProductQuantity: 0,
      ProductUnitPrice: 0,
      ProductNetPrice: 0,
    });
    this.invoiceLineItems.SupplierProductCode = SupplierCode;
    if (SupplierCode != "") {
      for (let i = 0; i < this.product.length; i++) {
        let pro = this.product[i];
        if (pro['SupplierProductCode'] == SupplierCode) {
          this.invoiceLineItems.SupplierProductCode = pro['SupplierProductCode'];
          this.invoiceLineItems.ProductID = pro['ID'];
          this.variation(this.invoiceLineItems.ProductID, 0);
          this.invoiceLineItems.VATTypeID = pro['VatTypeID'];
          this.DisabledAdd = true;
          this.InvoiceForm.patchValue({
            ProductCode: this.invoiceLineItems.SupplierProductCode,
            SelectedProductID: this.invoiceLineItems.ProductID,
            VatTypeID: this.invoiceLineItems.VATTypeID,
          });
        }
      }
      for (let j = 0; j < this.invoiceItems.length; j++) {
        let inv = this.invoiceItems[j];
        if (inv['SupplierProductCode'] == this.invoiceLineItems.SupplierProductCode) {
          this.invoiceLineItems.VATTypeID = inv['VATTypeID'];
          this.invoiceLineItems.ProductVariationID = inv['ProductVariationID'];
          this.ProductVariation = inv['ProductVariationID'];
          this.invoiceLineItems.Quantity = inv['Quantity'];
          this.invoiceLineItems.UnitPrice = inv['UnitPrice'];
          this.invoiceLineItems.NetPrice = inv['NetPrice'];
          this.DisabledAdd = false;
          this.InvoiceForm.patchValue({
            ProductCode: this.invoiceLineItems.SupplierProductCode,
            SelectedProductID: this.invoiceLineItems.ProductID,
            ProductVariation: this.invoiceLineItems.ProductVariationID,
            VatTypeID: this.invoiceLineItems.VATTypeID,
            ProductQuantity: this.invoiceLineItems.Quantity.toFixed(0),
            ProductUnitPrice: this.invoiceLineItems.UnitPrice.toFixed(4),
            ProductNetPrice: this.invoiceLineItems.NetPrice.toFixed(4),
          });
        }
      }
    }
    else {
      this.errorMessageProductCode = "Enter Product Code";
    }
  }

  clearInvoiceForm(options) {
    switch (options) {
      case "All":
        this.InvoiceForm.patchValue({
          ID: 0,
          Name: '',
          SupplierID: 0,
          Supplier: '',
          InvoiceNumber: null,
          SupplierDate: 0,
          SupplierDiscount: 0,
          ProductCode: null,
          SelectedProductID: 0,
          ProductVariation: 0,
          VatTypeID: 0,
          ProductQuantity: 0,
          ProductUnitPrice: 0,
          ProductNetPrice: 0,
          NetTotal: '',
          TotalVAT: '',
          GrandTotal: '',
          AdjustTotal: 0,
        });
        this.InvoiceForm.setErrors(null);
      case "Invoice":
        this.InvoiceForm.patchValue({
          InvoiceNumber: '',
          SupplierDate: '',
          SupplierDiscount: 0,
          NetTotal: '',
          TotalVAT: '',
          GrandTotal: '',
        });
      case "InvoiceLineItems":
        this.InvoiceForm.patchValue({
          ProductVariation: 0,
          VatTypeID: 0,
          ProductQuantity: 0,
          ProductUnitPrice: 0,
          ProductNetPrice: 0,
        });
    }
  }

  onChangeQuantity(Quantity) {
    if (Quantity >= 0 || Number.isInteger(Quantity) == true) {
      this.errorMessageQuantity = "";
      this.invoiceLineItems.NetPrice = Quantity * this.invoiceLineItems.UnitPrice;
      this.InvoiceForm.patchValue({
        ProductNetPrice: this.invoiceLineItems.NetPrice.toFixed(4),
      });
    }
    else {
      this.errorMessageQuantity = "Enter proper quantity";
    }
  }

  onChangeUnitPrice(UnitPrice) {
    if (UnitPrice < 0) {
      this.errorMessageQuantity = "Enter proper unit price";
    }
    else {
      this.errorMessageQuantity = "";
      this.invoiceLineItems.NetPrice = UnitPrice * this.invoiceLineItems.Quantity;
      this.InvoiceForm.patchValue({
        ProductNetPrice: this.invoiceLineItems.NetPrice.toFixed(4),
      });
      this.DisabledAdd = false;
    }
  }

  onChangeDiscount(Discount) {
    if (Discount < 0) {
      this.errorMessageDiscountPercentage = "Enter proper discount";
    }
    else {
      this.errorMessageDiscountPercentage = "";
      this.invoiceLineItems.DiscountPercentage = Discount;
      this.InvoiceForm.patchValue({
        SupplierDiscount: this.invoiceLineItems.DiscountPercentage,
      });
    }
  }

  onChangeAdjustTotal(event: any) {
    this.AdjustTotal= event.target.value;
    //console.log(this.AdjustTotal);

    this.invoiceLineItems.TotalAmount = this.TotalAmount;

    if (!this.AdjustTotal) {
      // this.invoiceLineItems.AdjustTotal = 0;
      this.invoiceLineItems.TotalAmount = this.TotalAmount;
    }
    else {
      this.invoiceLineItems.AdjustTotal = this.AdjustTotal;
      this.invoiceLineItems.TotalAmount = this.invoiceLineItems.TotalAmount + Number(this.invoiceLineItems.AdjustTotal);
    }
    this.InvoiceForm.patchValue({
      GrandTotal: this.invoiceLineItems.TotalAmount,
      AdjustTotal: this.invoiceLineItems.AdjustTotal,
    });
  }

  addInvoice(position: string) {
    this.InvoiceForm.patchValue({
      OtherChargeTypeID: 0,
      OtherChargeVATTypeID: 0,
      Amount: 0,
      Comments: null,
    });
    this.position = position;
    var pattern = /^[0-9a-zA-Z/\\_-]+$/;

    if (this.invoiceLineItems.SupplierID == null || this.invoiceLineItems.SupplierID == 0) {
      this.errorMessageSuppliers = "Please select supplier";
    }

    else if (this.invoiceLineItems.SupplierInvoiceID == null || this.invoiceLineItems.SupplierInvoiceID == "") {

      this.errorMessageInvoiceNumber = "Please enter supplier invoice number";
    }

    else if (this.invoiceLineItems.InvoiceDate == null) {
      this.errorMessageInvoiceDate = "Please select date";
    }

    else if (this.SelectedProductID == null || this.SelectedProductID == 0) {

      this.errorMessageSelectedProduct = "Please select product";
    }

    else if (this.invoiceLineItems.VATTypeID == null || this.invoiceLineItems.VATTypeID <= 0) {
      this.errorMessageVATTypes = "Please select VATType";
    }

    else if (this.invoiceLineItems.Quantity < 0) {
      this.errorMessageQuantity = "Please enter proper quantity";
    }

    else if (this.invoiceLineItems.UnitPrice < 0) {
      this.errorMessageUnitPrice = "Please enter unit price";
    }
    else if (!pattern.test(this.invoiceLineItems.SupplierInvoiceID)) {
      this.errorMessageInvoiceNumber = "Enter proper invoice number ";
    }
    else if (this.invoiceLineItems.SupplierProductCode == "" || this.invoiceLineItems.SupplierProductCode == null) {
      this.errorMessageProductCode = "Enter Product Code"
    }
    else {

      this.errorMessageUnitPrice = "";
      this.errorMessageVATTypes = "";
      this.errorMessageDiscountPercentage = "";
      this.errorMessageSuppliers = "";
      this.errorMessageQuantity = "";
      this.errorMessageSelectedProduct = "";
      this.errorMessageInvoiceNumber = "";
      this.errorMessageProductCode = "";
      this.errorMessageInvoiceDate = "";
      for (let i = 0; i < this.vatTypes.length; i++) {
        let vat = this.vatTypes[i];
        if (vat['ID'] == this.invoiceLineItems.VATTypeID) {
          this.invoiceLineItems.Label_VATTypeName = vat['VATCode'];
        }
      }
      //console.log(this.invoiceLineItems);

      for (let i = 0; i < this.product.length; i++) {
        let inv = this.product[i];
        if (inv['ID'] == this.SelectedProductID && inv['SupplierProductCode'] == this.invoiceLineItems.SupplierProductCode) {
          this.invoiceLineItems.Label_ProductName = inv['Name'];
        }
      }
      this.invoiceLineItems.InvoiceID = this.InvoiceID;
      this.invoiceLineItems.ProductID = this.SelectedProductID;
      this.invoiceLineItems.ProductVariationID = this.ProductVariation;
      this.invoiceManagementService.getExtractedIsSubmitted(this.invoiceLineItems).subscribe(
        data => {
          this.previousUnitPrice = data;
          this.accessPreviousUnitPrice();

        }
      );
    }
  }

  accessPreviousUnitPrice() {
    if (this.previousUnitPrice.length > 0) {
      this.checkInvoice = true;
    }
    else {
      this.onCheckInvoiceAccepted();
    }
  }

  onCheckInvoiceAccepted() {
    if (this.previousUnitPrice.length > 0){
      this.alertService.addProductAlert(this.invoiceLineItems).subscribe(
        data =>{
      },
      error=>{
      });
    }
    this.invoiceManagementService.getInvoice(this.invoiceLineItems).subscribe(
      data => {
        this.invoiceItems = data; 
        this.successMessageInv = "Invoice added successfully";
        this.ViewInvoice(this.invoiceLineItems.SupplierInvoiceID);
      });
    this.DisabledAdd = true;
    this.productVariations = [];
    this.checkInvoice = false;
  }

  onCheckInvoiceCancelled() {
    this.checkInvoice = false;
  }


  onSelectOtherChargeType(event) {
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    let query = event.value;
    this.SelectedOtherChargeType = query;
    this.otherChargesTypes.VATTypeID = 0;
    this.otherChargesTypes.Amount = 0;
    this.otherChargesTypes.Comment = "";
    if (query > 0) {
      this.errorOtherChargesTypes = "";
      for (let i = 0; i < this.OtherChargeType.length; i++) {
        let other = this.OtherChargeType[i];
        if (other['ID'] == this.SelectedOtherChargeType) {
          this.otherChargesTypes.Label_OtherChargesType = other['Name'];
          this.otherChargesTypes.OtherChargesTypeID = this.SelectedOtherChargeType;
        }
      }
      for (let i = 0; i < this.nonProd.length; i++) {
        let filter = this.nonProd[i];
        if (filter['OtherChargesTypeID'] == this.otherChargesTypes.OtherChargesTypeID) {
          this.otherChargesTypes.InvoiceID = filter['InvoiceID'];
          this.otherChargesTypes.VATTypeID = filter['VATTypeID'];
          this.otherChargesTypes.Amount = filter['Amount'];
          this.otherChargesTypes.Comment = filter['Description'];
        }
      }
      this.InvoiceForm.patchValue({
        InvoiceID: this.otherChargesTypes.InvoiceID,
        OtherChargeTypeID: this.otherChargesTypes.OtherChargesTypeID,
        OtherChargeVATTypeID: this.otherChargesTypes.VATTypeID,
        Amount: this.otherChargesTypes.Amount,
        Comments: this.otherChargesTypes.Comment,
      });
      this.NonInvoiceForm.patchValue({
        InvoiceID: this.otherChargesTypes.InvoiceID,
        OtherChargeTypeID: this.otherChargesTypes.OtherChargesTypeID,
        OtherChargeVATTypeID: this.otherChargesTypes.VATTypeID,
        Amount: this.otherChargesTypes.Amount,
        Comments: this.otherChargesTypes.Comment,
      });
    }
    else {
      this.errorOtherChargesTypes = "Select Other Charge type";
    }
  }

  onSelectOtherChargeVATChange(event) {
    let query = event.value;
    this.SelectedOtherChargeVATType = query;
    if (query > 0) {
      this.errorOtherChargesVATTypes = "";
      for (let i = 0; i < this.vatTypes.length; i++) {
        let vat = this.vatTypes[i];
        if (vat['ID'] == this.SelectedOtherChargeVATType) {
          this.otherChargesTypes.Label_VATTypeName = vat['Name'];
          this.otherChargesTypes.VATTypeID = vat['ID'];
          this.NonInvoiceForm.patchValue({
            OtherChargeVATTypeID: vat['ID'],
          });
        }
      }
    }
    else {
      this.errorOtherChargesVATTypes = "Select VAT Type";
    }
  }

  onEnterAmount(Amount) {
    if (Amount < 0) {
      this.errorOtherChargesAmount = "Enter proper Amount";
    }
    else {
      this.errorOtherChargesAmount = "";
      this.otherChargesTypes.Amount = Amount;
      this.NonInvoiceForm.patchValue({
        Amount: this.otherChargesTypes.Amount,
      });
      this.DisabledAddOtherCharges = false;
    }
  }

  onEnterDescription(Description) {
    this.otherChargesTypes.Description = Description;
    this.NonInvoiceForm.patchValue({
      Comment: this.otherChargesTypes.Description,
    });
  }

  AddOtherChargeTypes() {
    if (this.SelectedOtherChargeType == null || this.SelectedOtherChargeType <= 0) {
      this.errorOtherChargesTypes = "Enter the Other Charges Type";
    }
    // else if (this.otherChargesTypes.VATTypeID == null || this.otherChargesTypes.VATTypeID == 0) {
    //   this.errorOtherChargesVATTypes = "Enter VAT Type";
    // }
    else if (this.otherChargesTypes.Amount < 0) {
      this.errorOtherChargesAmount = "Enter proper Amount";
    }
    else {
      this.errorOtherChargesAmount = "";
      this.errorOtherChargesVATTypes = "";
      this.errorOtherChargesTypes = "";
      if (this.invItem.length > 0) {
        if (this.NonInvoiceForm.valid) {
          this.otherChargesTypes.SupplierID = this.SelectedSupplierID;
          this.otherChargesTypes.SupplierInvoiceID = this.invoiceLineItems.SupplierInvoiceID;
          this.otherChargesTypes.OtherChargesTypeID = this.SelectedOtherChargeType;
          this.otherChargesTypes.InvoiceID = this.InvoiceID;
          this.otherChargesTypes.VATTypeID = this.SelectedOtherChargeVATType;
          this.DisabledAddOtherCharges = false;
          this.otherChargesService.addOtherCharges(this.otherChargesTypes).subscribe(
            data => {
              this.nonProd = data;
              this.successMessageNon = "Non product line item added successfully";
              this.errorMessageNon = "";
              for (let i = 0; i < this.nonProd.length; i++) {
                let filter = this.nonProd[i];
                if (filter['InvoiceID'] == this.InvoiceID) {
                  this.otherChargesTypes.InvoiceID = filter['InvoiceID'];
                  this.invoiceLineItems.PerDiscountNetAmount = filter['PerDiscountNetAmount'];
                  this.invoiceLineItems.TotalAmount = filter['TotalAmount'];
                  this.invoiceLineItems.VATTotal = filter['VATTotal'];
                  this.InvoiceForm.patchValue({
                    InvoiceID: this.otherChargesTypes.InvoiceID,
                    OtherChargeTypeID: 0,
                    OtherChargeVATTypeID: 0,
                    Amount: 0,
                    Comments: "",
                    NetTotal: this.invoiceLineItems.PerDiscountNetAmount,
                    TotalVAT: this.invoiceLineItems.VATTotal,
                    GrandTotal: this.invoiceLineItems.TotalAmount,
                  });
                  this.ViewOtherCharges(this.invoiceLineItems.SupplierInvoiceID);
                }

                else {
                  this.InvoiceForm.patchValue({
                    InvoiceID: 0,
                    OtherChargeTypeID: 0,
                    OtherChargeVATTypeID: 0,
                    Amount: 0,
                    Comments: null,
                    NetTotal: this.invoiceLineItems.PerDiscountNetAmount,
                    TotalVAT: this.invoiceLineItems.VATTotal,
                    GrandTotal: this.invoiceLineItems.TotalAmount,
                  });
                }
              }


            }
          );
          this.DisabledAddOtherCharges = true;
        }
      }
      else {
        this.errorMessageInv = "No Invoice Line Items Found";
      }
    }
  }


  NetAmount(Quantity, UnitPrice) {
    this.invoiceLineItems.NetPrice = 0;
    if (Quantity >= 0 && UnitPrice >= 0) {
      var Amount = Quantity * UnitPrice;
      this.invoiceLineItems.NetPrice = Amount;

    }

  }

  UnitPriceEmptyDisableAdd(UnitPrice) {
    if (UnitPrice) {
      this.InvoiceSubmitDisable = false;
    }
    else {
      this.InvoiceSubmitDisable = true;
    }
  }

  onRowEditInit(invoiceLine: InvoiceLineItems) {
    this.successMessageInv = "";
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    this.errorMessageInv = "";
    this.variation(invoiceLine.ProductID, invoiceLine.ProductVariationID);
    this.ProductVariation = invoiceLine.ProductVariationID;
    this.InvoiceForm.patchValue({
      ProductCode: invoiceLine.SupplierProductCode,
      SelectedProductID: invoiceLine.ProductID,
      ProductVariation: invoiceLine.ProductVariationID,
      VatTypeID: invoiceLine.VATTypeID,
      ProductQuantity: invoiceLine.Quantity.toFixed(0),
      ProductUnitPrice: invoiceLine.UnitPrice.toFixed(4),
      ProductNetPrice: invoiceLine.NetPrice.toFixed(4),
    });
    //console.log(this.InvoiceForm.value);
    this.DisabledAdd = false;
  }

  onRowEdit(NonProductLineItems: OtherChargesTypes) {
    console.log("NonProductLineItems:", NonProductLineItems);
    this.errorMessageNon = "";
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    this.InvoiceForm.patchValue({
      OtherChargeTypeID: NonProductLineItems.OtherChargesTypeID,
      OtherChargeVATTypeID: NonProductLineItems.VATTypeID,
      Amount: NonProductLineItems.Amount,
      Comments:NonProductLineItems.Comment,
    });
    this.DisabledAddOtherCharges = false;
  }

  onRowEditCancel(invoiceLine: InvoiceLineItems, index: number, event) {
    //this.errorMessage = "";
    this.errorMessageInv = "";
    this.successMessageInv = "";
    this.Lineinvoice[index] = this.clonedInvoice[invoiceLine.ProductID];
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete this Invoice Line Item?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteInvoice(invoiceLine);
      },
      reject: () => {
      }
    });

  }

  onRowDelete(NonProductLineItems: OtherChargesTypes, index: number, event) {
    this.errorMessageNon = "";
    this.successMessageNon = "";
    this.addOtherChargeMsg = "";
    this.nonProdItems[index] = this.clonedNonProd[NonProductLineItems.ID];
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete Non Product Line Invoice?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteNonProdItems(NonProductLineItems);
      },
      reject: () => {
      }
    });

  }

  deleteNonProdItems(NonProductLineItems) {
    this.successGridMessage = "";
    this.errorGridMessage = "";
    this.otherChargesService.deleteOtherCharges(NonProductLineItems).subscribe(
      data => {
        this.TotalValues = data;
        this.onChangeInvoiceNumber(this.SelectedSupplierID, this.invoiceLineItems.SupplierInvoiceID);
        this.invoiceLineItems.TotalAmount = this.TotalValues['TotalAmount'];
        this.invoiceLineItems.PerDiscountNetAmount = this.TotalValues['PerDiscountNetAmount'];
        this.invoiceLineItems.VATTotal = this.TotalValues['VATTotal'];

      },
      error => {
        this.errorGridMessage = "Error while deleting Line Item";
      });
  }

  deleteInvoice(invoiceLine) {
    this.successGridMessage = "";
    this.errorGridMessage = "";
    this.invoiceManagementService.deleteInvoice(invoiceLine)
      .subscribe(
        data => {
          this.TotalValuesInv = data;
          this.ViewInvoice(this.invoiceLineItems.SupplierInvoiceID);
          this.invoiceLineItems.VATTotal = this.TotalValuesInv['VATTotal'];
          this.invoiceLineItems.TotalAmount = this.TotalValuesInv['TotalAmount'];
          this.invoiceLineItems.PerDiscountNetAmount = this.TotalValuesInv['PerDiscountNetAmount'];

        },
        error => {
          this.errorGridMessage = "Error while deleting Line Item";
        });
  }

  newInvoiceForm() {
    this.successMessageInv = "";
    this.errorMessageInv = "";
    this.productVariations = [];
    this.product = [];
    this.invoiceItems = [];
    this.invItem.length = 0;
    this.nonProd = [];
    this.invoiceLineItems.InvoiceDate=null;
    this.InvoiceForm.patchValue({
      ID: [''],
      Name: [''],
      SupplierID: 0,
      Supplier: [''],
      InvoiceNumber: [''],
      SupplierDate: null,
      SupplierDiscount: 0,
      ProductCode: [''],
      SelectedProductID: [''],
      VatTypeID: 0,
      ProductQuantity: 0,
      ProductUnitPrice: 0,
      ProductNetPrice: 0,
      NetTotal: [''],
      TotalVAT: [''],
      GrandTotal: [''],
      AdjustTotal: 0,
    });
    this.invoiceLineItems.PerDiscountNetAmount = 0;
    this.invoiceLineItems.TotalAmount = 0;
    this.invoiceLineItems.VATTotal = 0;
    this.isShownSupplier = false;
  }

  IsunmapDelivebleExist() {
    this.InvoiceMapDeliverableItems = this.InvoiceMapDeliverableItems.filter(items => items.SupplierID == this.SelectedSupplierID);
    if (this.InvoiceMapDeliverableItems.length > 0) {
        var tempArray = [];
        for (var i = 0; i < this.InvoiceMapDeliverableItems.length; i++) {
            if (this.invoiceItems.length == this.InvoiceMapDeliverableItems[i].InvoiceMapDeliverableLineItems.length) {
               
                for (var j = 0; j < this.invoiceItems.length; j++) {
                    for (var k = 0; k < this.InvoiceMapDeliverableItems[i].InvoiceMapDeliverableLineItems.length; k++) {

                        if (
                            this.invoiceItems[j].ProductID == this.InvoiceMapDeliverableItems[i].InvoiceMapDeliverableLineItems[k].ProductID) {
                            tempArray.push(this.InvoiceMapDeliverableItems[i].InvoiceMapDeliverableLineItems[k]);
                            break;
                        }
                    }
                    if (tempArray.length == 0) {
                        break;
                    }
                }
            }

        }
        if (tempArray.length > 0) {
          this.router.navigate(['/AdminDashboard/Pricing/InvoiceMapDeliverables/'], {
            queryParams: { DeliverableNumber: tempArray[0].DeliverableNumber, invoiceNo: this.invoiceItems[0].SupplierInvoiceID, supplierID: this.invoiceItems[0].SupplierID }
         });
            //window.location.href = "/AdminDashboard/Pricing/InvoiceMapDeliverables?DeliverableNumber=" + tempArray[0].DeliverableNumber + "&invoiceNo=" + this.invoiceItems[0].SupplierInvoiceID + "&supplierID=" + this.invoiceItems[0].SupplierID;

        }
        else {
          this.router.navigate(['/AdminDashboard/Pricing/InvoiceMapDeliverables/'], {
            queryParams: { DeliverableNumber: 0, invoiceNo: this.invoiceLineItems.SupplierInvoiceID, supplierID: this.invoiceLineItems.SupplierID }
         });
        }
    }
}

getUnmapDeliverableDetails() {
  this.mapDeliverableService.getUnmapDeliverableDetails()
      .subscribe(
          data => {
              this.InvoiceMapDeliverableDetails = data;
              this.getUnmapDeliverableData();
          }, error => {
               
          })
}

getUnmapDeliverableData() {
  for (var i = 0; i < this.InvoiceMapDeliverableDetails.length; i++) {
    let item = this.InvoiceMapDeliverableItems.filter(element =>  element.DeliverableNumber==this.InvoiceMapDeliverableDetails[i].DeliverableNumber);
    let lineItem = new InvoiceMapDeliverableLineItem(this.InvoiceMapDeliverableDetails[i].DeliverableNumber,this.InvoiceMapDeliverableDetails[i].ProductID,this.InvoiceMapDeliverableDetails[i].SupplierID,this.InvoiceMapDeliverableDetails[i].Name, this.InvoiceMapDeliverableDetails[i].SupplierProductCode, this.InvoiceMapDeliverableDetails[i].Quantity,this.InvoiceMapDeliverableDetails[i].IsReceived,this.InvoiceMapDeliverableDetails[i].IsException,this.InvoiceMapDeliverableDetails[i].Comments,this.InvoiceMapDeliverableDetails[i].DeliveryDate,this.InvoiceMapDeliverableDetails[i].ReceivedQuantity,this.InvoiceMapDeliverableDetails[i].ExceptionQuantity,this.InvoiceMapDeliverableDetails[i].ProductVariationID);
    if (item && item.length > 0) {
      item[0].InvoiceMapDeliverableLineItems.push(lineItem);
    }
    else {
      let lineItems: InvoiceMapDeliverableLineItem[] = [];
      lineItems.push(lineItem);
      let newItem = new InvoiceMapDeliverableItem(this.InvoiceMapDeliverableDetails[i].SupplierID,this.InvoiceMapDeliverableDetails[i].SupplierName,this.InvoiceMapDeliverableDetails[i].DeliverableNumber,lineItems);
      this.InvoiceMapDeliverableItems.push(newItem);
       
    }
  }
}

}

