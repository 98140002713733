export class InvoiceDeliverable {
    constructor(
        public Name :string,
        public SupplierProductCode:string,
        public SupplierID :number,
        public SupplierInvoiceID:string,
        public ProductID: number,
        public InvoiveDetailID :number,
        public Quantity:number,
        public InvoiceDate: string,
        public SupplierName:string,
        public IsReceived:boolean,
        public IsException:boolean,
        public Comments:string,
        public IsInvoiceReceived:boolean,
        public ReceivedQuantity: number,
        public ExceptionQuantity: number,
        public IsResolved:boolean,
        public Acknowledgement:string,
        public DeliveryDate:string,
        public ProductVariationID :number,
        public OldRecievedQuantity : number,
        public IsInventoryUpdated:boolean,
        public WarehouseQuantity:number,
    )
    { }
}

export class InvoiceDeliverableLineItem
{
    constructor(
        public Name :string,
        public SupplierCode:string,
        public Quantity:number,
        public ProductID:number,
        public InvoiceDate:string,
        public ProductVariationID :number,
    )
    { }
}

export class InvoiceDeliverableItem
{
    constructor(
        public SupplierInvoiceID:string,
        public SupplierName:string,
        public SupplierID :number,
        public IsResolved:boolean,
        public InvoiceDate: string,
        public InvoiceDeliverableLineItems:InvoiceDeliverableLineItem[]
    )
    { }
}

export class DeliveryResolvedDetails{
    constructor(
      public IsResolved:boolean,
      public Acknowledgement:string  
    ){}
}
