import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OtherChargesTypes } from '../models/other-charges-type';

@Injectable({
  providedIn: 'root'
})

export class OtherChargesService {

  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getAllOtherChargesType(): Observable<any> {
    return this.http.get('/api/OtherChargesTypes/GetOtherChargesTypes', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getOtherCharges(id: number, invoiceNo: string) {
    return this.http.get('/api/OtherChargesTypes/ViewOtherChargesTypes/' + id + '/' + invoiceNo, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  addOtherCharges(otherCharges: OtherChargesTypes) {
    return this.http.post('/api/OtherChargesTypes/AddOtherChargesTypes', otherCharges, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  editOtherCharges(otherCharges: OtherChargesTypes) {
    return this.http.post('/api/OtherChargesTypes/EditOtherChargesTypes', otherCharges, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  deleteOtherCharges(otherCharges: OtherChargesTypes) {
    return this.http.post('/api/OtherChargesTypes/DeleteOtherChargesTypes', otherCharges, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  AddNewOtherChargeType(Name: string, Description: string) {
    return this.http.get('/api/OtherChargesTypes/AddNewOtherChargesTypes/' + Name + '/' + Description, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
}
