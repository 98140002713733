import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Seasons } from '../models/Seasons';

@Injectable({
  providedIn: 'root'
})
export class SeasonsService {

  public httpOptions: {};

  constructor(private http: HttpClient) { 
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  
  GetAllSeasons(): Observable<any> {
    return this.http.get('/api/Seasons/GetAllSeasons', this.httpOptions);
  }  

  SaveSeasons(seasons: Seasons) {
    return this.http.post('/api/Seasons/SaveSeasons', seasons, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  UpdateSeasons(seasons: Seasons) {
    return this.http.post('/api/Seasons/UpdateSeasons', seasons, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  DeleteSeasons(seasons) {
    return this.http.post('/api/Seasons/DeleteSeasons', seasons, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  GetSeasonsDetail(seasons){
    return this.http.post('/api/Seasons/GetSeasonsDetail', seasons, this.httpOptions)
    .pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));
  }

}
