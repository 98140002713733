<div class=" header bg-danger">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">
          <h6 class=" h2 text-white d-inline-block mb-0">Product</h6>
          <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
            <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> Multipack </a>
              </li>
              <li aria-current="page" class=" breadcrumb-item active">Multipack Product</li>
            </ol>
          </nav>
        </div>

        <div class=" col-lg-6 col-5 text-right">
          <button class=" btn btn-sm btn-neutral" type="button" label="New" (click)="AddnewProducts()" pButton></button>
          <!-- <button class=" btn btn-sm btn-neutral" type="button" label="Show Supplier" (click)="showAllSuppliers()" pButton></button> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class=" col-lg-12 card-wrapper" style="margin-top: 10px;">
  <div class=" card">
    <div class=" card-header">
      <h3 class=" mb-0">Multipack Products</h3>
    </div>

    <div class=" card-body">
      <p-table #dt1 [value]="MultipackProducts" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[25,50,100]" [loading]="loading" styleClass="p-datatable-MultipackProducts"
               [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
               [globalFilterFields]="['Name','BarCode','Label_SupplierName','SupplierProductCode']">
        <ng-template pTemplate="caption">
          <div class=" row">
            <div class=" col-lg-6 col-6">
              <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                      (click)="clear(dt1)"></button>
            </div>
            <div class=" col-lg-6 col-6">
              <input class="inputSearch" pInputText type="search" (input)="dt1.filterGlobal($event.target.value, 'contains')" [(ngModel)]="inputSearch"
                     placeholder="Search keyword" />
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th hidden>ID</th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Product Name
                <!--<p-columnFilter type="text" field="Name" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Supplier productCode
                <!--<p-columnFilter type="text" field="SupplierProductCode" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Supplier Name
                <!--<p-columnFilter type="text" field="SupplierName" display="menu"></p-columnFilter>-->
              </div>
            </th>

            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                BarCode
                <!--<p-columnFilter type="text" field="BarCode" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th hidden>Warehouse Quantity</th>
            <th style="width: 8rem; text-align: center">Actions</th>
          </tr>


        </ng-template>
        <ng-template pTemplate="body" let-MultipackProducts let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="MultipackProducts">
            <td hidden pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="MultipackProducts.ID">
                </ng-template>
                <ng-template pTemplate="output">
                  {{MultipackProducts.ID}}
                </ng-template>
              </p-cellEditor>
            </td>

            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="MultipackProducts.Name" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{MultipackProducts.Name}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="MultipackProducts.SupplierProductCode" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{MultipackProducts.SupplierProductCode}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="MultipackProducts.Label_SupplierName" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{MultipackProducts.Label_SupplierName}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="MultipackProducts.BarCode" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{MultipackProducts.BarCode}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td hidden>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="MultipackProducts.Warehouse" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{MultipackProducts.Warehouse}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td style="text-align:center">
              <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                      (click)="onRowEditInit(MultipackProducts)" class="p-button-rounded p-button-text"></button>
              <!--<button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(suppliers)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>-->
              <!-- <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(suppliers, ri)" class="p-button-rounded p-button-text p-button-danger"></button> -->
            </td>
          </tr>

        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">No customers found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog header="Multipack Products" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '950px',left:'130px'}" [(visible)]="showDialog">
  <form [formGroup]="multipackForm">
    <div class="form-group row">
      <div class="col-md-2">
        <h3>Multipack Name </h3>
      </div>
      <div class="col-md-10">
        <span class="p-float-label">
          <input id="inputtext-left" [style]="{width: '100%'}" value="ID" type="text" disabled formControlName="Name" readonly="readonly" pInputText>
        </span>
      </div>

    </div>

    <div hidden class="form-group row">
      <span>
        <input id="ParentProductID" disabled type="text" disabled formControlName="ParentProductID"
               pInputText>
      </span>
    </div>

    <div class="form-group row">
      <div class="col-md-4">
        <span class="p-float-label">
          <p-dropdown [autoDisplayFirst]="false" [style]="{'width':'100%'}" styleClass="primeng-dropdown"
                      [(ngModel)]="selectSupplier" [options]="Suppliers" [virtualScroll]="true" [filter]="true" optionLabel="Name"
                      optionValue="ID" formControlName="SupplierID" (onChange)="onChangeSupplier(selectSupplier)">
          </p-dropdown>
          <label for="Supplier">Select Supplier</label>

        </span>

      </div>
      <div class="col-md-4">
        <span class="p-float-label">
          <p-dropdown [autoDisplayFirst]="false" [style]="{'width':'100%'}" styleClass="primeng-dropdown"
                      [(ngModel)]="selectProduct" [options]="Product" [virtualScroll]="true" [filter]="true" optionLabel="Name"
                      optionValue="ID" formControlName="ChildProductID" (onChange)="onChangeChilpProduct(selectProduct)">
          </p-dropdown>
          <label for="Product">Select Product</label>

        </span>
      </div>

      <div class="col-md-4">
        <span class="p-float-label">
          <span class="p-fluid">
            <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="productVariations" 
                        [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                        optionLabel="Name" optionValue="ID" formControlName="ChildProductVariationID"
                        [disabled]="IsDisabledProductVariations">
            </p-dropdown>
            <label for="inputtext-left">Select Product Variation</label>
          </span>
        </span>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-4">
        <span class="p-float-label">
          <input id="Quantity" (keypress)="numberOnly($event)" [style]="{'width':'100%'}" type="text"
                 formControlName="Quantity" pInputText>
          <label for="float-input">Quantity</label>
        </span>
      </div>
      <div class="col-md-4">
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <div class="form-group row" *ngIf="addBtn">
          <div class="col-md-4">
            <button type="button" pButton (click)="addMutipackProduct()" label="Add"></button>
            <label ng-model="successMessage" ng-bind="successMessage" class="success">{{successMessage}}</label>
            <label ng-model="errorMessage" ng-bind="errorMessage" class="error">{{errorMessage}}</label>
          </div>
        </div>
        <div class="form-group row" *ngIf="updateBtn">
          <div class="col-md-4">
            <button type="button" pButton (click)="updateMutipackProduct()" label="Update"></button>
            <label ng-model="successMessage" ng-bind="successMessage" class="success">{{successMessage}}</label>
            <label ng-model="errorMessage" ng-bind="errorMessage" class="error">{{errorMessage}}</label>

          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="form-group row">
    <div class="flexcolumn container">
      <p-table #dt2 [value]="ChildProducts" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[25,50,100]" [loading]="loading" styleClass="p-datatable-ChildProducts" [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [globalFilterFields]="['Label_SupplierName','Label_ChildProductName','Label_SupplierProductCode','Label_ChildProductVariationName']">
        <ng-template pTemplate="caption">
          <div class=" row">
            <div class=" col-lg-6 col-6">
              <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt2)"></button>
            </div>
            <div class=" col-lg-6 col-6">
              <input class="inputSearch" pInputText type="search" (input)="dt2.filterGlobal($event.target.value,'contains')"
                     placeholder="Search keyword" [(ngModel)]="SearchMultipack" />
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th hidden>ID</th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Supplier
                <!--<p-columnFilter type="text" field="Label_SupplierName" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Product
                <!--<p-columnFilter type="text" field="Label_ChildProductName" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Product variation
                <!--<p-columnFilter type="text" field="Label_ChildProductVariationName" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Product Code
                <!--<p-columnFilter type="text" field="Label_SupplierProductCode" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th>
              <div class="p-d-flex p-jc-between p-ai-center">
                Quantity In Pack
                <!--<p-columnFilter type="text" field="Quantity" display="menu"></p-columnFilter>-->
              </div>
            </th>
            <th style="width: 8rem; text-align: center">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-ChildProducts let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="ChildProducts">
            <td hidden pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="ChildProducts.ID">
                </ng-template>
                <ng-template pTemplate="output">
                  {{ChildProducts.ID}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="ChildProducts.Label_SupplierName">
                </ng-template>
                <ng-template pTemplate="output">
                  {{ChildProducts.Label_SupplierName}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="ChildProducts.Label_ChildProductName" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ChildProducts.Label_ChildProductName}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="ChildProducts.Label_ChildProductVariationName" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ChildProducts.Label_ChildProductVariationName}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="ChildProducts.Label_SupplierProductCode" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ChildProducts.Label_SupplierProductCode}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText type="text" [(ngModel)]="ChildProducts.Quantity" required>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ChildProducts.Quantity}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td style="text-align:center">
              <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                      (click)="onRowEditInitMutipack(ChildProducts)" class="p-button-rounded p-button-text"></button>
              <p-toast></p-toast>
              <p-confirmPopup></p-confirmPopup>
              <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                      (click)="onRowEditCancel(ChildProducts, ri)"
                      class="p-button-rounded p-button-text p-button-danger"></button>

            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>
