<div class=" header bg-danger">
  <div class=" container-fluid">
    <div class=" header-body">
      <div class=" row align-items-center py-4">
        <div class=" col-lg-6 col-7">
          <h6 class=" h2 text-white d-inline-block mb-0">Pricing</h6>
          <nav aria-label="breadcrumb" class=" d-none d-md-inline-block ml-md-4">
            <ol class=" breadcrumb breadcrumb-links breadcrumb-dark">
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> <i class=" fas fa-home"> </i> </a>
              </li>
              <li class=" breadcrumb-item">
                <a href="javascript:void(0)"> Pending Delivery Details </a>
              </li>
            </ol>
          </nav>
        </div>

        <!-- <div class=" col-lg-6 col-5 text-right">
          <button class=" btn btn-sm btn-neutral" type="button" label="Pending Delivery Details" (click)="reload()" pButton></button> -->
          <!--<button class=" btn btn-sm btn-neutral" type="button" label="Show Invoice" (click)="showAllInvoices()" pButton></button>-->
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
<div class="deskContent">
  <div class=" col-lg-12 card-wrapper" style="margin-top: 10px;">
    <div class=" card">
      <div class="card-header d-flex align-items-center justify-content-between">
        <h3 class=" mb-0">Pending Delivery Details</h3>
        <button class="btn-neutral float-right" type="button" (click)="reload()" pTooltip="Reload" tooltipPosition="left"><i class="fas fa-redo-alt"></i></button>
      </div>
        <div class="card-body">
              <p-table #dt2 [value]="InvoiceDeliverableItems" dataKey="SupplierID" [rows]="25" [showCurrentPageReport]="true"
                       [rowsPerPageOptions]="[25,50,100]" [loading]="loading" styleClass="p-datatable-InvoiceDeliverableItems" [paginator]="true"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
                       [globalFilterFields]="['SupplierName','SupplierInvoiceID']">
                       <ng-template pTemplate="caption"> 
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group" style="color: #327ab7;">
                                <b>Delivery Count: <span>{{deliveryDetailsCount}}</span></b>
                              </div>
                            </div>
                              <div *ngFor="let category of categories" class="p-field-checkbox mx-3">
                                  <p-radioButton [inputId]="category.key" name="category" [value]="category" [(ngModel)]="selectedCategory" (click)="onChangeCategory(selectedCategory)"></p-radioButton>
                                  <label [for]="category.key">{{category.name}}</label>
                              </div>
                              <button class="d-flex ml-auto" type="button" pButton icon="fas fa-plus-circle" (click)="addNewDelivery()" label="New Delivery">
                              </button>
                          </div><br/>
                          <div class="row">
                              <div class="col-lg-6">
                                  <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt2)"></button>
                              </div>
                              <div class="col-lg-6">
                                <input class="inputSearch" pInputText type="search" (input)="dt2.filterGlobal($event.target.value, 'contains')" placeholder="Search by Invoice Number or Supplier in {{selectedCategory['name']}} Delivery Details" [(ngModel)]="inputSearch" />
                              </div>
                          </div>
                        </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th hidden>ID</th>
                    <th>
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Supplier
                        <p-columnFilter type="text" field="Supplier" display="menu"></p-columnFilter>
                      </div>
                    </th>
                    <th>
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Invoice Date
                        <p-columnFilter type="text" field="InvoiceDate" display="menu"></p-columnFilter>
                      </div>
                    </th>
                    <th colspan="3">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Invoice Items
                        <p-columnFilter type="text" field="InvoiceItems" display="menu"></p-columnFilter>
                      </div>
                    </th>
                    <th>
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Action
                        <p-columnFilter type="text" field="Action" display="menu"></p-columnFilter>
                      </div>
                    </th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" *ngIf="pendingDetails.length == 0">
                  <tr>
                    <td colspan="4">No Data Found!</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-InvoiceDeliverableItems let-editing="editing" let-ri="rowIndex">
                  <tr [pEditableRow]="InvoiceDeliverableItems">
                    <td hidden pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="InvoiceDeliverableItems.SupplierID">
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{InvoiceDeliverableItems.SupplierID}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                           <input pInputText type="text" [(ngModel)]="InvoiceDeliverableItems.Supplier" required> 
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{InvoiceDeliverableItems.SupplierName}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" [(ngModel)]="InvoiceDeliverableItems.InvoiceDate" required>
                        </ng-template>
                        <ng-template pTemplate="output">
                           {{InvoiceDeliverableItems.InvoiceDate | date: 'dd-MMM-yyyy'}}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td colspan="3">
                      <p-table [value]="InvoiceDeliverableItems.InvoiceDeliverableLineItems">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Supplier Code</th>
                                <th>Product Title</th>
                                <th>Quantity</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" *ngIf="InvoiceDeliverableItems.InvoiceDeliverableLineItems.length == 0">
                          <tr>
                            <td colspan="3">No Data Found!</td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-InvoiceDeliverableLineItem>
                            <tr>
                                <td>{{InvoiceDeliverableLineItem.SupplierCode}}</td>
                                <td>{{InvoiceDeliverableLineItem.Name}}</td>
                                <td>{{InvoiceDeliverableLineItem.Quantity}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                      <!-- <p-cellEditor>
                        <ng-template pTemplate="input">
                           <input pInputText type="text" [(ngModel)]="prodDetails" required> 
                        </ng-template>
                        <ng-template pTemplate="output">
                          <p-table [value]="pendingDetails">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Supplier Code</th>
                                    <th>Product Title</th>
                                    <th>Quantity</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-pendingDetail>
                                <tr>
                                    <td>{{pendingDetail.SupplierProductCode}}</td>
                                    <td>{{pendingDetail.Name}}</td>
                                    <td>{{pendingDetail.Quantity}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        </ng-template>
                      </p-cellEditor> -->
                    </td>
                    <td style="text-align:center">
                      <button *ngIf="InvoiceDeliverableItems.IsResolved == true" pButton pRipple type="button" pInitEditableRow label="Resolved" class="p-button-success" (click)="receiveDeliverableLineItem(InvoiceDeliverableItems)"></button>
                      <button *ngIf="InvoiceDeliverableItems.IsResolved == false" pButton pRipple type="button" pInitEditableRow label="Receive" (click)="receiveDeliverableLineItem(InvoiceDeliverableItems)"></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table><br/>
              <p *ngIf="errorMessage" class="error">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
  <p-dialog class="new-pending" id="NewDelivery" header="New Delivery Details" [(visible)]="newDelivery" [style]="{width: '50vw'}" [baseZIndex]="10000" [modal]="true"
              [draggable]="false" [resizable]="false">
      <div class="offset-lg-11 justify-content-end">
      <button  class="btn-neutral btn-refresh" type="button" pButton (click)="refresh()" icon="fas fa-redo-alt">
      </button></div>    
      <form [formGroup]="NewDeliveryForm">
      <div class="row">
          <div class="offset-lg-6 col-lg-6">
              <label class="p-mb-3 p-text-bold" for="Number">Delivery Number</label>
              <input id="Number" type="number" name="DeliveryNumber" pInputText [(ngModel)]="InvoiceNewDeliverableLineItem.DeliverableNumber"
              (keyup)="getDetailsByDeliverableNum(InvoiceNewDeliverableLineItem.DeliverableNumber)"
              class="form-control" formControlName="DeliverableNumber" autocomplete="off" required />
              <p *ngIf="errorMessageDeliverableNumber" class="error">{{ errorMessageDeliverableNumber }}</p>
          </div>
      </div><br/>
      <p-fieldset legend="Delivery Details">
          <div class="row align-items-start">
              <div class="col-lg-5">
                  <label class="p-mb-3 p-text-bold" for="Supplier">Supplier</label>
                  <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="suppliers" [(ngModel)]="InvoiceNewDeliverableLineItem.SupplierID" placeholder="Select Supplier" (onChange)="onSelectSupplierChange($event)"
                              [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false" 
                              optionLabel="Name" optionValue="ID" formControlName="SupplierID" required>
                  </p-dropdown>
                  <p *ngIf="errorMessageSuppliers" class="error">{{ errorMessageSuppliers }}</p>
              </div>
              <div class="col-lg-1 pt-3">
                  <p-button styleClass="float-right mt-4 " (click)="showAddSupplier()" icon="fa fa-plus" pTooltip="Add new supplier"></p-button>
              </div>
              <div class="col-lg-6">
                  <label class="p-mb-3 p-text-bold" for="Date">Date</label><br />
                  <input id="SupplierDate" type="date" name="SupplierDate" pInputText [(ngModel)]="InvoiceNewDeliverableLineItem.InvoiceDate"
                         (keyup)="checkInvoiceDate(InvoiceNewDeliverableLineItem.InvoiceDate)" class="form-control" formControlName="SupplierDate"
                         required />
                  <!-- <p-calendar  dateFormat="dd-mm-yy" [locale]="en-GB" [(ngModel)]="SupplierDate"
                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2050" formControlName="SupplierDate"></p-calendar>  -->
                  <p *ngIf="errorMessageInvoiceDate" class="error">{{ errorMessageInvoiceDate }}</p>
              </div>
          </div><br/>
          <div class="row">
            <div class="col-lg-10">
              <p *ngIf="successMessage" class="success p-text-bold">{{successMessage}}</p>
            </div>
              <div class="col-lg-2">
              <button type="button" pButton (click)="addDelivery()" [disabled]="addDeliveryDisable" icon="fas fa-plus-circle" label="Add Delivery">
                  
               </button>
              </div>
          </div>
      </p-fieldset><br/>
      <p-fieldset legend="Line Item">
          <div class="row align-items-start">
              <div class="col-lg-3" [ngClass]="{'col-lg-4': productVariations.length===0}">
                  <label class="p-mb-3 p-text-bold" for="ProductTitle">Product Title</label>
                  <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="product" placeholder="Select Product" (onChange)="onSelectProductChange($event)"
                              [(ngModel)]="InvoiceNewDeliverableLineItemsDetails.ProductID" [virtualScroll]="true" [filter]="true" [autoDisplayFirst]="false"
                              optionLabel="Name" optionValue="ID" formControlName="SelectedProductID" required>
                  </p-dropdown>
                  <p *ngIf="errorMessageSelectedProduct" class="error">{{ errorMessageSelectedProduct }}</p>
              </div>
              <div class="col-lg-1 pt-3">
                  <p-button styleClass="float-right mt-4 " (click)="showAddProduct()" icon="fa fa-plus" pTooltip="Add new product"></p-button>
              </div>
              <div class="col-lg-3" *ngIf="productVariations.length>0">
                <label class="p-mb-3 p-text-bold" for="ProductVariation">Product Variation</label>
                    <p-dropdown inputId="dropdown" styleClass="primeng-dropdown" [options]="productVariations" [virtualScroll]="true" [filter]="true"
                                [(ngModel)]="InvoiceNewDeliverableLineItemsDetails.ProductVariationID" (onChange)="onSelectProductVariation($event)"
                                optionLabel="Name" optionValue="ID" formControlName="ProductVariation" [autoDisplayFirst]="false" placeholder="Select Product Variation">
                    </p-dropdown>
              </div>
              <div class="col-lg-3" [ngClass]="{'col-lg-4': productVariations.length===0}">
                  <label class="p-mb-3 p-text-bold" for="ProductCode">Product Code</label><br />
                  <input id="input" type="text" name="ProductCode" pInputText [(ngModel)]="InvoiceNewDeliverableLineItemsDetails.SupplierCode" (keyup)="onChangeProductCode(InvoiceNewDeliverableLineItemsDetails.SupplierCode)" class="form-control" formControlName="ProductCode" required
                         autocomplete="off" />
                  <p *ngIf="errorMessageProductCode" class="error">{{ errorMessageProductCode }}</p>
              </div>
              <div class="col-lg-2" [ngClass]="{'col-lg-3': productVariations.length===0}">
                  <label class="p-mb-3 p-text-bold" for="Quantity">Quantity</label><br />
                  <input id="input" type="number" pInputText [(ngModel)]="InvoiceNewDeliverableLineItemsDetails.Quantity" step="0"
                         class="form-control" formControlName="ProductQuantity"
                         required />
                  <p *ngIf="errorMessageQuantity" class="error">{{ errorMessageQuantity }}</p>
              </div>
          </div><br/>
          <div class="row">
            <div class="col-lg-5">
            <p-checkbox class="mx-4 p-mb-3 p-text-bold" (click)="onChangeCheckValue($event)" name="group1" value="Is Received" label="Is Received" [(ngModel)]="selectedCheckValues" inputId="IR" formControlName="selectedCheckValue"></p-checkbox>
            <p-checkbox class="mx-4 p-mb-3 p-text-bold" (click)="onChangeCheckValue($event)" name="group1" value="Is Exception" label="Is Exception" [(ngModel)]="selectedCheckValues" inputId="IE" formControlName="selectedCheckValue"></p-checkbox>
            <p *ngIf="errorMessageIsReceived" class="error">{{ errorMessageIsReceived }}</p>
          </div>
              <!-- <div *ngFor="let values of checkValues" class="p-field-checkbox mx-4">
                  <p-checkbox name="group2" value="values" [value]="values" [(ngModel)]="selectedCheckValues" (onChange)="onChangeCheckValue(selectedCheckValues)"  [inputId]="values.key"  [formControl]="NewDeliveryForm.controls['selectedCheckValue']" checkboxIcon="pi pi-check"></p-checkbox>
                  <label [for]="values.key">{{values.name}}</label>
              </div> -->
          </div><br/>
          <div class="row" *ngIf="viewOtherQuantity">
              <div class="col-lg-4">
                  <label class="p-mb-3 p-text-bold" for="ReceivedQuantity">Received Quantity</label><br />
                  <input id="input" type="number" pInputText [(ngModel)]="InvoiceNewDeliverableLineItemsDetails.ReceivedQuantity" step="0"
                         class="form-control" formControlName="ProductReceivedQuantity"
                         required />
                  <p *ngIf="errorMessageReceivedQuantity" class="error">{{ errorMessageReceivedQuantity }}</p>
                  <p *ngIf="errorMessageExceptionAndReceivedQuantity" class="error">{{ errorMessageExceptionAndReceivedQuantity }}</p>
              </div>
              <div class="col-lg-4">
                  <label class="p-mb-3 p-text-bold" for="ExceptionQuantity">Exception Quantity</label><br />
                  <input id="input" type="number" pInputText [(ngModel)]="InvoiceNewDeliverableLineItemsDetails.ExceptionQuantity" step="0"
                         class="form-control" formControlName="ProductExceptionQuantity"
                         required />
                  <p *ngIf="errorMessageExceptionQuantity" class="error">{{ errorMessageExceptionQuantity }}</p>
                  <p *ngIf="errorMessageExceptionAndReceivedQuantity" class="error">{{ errorMessageExceptionAndReceivedQuantity }}</p>
              </div>
              <div class="col-lg-4">
                  <label class="p-mb-3 p-text-bold" for="Comments">Comments</label><br />
                  <textarea rows="1" pInputTextarea placeholder="Comments" formControlName="Comments" [(ngModel)]="InvoiceNewDeliverableLineItemsDetails.Comments" class="form-control"></textarea>
                  <p *ngIf="errorMessageComments" class="error">{{ errorMessageComments }}</p>
              </div>
          </div><br/>
          <div class="row">
            <div class="col-lg-10">
              <p *ngIf="successMessageNewDelivery" class="success p-text-bold">{{successMessageNewDelivery}}</p>
            </div>
            <div class="col-lg-2">
            <button type="button" pButton (click)="addNewDeliveryDetails()" [disabled]="addNewDeliveryDetDisable || DeliverySubmit" icon="fas fa-plus-circle" label="Add Delivery">  
             </button>
            </div>
        </div><br/>
        <p-table #dt2 [value]="newDeliveryDetails" dataKey="ID" [loading]="loading" styleClass="p-datatable-newDeliveryDetails">

          <ng-template pTemplate="header">
            <tr>
              <th hidden>ID</th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Product Code
                  <p-columnFilter type="text" field="ProductCode" display="menu"></p-columnFilter>
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Product Title
                  <p-columnFilter type="text" field="ProductTitle" display="menu"></p-columnFilter>
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Quantity
                  <p-columnFilter type="text" field="Quantity" display="menu"></p-columnFilter>
                </div>
              </th>
              <th>
                <div class="p-d-flex p-jc-between p-ai-center">
                  Action
                  <p-columnFilter type="text" field="Action" display="menu"></p-columnFilter>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-newDeliveryDetails let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="newDeliveryDetails" *ngIf="newDeliveryDetails.SupplierProductCode!=null && newDeliveryDetails.ProductName!=null">
              <td hidden pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="newDeliveryDetails.ID">
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{newDeliveryDetails.ID}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="newDeliveryDetails.SupplierProductCode" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{newDeliveryDetails.SupplierProductCode}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="newDeliveryDetails.ProductName" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{newDeliveryDetails.ProductName}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="newDeliveryDetails.Quantity" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{newDeliveryDetails.Quantity}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align:center" *ngIf="DeliverySubmit==false">
                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(newDeliveryDetails)" class="p-button-rounded p-button-text"></button>
                <p-toast></p-toast>
                <p-confirmPopup [baseZIndex]="10000"></p-confirmPopup>
                <button *ngIf="!editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(newDeliveryDetails, ri, $event)" class="p-button-rounded p-button-text p-button-danger" ></button>
              </td>
              <td style="text-align:center" *ngIf="DeliverySubmit==true">
                <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-list" class="p-button-rounded p-button-text" (click)="onRowEditInit(newDeliveryDetails)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table><br/>
        <div class="row">
          <div class="col-lg-4">
            <p *ngIf="successMessageSubmitDelivery" class="success p-text-bold">{{successMessageSubmitDelivery}}</p>
            <p *ngIf="errorMessageNewDelivery" class="error p-text-bold">{{errorMessageNewDelivery}}</p>
            <p *ngIf="infoMessageSubmitted" class="text-info p-text-bold">{{infoMessageSubmitted}}</p>
          </div>
          <div class="offset-lg-6 col-lg-2">
          <button type="button" pButton (click)="deliverySubmit('top')" label="Submit" [disabled]="DeliverySubmit"> </button>
            <p-confirmDialog [style]="{width: '50vw'}" key="positionDialog" [position]="position" [baseZIndex]="10000" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
            <p-toast></p-toast> 
          </div>
      </div><br/>
      <div class="row">
        <span class ="col-lg-12 text-info"><b>Note: </b> If Allocation fails here then, Once you map the delivery to the invoice, again it will call via recieving delivery.</span>
    </div>
    <div class="row">
      <b>Allocation Status</b>
      <div class="row" *ngFor="let result of allocationResponseResult; let i = index">
          <div class="col-lg-12" *ngIf="result.Status == 2">
              <span class="error">{{result.Message + '-' + result.ProductName}}</span>
          </div>
          <div class="col-lg-12" *ngIf="result.Status == 200">
              <span class="success">{{result.Message + '-' + result.ProductName}}</span>
          </div>
      </div>
  </div>
      </p-fieldset><br/>
      <div class="row">
        <div class="offset-lg-11">
          <button type="button" pButton (click)="closeDialog()" label="Close">  
           </button>
          </div>
      </div>
  </form>
  </p-dialog>

  <app-supplier [isShownSupplierForm]="isShownSupplier" [header]="false" [grid]="false" (isSupVal)="exitSup($event)"></app-supplier>
  <app-product class="new-prod" [isShownProductForm]="isShownProduct" [header]="false" [suppliers]="suppliers" [selectedSupID]="SelectedSupplierID" [desk]="false" [phone]="false" (isProdVal)="exitProd($event)" ></app-product>
  