import { Injectable } from '@angular/core';
import { SharedModule } from '../shared.module';
import { Observable } from 'rxjs';
import { Login } from '../models/login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Http, Response } from '@angular/http';
import { Register } from '../models/register';
import { map } from 'rxjs/operators';
import { Forgetpassword } from '../models/forgetpassword';
import { ResetPassword } from '../models/resetpassword';
import { ResendLink } from '../models/resendlink';
import { Contact } from '../models/contact';

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  Userlogin(login: Login): Observable<object> {
    return this.http.post('/api/Account/Login', login)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

register(user: Register) {
  return this.http.post('/api/Account/Register', user)
    .pipe(
      map((res: Response) => {
        let body = res;
        return body
          || {};
      }));
}

forgetpassword(forgetpassword: Forgetpassword) {
  return this.http.post('/api/Account/ForgetPassword', forgetpassword, this.httpOptions)
    .pipe(
      map((res: Response) => {
        let body = res;
        return body
          || {};
      }));
}

resetpassword(resetpassword: ResetPassword) {
  return this.http.post('/api/Account/ResetPassword', resetpassword, this.httpOptions)
    .pipe(
      map((res: Response) => {
        let body = res;
        return body
          || {};
      }));
}

ResendConfirmationLink(email: ResendLink) {
  return this.http.post('/api/Account/ResendConfirmationLink', email)
    .pipe(
      map((res: Response) => {
        let body = res;
        return body
          || {};
      }));
}

ContactDetails(contact: Contact) {
  return this.http.post('/api/Account/ContactDetails', contact)
    .pipe(
      map((res: Response) => {
        let body = res;
        return body
          || {};
      }));
}
}
