<div class="container-fluid">
  <footer class="footer">
    <!--<div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }}
          Retail Helper
        </div>
      </div>
      <div class="col-xl-6">
        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
          <li class="nav-item">
            <div class="copyright text-center text-xl-left text-muted">
              <span class="nav-link">Contact Us </span>
            </div>
          </li>
          <li class="nav-item">
            <div class="copyright text-center text-xl-left text-muted">
              <span class="nav-link"> About Us</span>
            </div>
          </li>
        </ul>
      </div>
    </div>-->
  </footer>
</div>
