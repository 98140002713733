import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceDeliverable } from '../models/invoice-deliverable.model';
import { InvoiceNewDeliverableLineItems, InvoiceNewDeliverableLineItemsDetails } from '../models/invoice-new-deliverable-line-items.model';

@Injectable({
  providedIn: 'root'
})
export class PendingDeliveryDetailsService {
  public httpOptions: {};
  constructor(private http: HttpClient) { 
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getAllPendingDetails(): Observable<any> {
    return this.http.get('/api/PendingDeliveryDetails/getDeliverablePendingInv', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  addNewDelivery(invoicenewdeliverylineitems: InvoiceNewDeliverableLineItems): Observable<any> {
    return this.http.post('/api/PendingDeliveryDetails/addNewDelivery',invoicenewdeliverylineitems, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  addDeliverable(invoicedeliverablelineitem: InvoiceDeliverable): Observable<any> {
    return this.http.post('/api/PendingDeliveryDetails/addToDeliverable', invoicedeliverablelineitem, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  addDeliverableDetails(invoicedeliverablelineitem: InvoiceDeliverable): Observable<any> {
    return this.http.post('/api/PendingDeliveryDetails/addToDeliverableDetails',invoicedeliverablelineitem, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }


  getDeliverableNumber(): Observable<any> {
    return this.http.get('/api/PendingDeliveryDetails/getDeliverableNum', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getDetailsByDeliverableNum(DeliverableNumber): Observable<any> {
    return this.http.get('/api/PendingDeliveryDetails/getDetailsByDeliverableNum/'+DeliverableNumber, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  addNewDeliveryDetails(invoicenewdeliverylineitemsdetails:InvoiceNewDeliverableLineItemsDetails): Observable<any> {
    return this.http.post('/api/PendingDeliveryDetails/addNewDeliveryDetails', invoicenewdeliverylineitemsdetails, this.httpOptions ).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));
  }

  deleteDeliverableDetails(deliverableNum: number, productId: number, productVariationId: number): Observable<any> {
    return this.http.get('/api/PendingDeliveryDetails/deleteDeliverableDetails/'+deliverableNum+'/'+productId+'/'+productVariationId, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getDeliverablePendingInvoices(): Observable<any> {
    return this.http.get('/api/PendingDeliveryDetails/getDeliverablePendingInv', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }
  
  getDeliverableBySuppplierInvoice(supplierID,productID,productVariationID,invoicenumber): Observable<any> {
    return this.http.get('/api/PendingDeliveryDetails/getDeliverableBySuppplierInvoice/'+supplierID+'/'+productID+'/'+productVariationID+'/'+invoicenumber, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getInvoiceDetailsToMap(supplierID,invoicenumber): Observable<any> {
    return this.http.get('/api/PendingDeliveryDetails/getInvoiceDetailsToMap/'+supplierID+'/'+invoicenumber, this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

  getReceivedInvoices(): Observable<any> {
    return this.http.get('/api/PendingDeliveryDetails/getReceivedInv', this.httpOptions).pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));;
  }

}
