import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './shared/components/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LayoutAdminComponent } from './layout-admin/layout-admin.component';
import { PagenotfoundComponent } from './shared/components/pagenotfound/pagenotfound.component';
import { ForgetPasswordComponent } from './shared/components/forget-password/forget-password.component';
import { UserRegisterComponent } from './shared/components/user-register/user-register.component';
import { ThankYouMessagesComponent } from './shared/components/thank-you-messages/thank-you-messages.component';
import { ResetPasswordComponent } from './shared/components/resetpassword/resetpassword.component';
import { ResendVerificationlinkComponent } from './shared/components/resend-verificationlink/resend-verificationlink.component';
import { ContactFormComponent } from './shared/components/contact-form/contact-form.component';


const routes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  { path: 'Login', component: LoginComponent },
  { path: 'Home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'ForgetPassword', component: ForgetPasswordComponent },
  { path: 'register', component: UserRegisterComponent },
  { path: 'Thankyou', component: ThankYouMessagesComponent },
  { path: 'ContactUs', component: ContactFormComponent },
  { path: 'ResendLink', component: ResendVerificationlinkComponent },
  { path: 'ResetPassword', component: ResetPasswordComponent },
  {
    path: "AdminDashboard",
    component: LayoutAdminComponent,
    children: [
      //{
      //  path: "components",
      //  loadChildren: "./modules/components/components.module#ComponentsModule"
      //},
      {
        path: "Products",
        loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule)
      },
      {
        path: "Warehouse",
        loadChildren: () => import('./modules/warehouse/warehouse.module').then(m => m.WarehouseModule)
      },
      {
        path: "Sales",
        loadChildren: () => import('./modules/sales/sales.module').then(m => m.SalesModule)
      },
      {
        path: "Pricing",
        loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule)
      },
      {
        path: "Research",
        loadChildren: () => import('./modules/research/research.module').then(m => m.ResearchModule)
      }
    ]
  },
  { path: '**', component: PagenotfoundComponent },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
