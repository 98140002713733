import { Component, OnInit } from '@angular/core';
import { Register } from '../../models/register';
import { LoginService } from '../../service/login.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.css'],
  providers: [LoginService],
  preserveWhitespaces: true
})
export class UserRegisterComponent implements OnInit {
  public register: Register = new Register("", "", "", "", "");
  public validationerror: string;
  public validationsuccess: string;
  public loading: boolean;
  public loggedIn: boolean;
  public focus: boolean;
  public focus1: boolean;
  public focus2: boolean;

  constructor(
    private loginService: LoginService,
    private router: Router) { }


  ngOnInit() {
    //this.loggedIn = localStorage.getItem("access_token") ? true : false;
    
      this.router.navigate(["/register"]);
    
  }

  public UserRegister() {
    this.loading = true;
    this.loginService.register(this.register)
      .subscribe(
        data => {
          this.validationsuccess = "Thank you for Signing Up with Retail Helper, registration need to be approved by the system admin";
          this.loading = false;
        },
        error => {
          this.loading = false;
          if (error.error.text != "") {
            this.loading = false;
            this.validationerror = error.error.text;
            setTimeout(() => {
              this.validationerror = "";
            }, 5000);
          }
          else if (error.status == 400) {
            if (JSON.parse(error._body)["Email"][0] == "Invalid Email") {
              this.loading = false;
              this.validationerror = "Please enter valid email address";
              setTimeout(() => {
                this.validationerror = "";
              }, 5000);
            }
          }
          else if (error.status == 401) {
            this.loading = false;
            this.validationerror = JSON.parse(error._body).message;
            setTimeout(() => {
              this.validationerror = "";
            }, 5000);
          }
          else if (error.status == 500) {
            this.loading = false;
            this.validationerror = "Internal Server Error";
            setTimeout(() => {
              this.validationerror = "";
            }, 5000);
          }
        });
  }
}
