import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from '../shared/guards/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoCacheHeadersInterceptor } from '../Cache-Interceptor';

import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/resetpassword/resetpassword.component';
import { ResendVerificationlinkComponent } from './components/resend-verificationlink/resend-verificationlink.component';
//Shared module
import {UserRegisterComponent} from './components/user-register/user-register.component';
import {LoginComponent} from './components/login/login.component';
import {ContactFormComponent} from './components/contact-form/contact-form.component';
import {PagenotfoundComponent} from './components/pagenotfound/pagenotfound.component';
import {ThankYouMessagesComponent} from './components/thank-you-messages/thank-you-messages.component';
//https://alligator.io/angular/providers-shared-modules/

/**Product dashboard */
import { ProductModule } from '../modules/product/product.module';
import {ProductRoutes} from '../modules/product/product.routing'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProductModule,
    RouterModule.forChild([
     // { path: '',  component: LoginComponent },
      { path: 'register', component: UserRegisterComponent },
      { path: 'ForgetPassword', component: ForgetPasswordComponent },
      { path: 'Login', component: LoginComponent },
      { path: 'ContactUs', component: ContactFormComponent },
      { path: 'ResendLink', component: ResendVerificationlinkComponent },
      { path: 'ResetPassword', component: ResetPasswordComponent },
      { path: 'Thankyou', component: ThankYouMessagesComponent },

      //{ path: '**', component: PagenotfoundComponent },
      


    ]),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
  providers: [
    AuthGuard,
    UserRegisterComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    }
  ],
  declarations: [ForgetPasswordComponent, ResetPasswordComponent, ResendVerificationlinkComponent,UserRegisterComponent,ForgetPasswordComponent,
    LoginComponent,ContactFormComponent,PagenotfoundComponent,ThankYouMessagesComponent, ]
})
export class SharedModule {
}
