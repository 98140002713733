export class InvoiceLineItems {
    constructor(
        public ID: number,
        public SupplierID: number,
        public SupplierInvoiceID: string,
        public InvoiceDate: string,
        public DiscountPercentage: any,
        public PerDiscountNetAmount: any,
        public TotalAmount: any,
        public VATTotal: any,
        
        public Label_SupplierName: string,
        public IsInvoiceSubmitted: boolean,
        public IsReceived: boolean,

        public IsDeleted: boolean,

        public InvoiceID: number,

        public ProductID: number,

        public SupplierProductCode: string,

        public ProductVariationID: number,
        public Quantity: any,
        public UnitPrice: any,
        public DiscountedUnitPrice: number,
        public VATTypeID: number,
        public ProductVatTypeID: number,
        public Label_ProductName: string,
        
        public Label_VATTypeName: string,
        public NetPrice: number,
        public VatPrice: number,
        public AdjustTotal: any,
        public Label_ProductVariationName: string,
        public CreatedDate: Date,
        public CreatedBy: string,
        public ModifiedDate: Date,
        public ModifiedBy: string,
        ){}
}
