import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '../../models/login';
import { LoginService } from '../../service/login.service';
import { ResendLink } from '../../models/resendlink';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})

export class LoginComponent implements OnInit {

  public login = new Login(null, null);
  public accesstoken: string;
  public expirytime: Date;
  public tokentype: string;
  public loggedIn: boolean;
  public resendtext: boolean;
  public validationerror: string;
  public resendlink = new ResendLink("");
  public loading: boolean;
  public focus: boolean;
  public focus1: boolean;
  constructor(
    private loginService: LoginService,
    private router: Router
  ) { }

  ngOnInit() {

    this.loggedIn = localStorage.getItem("access_token") ? true : false;
    if (this.loggedIn) {
      this.router.navigate(['/AdminDashboard']);
    }
    else {
      this.router.navigate(["/Login"]);
    }
  }

  public Userlogin() {

    this.loading = true;
    this.loginService.Userlogin(this.login)
      .subscribe(
        data => {
          this.loading = false;
          localStorage.setItem("userName", data["userName"]);
          localStorage.setItem("access_token", data["access_token"]);
          localStorage.setItem("expires_in", data["expires_in"]);
          localStorage.setItem("token_type", data["token_type"]);
          localStorage.setItem("userId", data["userId"]);
          localStorage.setItem("subscription", data["subscription"]);
          this.router.navigate(['/AdminDashboard']);
        },
        error => {
          console.log(error);
          if (error.status == 401) {
            this.loading = false;
            this.validationerror = "Email or Password is incorrect !";
            setTimeout(() => {
              this.validationerror = "";
            }, 6000);
          }
          else if (error.status == 403) {
            this.loading = false;
            this.resendtext = true;
            this.resendlink.email = this.login.email;
            this.validationerror = "Your email is not verified. We have sent you a verification email. Please click on the link there to verify your email address.";
            setTimeout(() => {
              this.validationerror = "";
            }, 12000);
          }
        });
  }

  public ResendLink() {
    this.router.navigate(["/ResendLink"]);
  }
}


