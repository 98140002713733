import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

//Import models
import { MultiPackProducts } from '../../models/MultiPackProducts';
import { ProductManagement } from '../../models/productmanagement';
//Import services
import { MultipackproductService } from '../../services/multipackproduct.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ProductvariationsService } from '../../../sales/services/productvariations.service';

//Import primeng
import { SelectItem } from 'primeng/api';
import { ScrollableView } from 'primeng/table';
import ResizeObserver from 'resize-observer-polyfill';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';

/** Hack: align header */
//ScrollableView.prototype.ngAfterViewChecked = function () {
//  if (!this.initialized && this.el.nativeElement.offsetParent) {
//    //this.alignScrollBar();
//    this.initialized = true;

//    new ResizeObserver(entries => {
//      //for (let entry of entries)
//      this.alignScrollBar();
//    }).observe(this.scrollBodyViewChild.nativeElement);
//  }
//};
@Component({
  selector: 'app-multipackcreation',
  templateUrl: './multipackcreation.component.html',
  styleUrls: ['./multipackcreation.component.css'],
  providers: [MultipackproductService, MessageService, ConfirmationService, PrimeNGConfig, ProductvariationsService],
  preserveWhitespaces: true
})
export class MultipackcreationComponent implements OnInit {
  public showDialog: boolean;
  multipackForm: FormGroup;
  Product: SelectItem[];
  MultipackProducts: SelectItem[];
  Suppliers: SelectItem[];
  selectSupplier: SelectItem;
  selectProduct: SelectItem;
  public ChildProducts: [];
  errorMessage = "";
  successMessage = "";
  //table binding
  inputSearch: string = "";
  public SearchMultipack = "";
  clonedProduct: { [s: string]: ProductManagement; } = {};
  clonedMultipack: { [s: string]: MultiPackProducts; } = {};
  //button
  updateBtn: boolean = false;
  addBtn: boolean = true;
  public loading: boolean;
  public productVariations = [];
  public IsDisabledProductVariations: boolean;

  constructor(private multipackProdService: MultipackproductService, private router: Router,
    private formBuilder: FormBuilder, private confirmationService: ConfirmationService, private primengConfig: PrimeNGConfig,
    private productvariationsService: ProductvariationsService
  ) { }

  ngOnInit(): void {
    this.IsDisabledProductVariations = true;
    this.primengConfig.ripple = true;
    this.errorMessage = "";
    this.successMessage = "";
    this.multipackForm = this.formBuilder.group({
      ID: [''],
      SupplierID: ['', Validators.required],
      ChildProductID: ['', Validators.required],
      Quantity: [''],
      Name: [''],
      ParentProductID: [''],
      ChildProductVariationID: ['']
    });



    this.getAllMultipackProducts();
  }
  get f() { return this.multipackForm.controls; }

  //GET method for Multipack product-table -start
  getAllMultipackProducts() {
    this.errorMessage = "";
    this.successMessage = "";
    this.loading = true;
    this.multipackProdService.getAllMultipackProduct()
      .subscribe(
        data => {
          this.MultipackProducts = data;
          this.loading = false;
        },
        error => {
          this.errorMessage = "Error while fetching Multipack Product data";
          this.loading = false;
          this.hideMessage();
          return;
        })
  }
  getAllSupplier() {
    this.multipackProdService.getAllSuppliers()
      .subscribe(
        data => {
          this.Suppliers = data;
        },
        error => {
          this.errorMessage = "Error while fetching supplier data";
          this.hideMessage();
          return;
        })
  }
  getMultipackProducts(ParentProductID) {
    this.multipackProdService.getMultipackProduct(ParentProductID)
      .subscribe(
        data => {
          this.ChildProducts = data;
          console.log(JSON.stringify(this.ChildProducts));
        },
        error => {
          this.errorMessage = "Error while fetching multipack product data";
          this.hideMessage();
          return;
        })
  }

  onChangeSupplier(ID) {
    this.productVariations = [];
    this.errorMessage = "";
    this.successMessage = "";
    this.multipackProdService.getProductList(ID)
      .subscribe(
        data => {
          this.Product = data;
        },
        error => {
          this.errorMessage = "Error while fetching all Product Lists data";
          this.hideMessage();
          return;
        })
  }

  onChangeChilpProduct(ID) {
    this.errorMessage = "";
    this.successMessage = "";
    this.productVariations = [];
    this.getAllProductVariationsOnProductID(ID);
  }

  //GET method for Multipack product-table -End

  //Edit the product screen -Start
  onRowEditInit(product: ProductManagement) {
    this.showDialog = true;
    this.getAllSupplier();
    this.errorMessage = "";
    this.successMessage = "";
    this.clonedProduct[product.ID] = { ...product };
    this.multipackForm.patchValue({
      ParentProductID: this.clonedProduct[product.ID].ID,
      Name: this.clonedProduct[product.ID].Name,
    });
    this.getMultipackProducts(this.multipackForm.value['ParentProductID']);
    this.clearmultipackForm();
    this.errorMessage = "";
    this.successMessage = "";
    this.updateBtn = false;
    this.addBtn = true;
  }
  //Edit the product screen -End

  //Edit the Multipack product screen -Start

  onRowEditInitMutipack(multipackproduct: MultiPackProducts) {
    this.showDialog = true;
    this.getAllSupplier();
    this.errorMessage = "";
    this.successMessage = "";
    this.clonedMultipack[multipackproduct.ID] = { ...multipackproduct };

    this.getAllProductVariationsOnProductID(this.clonedMultipack[multipackproduct.ID].ChildProductID);

    this.multipackForm.patchValue({
      ID: this.clonedMultipack[multipackproduct.ID].ID,
      SupplierID: this.clonedMultipack[multipackproduct.ID].SupplierID,
      ChildProductID: this.clonedMultipack[multipackproduct.ID].ChildProductID,
      ChildProductVariationID: this.clonedMultipack[multipackproduct.ID].ChildProductVariationID,
      Quantity: this.clonedMultipack[multipackproduct.ID].Quantity,
    });
    this.updateBtn = true;
    this.addBtn = false;
    this.onChangeSupplier(this.multipackForm.value['SupplierID'])
    this.getMultipackProducts(this.multipackForm.value['ParentProductID']);
    window.scrollTo(0, 0);

  }

  //Edit the Multipack product screen -Start

  //POST- for insert multipack product - start
  addMutipackProduct() {
    this.errorMessage = "";
    this.successMessage = "";
    if (this.multipackForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      this.hideMessage();
      return;
    }
    this.multipackForm.value["ID"] = 0;
    if (this.multipackForm.value["SupplierID"] <= 0) {
      this.errorMessage = "Please select supplier";
      this.hideMessage();
      return;
    }
    if (this.multipackForm.value["ChildProductID"] <= 0) {
      this.errorMessage = "Please select product.";
      this.hideMessage();
      return;
    }

    if (this.multipackForm.value["Quantity"] <= 0) {
      this.errorMessage = "Please specify quantity, must be greater than zero.";
      this.hideMessage();
      return;
    }

    this.multipackForm.value["ChildProductVariationID"] = this.multipackForm.value["ChildProductVariationID"] > 0 ? this.multipackForm.value["ChildProductVariationID"] : null;

    this.loading = true;
    this.multipackProdService.saveMultipackProduct(this.multipackForm.value)
      .subscribe(
        data => {
          this.getMultipackProducts(this.multipackForm.value['ParentProductID']);
          this.clearmultipackForm();
          this.successMessage = "Multipack Product Added";
          this.loading = false;
          this.hideMessage();
          return;
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Multipack already exists..!";
            this.loading = false;
          }
          else {
            this.errorMessage = "Error while saving multipack data";
            this.loading = false;
          }
          this.hideMessage();
          this.loading = false;
          return;
        })
  }
  //POST- for insert multipack product - end

  //PUT - for update multipack product -start
  updateMutipackProduct(mutipackProduct: MultiPackProducts) {
    this.errorMessage = "";
    this.successMessage = "";
    if (this.multipackForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      this.hideMessage();
      return;
    }
    if (this.multipackForm.value["SupplierID"] <= 0) {
      this.errorMessage = "Please select supplier";
      this.hideMessage();
      return;
    }
    if (this.multipackForm.value["ChildProductID"] <= 0) {
      this.errorMessage = "Please select product.";
      this.hideMessage();
      return;
    }
    if (this.multipackForm.value["Quantity"] <= 0) {
      this.errorMessage = "Please specify quantity, must be greater than zero. .";
      this.hideMessage();
      return;
    }

    this.multipackForm.value["ChildProductVariationID"] = this.multipackForm.value["ChildProductVariationID"] > 0 ? this.multipackForm.value["ChildProductVariationID"] : null;

    this.loading = true;
    this.multipackProdService.updateMultipackProduct(this.multipackForm.value)
      .subscribe(
        data => {
          this.getMultipackProducts(this.multipackForm.value['ParentProductID']);
          this.clearmultipackForm();
          this.successMessage = "Multipack Product Updated";
          this.loading = false;
          this.hideMessage();
          this.updateBtn = false;
          this.addBtn = true;
          return;
        },
        error => {
          this.loading = false;
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Multipack already exists..!";
          }
          else {
            this.errorMessage = "Error while saving multipack data";
          }
          this.hideMessage();
          return;
        })
  }
  //PUT - for update multipack product -end

  //hide message -start
  hideMessage() {
    setTimeout(() => {
      this.errorMessage = "";
      this.successMessage = "";
    }, 10000);
  }
  //hide message -end


  //clearform -start
  clearmultipackForm() {

    this.Product = [];
    this.productVariations = [];

    this.multipackForm.patchValue({
      SupplierID: 0,
      ChildProductID: 0,
      ChildProductVariationID: [],
      Quantity: '',
      IsDeleted: false
    });
  }
  //clearform -end
  //Routing to product page
  AddnewProducts() {
    this.router.navigateByUrl("/AdminDashboard/Products/Product");
  }
  //Number Regex -start
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  //Number Regex -end


  //DELETE method for multipack product-start
  onRowEditCancel(multipackProduct: MultiPackProducts, index: number) {
    this.errorMessage = "";
    this.successMessage = "";
    this.ChildProducts.indexOf[index] = this.clonedMultipack[multipackProduct.ID];
    delete this.ChildProducts[multipackProduct.ID];
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete Multipack?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteMultipackProduct(multipackProduct);
      },
      reject: () => {
      }
    });
  }
  deleteMultipackProduct(multipackProduct) {
    this.errorMessage = "";
    this.successMessage = "";
    this.multipackProdService.deleteMultipackProduct(multipackProduct)
      .subscribe(
        data => {
          this.successMessage = "Multipack Product Deleted";
          this.hideMessage();
          this.getMultipackProducts(this.multipackForm.value['ParentProductID']);
          this.clearmultipackForm();
          this.updateBtn = false;
          this.addBtn = true;
        },
        error => {
          this.errorMessage = "Error while deleting Multipack data";
          this.hideMessage();
          return;
        })
  }
  //DELETE method for multipack product-end
  clear(table: Table) {
    table.clear();
    this.inputSearch = "";
    this.SearchMultipack = "";
  }


  getAllProductVariationsOnProductID(id) {
    this.IsDisabledProductVariations = true;
    this.productvariationsService.getAllProductVariationsOnProductID(id)
      .subscribe(
        data => {
          this.productVariations = data;
          if (data != null) {
            this.IsDisabledProductVariations = false;
            const newValue = { ID: 0, Name: '--Select Product Variation--' };
            this.productVariations.unshift(newValue);
          }
          else {

            this.multipackForm.patchValue({
              ChildProductVariationID: [],
            });
            this.productVariations = [];
            this.IsDisabledProductVariations = true;
          }
        },
        error => {
          this.errorMessage = "Error while fetching Product Variations data";
          return;
        })
  }

}
