import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Productvariations } from '../../product/models/productvariations';

import { ProductVariationTypes } from '../../product/models/productvariationtypes';
@Injectable({
  providedIn: 'root'
})
export class ProductvariationsService {

  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getAllProductVariationsOnProductID(id): Observable<any> {
    return this.http.get('/api/ProductVariation/GetProductVariationsOnProductID?id=' + id, this.httpOptions);
  }

  getAllProductVariationOptions(): Observable<any> {
    return this.http.get('/api/ProductVariation/GetAllProductVariationOptions', this.httpOptions);
  }

  saveProductVariationOptions(productVariationTypes: ProductVariationTypes) {
    return this.http.post('/api/ProductVariation/SaveProductVariationOptions', productVariationTypes, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  saveProductVariation(productvariations: Productvariations) {
    return this.http.post('/api/ProductVariation/SaveProductVariation', productvariations, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  deleteProductVariation(productvariations: Productvariations) {
    return this.http.post('/api/ProductVariation/DeleteProductVariation', productvariations, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
}
