import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, NgForm, Validators, FormArray } from '@angular/forms';
import { SelectItem, ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { timeout } from 'rxjs/operators';

////Import Models
import { ProductManagement } from '../../models/productmanagement';
import { Supplier } from '../../models/supplier';
import { Location } from '../../models/location';
import { ProductLocation } from '../../models/productlocation';

////Import Services
import { ProductmanagementService } from '../../services/productmanagement.service';
import { LocationService } from '../../services/location.service';
import Selectr from 'mobius1-selectr';
import { LoginService } from '../../../../shared/service/login.service';
import { PackageformaterService } from '../../../../shared/service/packageformater.service';
import { VattypesService } from '../../../../shared/service/vattypes.service';
import { CarrierService } from '../../../../shared/service/carrier.service';
import { ProductvariationsService } from '../../../sales/services/productvariations.service';
import { Productvariations } from '../../models/productvariations';
import { ProductVariationTypes } from '../../models/productvariationtypes';
import { SupplierService } from '../../services/supplier.service';
import { InvoiceComponent } from 'src/app/modules/pricing/Components/invoice/invoice.component';
import { SeasonsService } from '../../services/seasons.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  providers: [MessageService, LoginService, ProductmanagementService, LocationService, ConfirmationService, PrimeNGConfig, MessageService, PackageformaterService, VattypesService, CarrierService, ProductvariationsService],
  preserveWhitespaces: true
})

export class ProductComponent implements OnInit {
  clonedProductLocation: { [s: string]: ProductLocation; } = {};
  @Output() isProdVal = new EventEmitter<any>();
  @Input() isShownProductForm: boolean = false;
  @Input() header: boolean = true;
  @Input() desk: boolean = true;
  @Input() phone: boolean = true;
  @Input() suppliers: any = [];
  @Input() seasons: any = [];
  @Input() selectedSupID: number;
  @Input() selectedSeasonID: number;
  @ViewChild('dt2') dt2: any;
  //public isShownProductForm: boolean;
  public isShownAddProductVariationForm: boolean;
  public productLocations = [];
  public productLocationsCopy = [];
  public product: ProductManagement = new ProductManagement(null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  public validationerror: string;
  public loading: boolean;
  public loggedIn: boolean;
  public productVariationTypes: ProductVariationTypes = new ProductVariationTypes(null, null);
  public newProductvariations: Productvariations = new Productvariations(null, null, null, null, null, null, null, null);

  productForm: FormGroup;
  addProductVariationOptionForm: FormGroup;
  addNewProductVariationForm: FormGroup;
  //items: FormArray;
  item: string;
  errorMessage = "";
  successMessage = "";
  errorGridMessage = "";
  successGridMessage = "";
  errorMessageVariationOption = "";
  successMessageVariationOption = "";
  successMessageNewProductVariation = "";
  errorMessageNewProductVariation = "";
  //public suppliers = [];
  public suppliersCopy = [];
  public seasonsCopy = [];
  public products = [];
  public locations = [];
  public locationTypes = [];
  public getAllPackageFormats = [];
  public getAllVatTypes = [];
  public locationsForMap = [];
  public locationsForMapCopy = [];
  public productMappedLocations = [];
  public mappedLocations = [];
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  public SelectedSupplierID: number;
  public inputSearch = "";
  public searchID: number;
  public packageFormats = [];
  public vatTypes = [];
  public productPackageTemplateData = [];
  public carriers = [];
  public carriers1 = [];
  public carriers2 = [];
  public carriers3 = [];
  public isDisabledCarriersID2: boolean;
  public isDisabledCarriersID3: boolean;
  public selectedCarriersID1: number;
  public selectedCarriersID2: number;
  public selectedCarriersID3: number;
  public productCarriersData = [];
  public productVariations = [];
  public productVariationOptions = [];
  url: string = "";
  prodValue: string = "";

  constructor(
    private loginService: LoginService,
    private productManagementService: ProductmanagementService,
    private locationService: LocationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private formBuilder: FormBuilder,
    private packageformaterService: PackageformaterService,
    private vattypesService: VattypesService,
    private carrierService: CarrierService,
    private productvariationsService: ProductvariationsService,
    private activatedRoute: ActivatedRoute,
    private supplierService: SupplierService,
    private seasonsService:SeasonsService,
    private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    // if(Boolean(this.activatedRoute.snapshot.queryParams['isShownProductForm'])){
    //   this.isShownProductForm = Boolean(this.activatedRoute.snapshot.queryParams['isShownProductForm']);
    //   this.url = this.activatedRoute.snapshot.queryParams['url'];
    // }
    // else{
    //   this.isShownProductForm = false;
    // }
    this.isShownProductForm = false;
    this.isShownAddProductVariationForm = false;
    this.productForm = this.formBuilder.group({
      ID: [''],
      Name: ['', Validators.required,],
      SupplierProductCode: [''],
      SupplierID: [''],
      SeasonID:[''],
      IsMultiPack: [''],
      BarCode: [''],
      ProductCategoryID: [''],
      ProductUnitWeight: [''],
      ProductLength: [''],
      ProductWidth: [''],
      ProductHeight: [''],
      VatTypeID: [],
      RoyalMailPackageFormat: [],
      HasVariations: [''],
      PackedUnitWeight: [''],
      PackedLength: [''],
      PackedWidth: [''],
      PackedHeight: [''],
      SelectedLocationIDs: [],
      MaxQuantityforLetter: [''],
      MaxQuantityforLargeLetter: [''],
      MaxQuantityforParcel: [''],
      selectedCarriersID1: [''],
      selectedCarriersID2: [''],
      selectedCarriersID3: [''],
    });

    this.addProductVariationOptionForm = this.formBuilder.group({
      ID: [''],
      OptionName: [''],
    });

    this.addNewProductVariationForm = this.formBuilder.group({
      ID: [''],
      ProductID: [''],
      ProductName: [''],
      items: this.formBuilder.array([
        this.formBuilder.group({
          Name: [''],
          Value: [''],
        })
      ])
    });

    this.getAllProducts();
    this.getAllSuppliers();
    this.getAllSeasons();
    this.getAllPackageFormaters();
    this.getAllVATTypes();
    this.getAllLocationToMap();
    this.getAllCarriers();
  }

  get f() { return this.productForm.controls, this.addProductVariationOptionForm.controls, this.addNewProductVariationForm.controls; }

  get items() {
    return this.addNewProductVariationForm.get('items') as FormArray;
  }


  showProductForm() {
    this.errorMessage = "";
    this.successMessage = "";
    this.errorGridMessage = "";
    this.successGridMessage = "";
    this.clearProductForm();
    this.isShownProductForm = true;
  }

  Close() {
    this.isShownProductForm = false;
    this.clearProductForm();
    this.isProdVal.emit({ isShownProd: this.isShownProductForm, prodVal: this.prodValue });
  }

  showAllProducts() {
    this.isShownProductForm = false;
  }

  //Begin Code Supplier
  getAllSuppliers() {
    this.productManagementService.getAllSuppliers()
      .subscribe(
        data => {
          this.suppliers = data;
          this.suppliersCopy = data;
          const newValue = { ID: 0, Name: '--Select Supplier--' };
          this.suppliers.unshift(newValue);
        },
        error => {
          this.errorMessage = "Error while fetching supplier data";
          return;
        })
  }
  //End Code Supplier

    //Begin Code Seasons
    getAllSeasons() {
      this.seasonsService.GetAllSeasons()
        .subscribe(
          data => {
            this.seasons = data;
            this.seasonsCopy = data;
            const newValue = { ID: 0, Name: '--Select Season--' };
            this.seasons.unshift(newValue);
          },
          error => {
            this.errorMessage = "Error while fetching Seasons data";
            return;
          })
    }
    //End Code Seasons

  //Begin Code Product
  getAllProducts() {
    this.loading = true;
    this.productManagementService.getAllProducts()
      .subscribe(
        data => {
          this.productLocations = data;
          this.productLocationsCopy = data;
          this.loading = false;

          let filtered: any[] = [];

          this.filterData(); 

          this.activatedRoute.queryParams.subscribe((params) => {
            this.inputSearch = params['q'];
            //this.dt2.filterGlobal(this.inputSearch, 'contains');

            this.searchID = +params['q'];

            if (this.searchID) {
              filtered = this.productLocationsCopy.filter(a => a.ID === this.searchID);

              this.productLocations = filtered;
              //alert(this.inputSearch);
              //console.log("Mapped Product search result" ,this.productLocations);
            }
          });

        },
        error => {
          this.errorMessage = "Error while fetching product data";
          this.loading = false;
          return;
        });
  }

  // filterData(event: any) {
  //   const searchTerm = event.target.value;
  //   this.dt2.filterGlobal(searchTerm, 'contains');

  //   if (!searchTerm) {
  //     this.productLocations = this.productLocationsCopy;
  //   }
  // }

  filterData() {
    const filterValue = (this.inputSearch || '').trim().toLowerCase();
  
    // Reset the filtered list to the original copy before filtering
    this.productLocations = [...this.productLocationsCopy];
  
    // Filter by supplier if a supplier is selected
    if (this.SelectedSupplierID) {
      this.productLocations = this.productLocations.filter((listing) =>
        listing.SupplierID === this.SelectedSupplierID
      );
    }
  
    // Filter by season if a season is selected
    if (this.selectedSeasonID) {
      this.productLocations = this.productLocations.filter((listing) =>
        listing.SeasonID === this.selectedSeasonID
      );
    }
    // Apply the search filter only if there's a search value provided
    if (filterValue) {
      this.productLocations = this.productLocations.filter((listing) =>
        (listing.Name?.toLowerCase().includes(filterValue) ||
          listing.SupplierProductCode?.toLowerCase().includes(filterValue) ||
          listing.Label_SupplierName?.toLowerCase().includes(filterValue) ||
          listing.BarCode?.toLowerCase().includes(filterValue) ||
          listing.MappedLocations?.toLowerCase().includes(filterValue) ||
          listing.Label_PackageFormaterName?.toLowerCase().includes(filterValue) ||
          listing.Label_SeasonName?.toLowerCase().includes(filterValue))
      );
    }
  
    // Trigger change detection to update the UI
    this.cdr.detectChanges();
  }
  

  addProduct() {
    this.errorMessage = "";
    this.successMessage = "";
    this.errorGridMessage = "";
    this.successGridMessage = "";
    if (this.productForm.invalid) {
      this.errorMessage = "Please fill all mandatory data.";
      return;
    }
    this.productForm.value["SeasonID"] = this.productForm.value["SeasonID"] != 0 ? this.productForm.value["SeasonID"] : 0;

    this.productForm.value["IsMultiPack"] = this.productForm.value["IsMultiPack"] == true ? true : false;
    this.productForm.value["HasVariations"] = this.productForm.value["HasVariations"] == true ? true : false;
    this.productForm.value["VatTypeID"] = this.productForm.value["VatTypeID"] > 0 ? this.productForm.value["VatTypeID"] : 0;

    this.productForm.value["ID"] = this.productForm.value["ID"] != 0 ? this.productForm.value["ID"] : 0;
    this.productForm.value["selectedCarriersID1"] = this.productForm.value["selectedCarriersID1"] != 0 ? this.productForm.value["selectedCarriersID1"] : 0;
    this.productForm.value["selectedCarriersID2"] = this.productForm.value["selectedCarriersID2"] != 0 ? this.productForm.value["selectedCarriersID2"] : 0;
    this.productForm.value["selectedCarriersID3"] = this.productForm.value["selectedCarriersID3"] != 0 ? this.productForm.value["selectedCarriersID3"] : 0;

    this.productForm.value["MaxQuantityforLetter"] = this.productForm.value["MaxQuantityforLetter"] != 0 ? this.productForm.value["MaxQuantityforLetter"] : 0;
    this.productForm.value["MaxQuantityforLargeLetter"] = this.productForm.value["MaxQuantityforLargeLetter"] != 0 ? this.productForm.value["MaxQuantityforLargeLetter"] : 0;
    this.productForm.value["MaxQuantityforParcel"] = this.productForm.value["MaxQuantityforParcel"] != 0 ? this.productForm.value["MaxQuantityforParcel"] : 0;

    if (this.productForm.value["MaxQuantityforLetter"] != 0) {
      if (this.productForm.value["MaxQuantityforLargeLetter"] == 0) {
        if (this.productForm.value["MaxQuantityforParcel"] != 0) {
          if (this.productForm.value["MaxQuantityforLetter"] >= this.productForm.value["MaxQuantityforParcel"]) {
            this.errorMessage = "Letter MAX quantity should be less than Parcel";
            return;
          }
        }
      }
      else {
        if (this.productForm.value["MaxQuantityforParcel"] != 0) {
          if (this.productForm.value["MaxQuantityforLetter"] >= (this.productForm.value["MaxQuantityforLargeLetter"] || this.productForm.value["MaxQuantityforParcel"])) {
            this.errorMessage = "Letter MAX quantity should be less than Large Letter and Parcel";
            return;
          }
        }
        else {
          if (this.productForm.value["MaxQuantityforLetter"] >= this.productForm.value["MaxQuantityforLargeLetter"]) {
            this.errorMessage = "Letter MAX quantity should be less than Large Letter";
            return;
          }
        }
      }

    }

    if (this.productForm.value["MaxQuantityforLargeLetter"] != 0) {
      if (this.productForm.value["MaxQuantityforParcel"] != 0) {
        if (this.productForm.value["MaxQuantityforLargeLetter"] >= this.productForm.value["MaxQuantityforParcel"]) {
          this.errorMessage = "Large Letter MAX quantity should be less than Parcel";
          return;
        }
      }
    }

    let string = this.productForm.value["Name"];
    //let result = /^[a-zA-Z]+/.test(string)
    //if (!result) {
    //  this.errorMessage = "Product name should be start with alphabet";
    //  return;
    //}

    if (this.productForm.value["ID"] > 0) {
      this.updateProduct(this.productForm.value);
    }
    else {

      this.saveProduct(this.productForm.value);
    }
  }

  saveProduct(product) {
    this.prodValue = this.productForm.get('Name').value;
    this.productManagementService.saveProduct(product)
      .subscribe(
        data => {
          this.clearProductForm();
          this.successMessage = "Product Added";
          this.successGridMessage = "Product Added";

          this.getAllProducts();
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessage = "Product already exists..!";
          }
          else {
            this.errorMessage = "Error while adding product data";
          }
          return;
        })

  }

  updateProduct(product) {
    this.prodValue = this.productForm.get('Name').value;
    this.productManagementService.updateProduct(product)
      .subscribe(
        data => {
          this.clearProductForm();
          this.successMessage = "Product Updated";
          this.successGridMessage = "Product Updated";

          this.getAllProducts();
          this.isShownProductForm = false;
        },
        error => {
          this.errorMessage = "Error while updating product data";
          return;
        })

  }

  deleteProduct(product: ProductManagement) {
    this.successMessage = "";
    this.successMessage = "";
    this.errorGridMessage = "";
    this.successGridMessage = "";
    this.productManagementService.deleteProduct(product)
      .subscribe(
        data => {
          this.successGridMessage = "Product Deleted";
          this.getAllProducts();
        },
        error => {
          this.errorGridMessage = "Error while deleting product data";
        })

  }
  //End Code Product

  //Begin Code Location
  getAllLocationToMap() {
    this.successMessage = "";
    this.successMessage = "";
    this.errorGridMessage = "";
    this.successGridMessage = "";
    this.locationService.getAllLocationToMap()
      .subscribe(
        data => {
          this.locationsForMap = data;
          this.locationsForMapCopy = data;
        },
        error => {
          this.errorMessage = "Error while fetching location data";
          return;
        })
  }

  //End Code Location

  onRowEditInit(productLocation: ProductLocation) {
    this.productPackageTemplateData = [];
    this.mappedLocations = [];
    this.successMessage = "";
    this.errorMessage = "";
    this.successGridMessage = "";
    this.errorGridMessage = "";
    this.isShownProductForm = true;;
    this.clonedProductLocation[productLocation.ID] = { ...productLocation };

    this.locationService.getMappedLocationsBasedOnProduct(this.clonedProductLocation[productLocation.ID].ID)
      .subscribe(
        data => {
          this.productMappedLocations = data;
          if (this.productMappedLocations !== []) {
            for (let i = 0; i < this.productMappedLocations.length; i++) {
              this.mappedLocations.push(this.locationsForMapCopy.find((x) => { return x.ID == this.productMappedLocations[i].LocationID; }));
            }
          }

          this.productManagementService.getProductPackageTemplateDataBasedOnProduct(this.clonedProductLocation[productLocation.ID].ID)
            .subscribe(
              data => {
                this.productForm.patchValue({
                  MaxQuantityforLetter: 0,
                  MaxQuantityforLargeLetter: 0,
                  MaxQuantityforParcel: 0,
                });

                this.productPackageTemplateData = data;
                if (this.productPackageTemplateData !== []) {
                  for (let i = 0; i < this.productPackageTemplateData.length; i++) {
                    for (let j = 0; j < this.packageFormats.length; j++) {
                      if (this.packageFormats[j].Name == 'Letter' && this.productPackageTemplateData[i].Label_PackageFormatName == 'Letter') {
                        this.productForm.patchValue({ MaxQuantityforLetter: this.productPackageTemplateData[i].MaxQuantity, });
                        break;
                      }
                      if (this.packageFormats[j].Name == 'Large Letter' && this.productPackageTemplateData[i].Label_PackageFormatName == 'Large Letter') {
                        this.productForm.patchValue({ MaxQuantityforLargeLetter: this.productPackageTemplateData[i].MaxQuantity, });
                        break;
                      }
                      if (this.packageFormats[j].Name == 'Parcel' && this.productPackageTemplateData[i].Label_PackageFormatName == 'Parcel') {
                        this.productForm.patchValue({ MaxQuantityforParcel: this.productPackageTemplateData[i].MaxQuantity, });
                        break;
                      }
                    }
                  }
                }
              })

          this.selectedCarriersID1 = 0;
          this.selectedCarriersID2 = 0;
          this.selectedCarriersID3 = 0;
          this.isDisabledCarriersID2 = true;
          this.isDisabledCarriersID3 = true;
          this.selectedSeasonID = 0;

          this.productManagementService.getProductCarrierPrefenencesBasedOnProductID(this.clonedProductLocation[productLocation.ID].ID)
            .subscribe(
              data => {
                this.productCarriersData = [];

                this.productCarriersData = data;
                if (this.productCarriersData !== [] && this.productCarriersData.length > 0) {
                  for (let i = 0; i < this.productCarriersData.length; i++) {
                    if (this.productCarriersData[i].Preference == 1) {
                      this.selectedCarriersID1 = this.productCarriersData[i].CarrierID;
                      this.selectedCarriersID3 = 0;
                      this.isDisabledCarriersID2 = false;
                      this.isDisabledCarriersID3 = true;
                      this.carriers2 = this.carriers1.filter(item => item.ID !== this.productCarriersData[i].CarrierID);
                    }
                    else if (this.productCarriersData[i].Preference == 2) {
                      this.selectedCarriersID2 = this.productCarriersData[i].CarrierID;
                      this.isDisabledCarriersID3 = false;
                      this.carriers3 = this.carriers2.filter(item => item.ID !== this.productCarriersData[i].CarrierID);
                    }
                    else if (this.productCarriersData[i].Preference == 3) {
                      this.selectedCarriersID3 = this.productCarriersData[i].CarrierID;
                    }
                  }
                }
                else {
                  this.selectedCarriersID1 = 0;
                  this.selectedCarriersID2 = 0;
                  this.selectedCarriersID3 = 0;
                  this.isDisabledCarriersID2 = true;
                  this.isDisabledCarriersID3 = true;
                }
              })



          this.productForm.patchValue({
            ID: this.clonedProductLocation[productLocation.ID].ID,
            Name: this.clonedProductLocation[productLocation.ID].Name.trim(),
            SupplierProductCode: this.clonedProductLocation[productLocation.ID].SupplierProductCode,
            SupplierID: this.clonedProductLocation[productLocation.ID].SupplierID,
            SeasonID: this.clonedProductLocation[productLocation.ID].SeasonID,
            IsMultiPack: this.clonedProductLocation[productLocation.ID].IsMultiPack == true ? true : false,
            BarCode: this.clonedProductLocation[productLocation.ID].BarCode,
            ProductCategoryID: this.clonedProductLocation[productLocation.ID].ProductCategoryID,
            ProductUnitWeight: this.clonedProductLocation[productLocation.ID].ProductUnitWeight,
            ProductLength: this.clonedProductLocation[productLocation.ID].ProductLength,
            ProductWidth: this.clonedProductLocation[productLocation.ID].ProductWidth,
            ProductHeight: this.clonedProductLocation[productLocation.ID].ProductHeight,
            VatTypeID: this.clonedProductLocation[productLocation.ID].VatTypeID > 0 ? this.clonedProductLocation[productLocation.ID].VatTypeID : 0,
            RoyalMailPackageFormat: this.clonedProductLocation[productLocation.ID].RoyalMailPackageFormat > 0 ? this.clonedProductLocation[productLocation.ID].RoyalMailPackageFormat : 0,
            HasVariations: this.clonedProductLocation[productLocation.ID].HasVariations == true ? true : false,
            PackedUnitWeight: this.clonedProductLocation[productLocation.ID].PackedUnitWeight,
            PackedLength: this.clonedProductLocation[productLocation.ID].PackedLength,
            PackedWidth: this.clonedProductLocation[productLocation.ID].PackedWidth,
            PackedHeight: this.clonedProductLocation[productLocation.ID].PackedHeight,
            SelectedLocationIDs: this.mappedLocations,
            
            //packageFormats: this.packageFormats,

          });
        },
        error => {
          this.errorMessage = "Error while fetching product mapped locations data";
          return;
        })
  }

  onRowEditCancel(productLocation: ProductLocation, index: number, event: Event) {
    this.errorMessage = "";
    this.successMessage = "";
    this.errorGridMessage = "";
    this.successGridMessage = "";
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete product?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteProduct(productLocation);
      },
      reject: () => {
      }
    });
  }

  getMappedLocationsBasedOnProduct(ProductID) {
    this.errorMessage = "";
    this.successMessage = "";
    this.locationService.getMappedLocationsBasedOnProduct(ProductID)
      .subscribe(
        data => {
          this.productMappedLocations = data;
        },
        error => {
          this.errorMessage = "Error while fetching product mapped locations data";
          return;
        })

  }


  clearProductForm() {
    this.productForm.patchValue({
      ID: 0,
      Name: '',
      SupplierProductCode: '',
      BarCode: '',
      SupplierID: '',
      SeasonID:'',
      IsMultiPack: false,
      HasVariations: false,
      ProductCategoryID: '',
      VatTypeID: '',
      RoyalMailPackageFormat: '',
      ProductUnitWeight: 0,
      ProductLength: 0,
      ProductWidth: 0,
      ProductHeight: 0,
      PackedUnitWeight: 0,
      PackedLength: 0,
      PackedWidth: 0,
      PackedHeight: 0,
      SelectedLocationIDs: [],
      MaxQuantityforLetter: 0,
      MaxQuantityforLargeLetter: 0,
      MaxQuantityforParcel: 0,
      selectedCarriersID1: [''],
      selectedCarriersID2: [''],
      selectedCarriersID3: [''],
    });
    this.productForm.controls['Name'].setErrors(null);
  }

  clearAddProductVariationOptionForm() {
    this.addProductVariationOptionForm.patchValue({
      ID: 0,
      OptionName: '',
    });
  }

  clearaddNewProductVariationForm() {
    this.addNewProductVariationForm.patchValue({
      Name: '--Select Option--',
      Value: '',
    });
  }

  hideMessage() {
    setTimeout(() => {
      this.errorMessage = "";
      this.successMessage = "";
      this.errorGridMessage = "";
      this.successGridMessage = "";
    }, 10000);
  }

  clear(table: Table) {
    this.successMessage = "";
    this.errorMessage = "";
    this.successGridMessage = "";
    this.errorGridMessage = "";
    table.clear();
    this.inputSearch = "";
    this.searchID = 0;
    this.SelectedSupplierID = 0;
    this.selectedSeasonID = 0;
    // this.getAllProducts();
    this.getAllSuppliers();
    this.getAllSeasons();
    this.productLocations=this.productLocationsCopy;
  }

  filterLocations(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    if (query.length >= 1) {
      for (let i = 0; i < this.locationsForMapCopy.length; i++) {
        let location = this.locationsForMapCopy[i];
        if ((location.Label_FinalLocationName != undefined && location.Label_FinalLocationName != null && location.Label_FinalLocationName != "" && location.Label_FinalLocationName.toLowerCase().includes(query.toLowerCase())) || (location.Name != undefined && location.Name != null && location.Name != "" && location.Name.toLowerCase().includes(query.toLowerCase()))) {
          filtered.push(location);
        }
      }
    }
    this.locationsForMap = filtered;
  }


  onSearchSupplierFilter(inputSearch) {
    let filtered: any[] = [];
    let query = inputSearch;
    if (query.length >= 2) {
      for (let i = 0; i < this.productLocations.length; i++) {
        let product = this.productLocations[i];

        if (product.Name.toLowerCase().includes(query.toLowerCase()) || product.Label_SupplierName.toLowerCase().includes(query.toLowerCase())
        ) {
          const newValue = { ID: product.SupplierID, Name: product.Label_SupplierName };
          filtered.push(newValue);
        }
        else {
          if (product.SupplierProductCode != undefined && product.SupplierProductCode != null && product.SupplierProductCode != "" && product.SupplierProductCode.toLowerCase().includes(query.toLowerCase())) {
            const newValue = { ID: product.SupplierID, Name: product.Label_SupplierName };
            filtered.push(newValue);
          }
          else if (product.BarCode != undefined && product.BarCode != null && product.BarCode != "" && product.BarCode.toLowerCase().includes(query.toLowerCase())) {
            const newValue = { ID: product.SupplierID, Name: product.Label_SupplierName };
            filtered.push(newValue);
          }
          else if (product.MappedLocations != undefined && product.MappedLocations != null && product.MappedLocations != "" && product.MappedLocations.toLowerCase().includes(query.toLowerCase())) {
            const newValue = { ID: product.SupplierID, Name: product.Label_SupplierName };
            filtered.push(newValue);
          }
          else if (product.Label_PackageFormaterName != undefined && product.Label_PackageFormaterName != null && product.Label_PackageFormaterName != "" && product.Label_PackageFormaterName.toLowerCase().includes(query.toLowerCase())) {
            const newValue = { ID: product.SupplierID, Name: product.Label_SupplierName };
            filtered.push(newValue);
          }
        }
      }

      const distinctThings = filtered.filter(
        (thing, i, arr) => arr.findIndex(t => t.ID === thing.ID) === i
      );

      if (distinctThings.length > 0) {
        this.suppliers = distinctThings;
        //this.suppliers = this.suppliers.sort((a, b) => (a.Name - b.Name));
        this.suppliers = this.suppliers.sort((a, b) => (a.Name < b.Name ? -1 : 1));
        const newValue = { ID: 0, Name: '--Select Supplier--' };
        this.suppliers.unshift(newValue);
      }
      else {
        this.suppliers = this.suppliersCopy;
      }
    }
    else {
      if (this.SelectedSupplierID == 0) {
        this.suppliers = this.suppliersCopy;
      }
    }
  }

  getAllPackageFormaters() {
    this.packageformaterService.getAllPackageFormaters()
      .subscribe(
        data => {
          this.packageFormats = data;
          const newValue = { ID: 0, Name: '--Select PackageFormater--', MaxQuantity: 0 };
          this.packageFormats.unshift(newValue);
        },
        error => {
          this.errorMessage = "Error while fetching package formater data";
          return;
        });
  }

  getAllVATTypes() {
    this.vattypesService.getAllVATTypes()
      .subscribe(
        data => {
          this.vatTypes = data;
          const newValue = { ID: 0, VATCode: '--Select VATType--' };
          this.vatTypes.unshift(newValue);
        },
        error => {
          this.errorMessage = "Error while fetching vat types data";
          return;
        });
  }

  getAllCarriers() {
    this.carrierService.getAllCarriers()
      .subscribe(
        data => {
          this.carriers = data;
          const newValue = { ID: 0, CarrierName: '--Select Carrier--' };
          this.carriers.unshift(newValue);
          this.carriers1 = this.carriers;
          this.carriers2 = this.carriers;
          this.carriers3 = this.carriers;
          this.isDisabledCarriersID2 = true;
          this.isDisabledCarriersID3 = true;
        },
        error => {
          this.errorMessage = "Error while fetching package carriers data";
          return;
        });
  }

  onSelectcarriers1Change(event) {
    let filtered: any[] = [];
    let query = event.value;

    if (query > 0) {
      this.isDisabledCarriersID2 = false;
      this.isDisabledCarriersID3 = true;
      this.carriers2 = this.carriers1.filter(item => item.ID !== query);
      this.selectedCarriersID2 = 0;
      this.selectedCarriersID3 = 0;

    }
    else {
      this.isDisabledCarriersID2 = true;
      this.isDisabledCarriersID3 = true;
      this.selectedCarriersID2 = 0;
      this.selectedCarriersID3 = 0;
    }
  }

  onSelectcarriers2Change(event) {
    let filtered: any[] = [];
    let query = event.value;

    if (query > 0) {
      this.isDisabledCarriersID3 = false;
      this.carriers3 = this.carriers2.filter(item => item.ID !== query);
      this.selectedCarriersID3 = 0;
    }
    else {
      this.isDisabledCarriersID3 = true;
      this.selectedCarriersID3 = 0;
    }
  }

  //Number Regex -start
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  //Number Regex -end

  onRowEditProductVariation(productLocation: ProductLocation) {
    this.errorMessage = "";
    this.successMessage = "";
    this.errorGridMessage = "";
    this.successGridMessage = "";
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";

    this.isShownAddProductVariationForm = true;

    this.getAllProductVariationsOnProductID(productLocation.ID);
    this.getAllProductVariationsOptions();

    this.addNewProductVariationForm.reset();
    this.addNewProductVariationForm.patchValue({
      ProductID: productLocation.ID,
      ProductName: productLocation.Name,
      //ProductVariationTypeID: 0,
      //OptionName: ''
    });

    if (this.addNewProductVariationForm.value["items"].length > 0) {
      for (let i = this.addNewProductVariationForm.value["items"].length; i > 0; i--) {
        (this.addNewProductVariationForm.get('items') as FormArray).removeAt(i);
      }
    }

  }

  //Begin Code Product Variations
  getAllProductVariationsOnProductID(id) {
    this.productvariationsService.getAllProductVariationsOnProductID(id)
      .subscribe(
        data => {
          if (data != null) {
            this.productVariations = data;
          }
          else {
            this.productVariations = [];
          }
        },
        error => {
          this.errorMessage = "Error while fetching Product Variations data";
          return;
        })
  }

  getAllProductVariationsOptions() {
    this.productvariationsService.getAllProductVariationOptions()
      .subscribe(
        data => {
          if (data != null) {
            this.productVariationOptions = data;
            for (var i = 0; i < this.productVariationOptions.length; i++) {
              this.productVariationOptions[i].disabled = false;
            }

            const newValue = { ID: 0, Name: '--Select Option--', disabled: false };
            this.productVariationOptions.unshift(newValue);
          }
          else {
            this.productVariationOptions = [];
          }
        },
        error => {
          this.errorMessage = "Error while fetching Product Variation Options data";
          return;
        })
  }

  addProductVariationOption() {
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";

    if (this.addProductVariationOptionForm.value["OptionName"] == "" || this.addProductVariationOptionForm.value["OptionName"] == undefined) {
      this.errorMessageVariationOption = "Please enter product variation option name";
      return;
    }

    let string = this.addProductVariationOptionForm.value["OptionName"];
    let result = /^[a-zA-Z]+/.test(string)
    if (!result) {
      this.errorMessageVariationOption = "Product variation option name should be start with alphabet";
      return;
    }

    this.addProductVariationOptionForm.value["ID"] = 0;
    this.productVariationTypes.ID = this.addProductVariationOptionForm.value["ID"];
    this.productVariationTypes.Name = this.addProductVariationOptionForm.value["OptionName"];

    this.productvariationsService.saveProductVariationOptions(this.productVariationTypes)
      .subscribe(
        data => {
          this.successMessageVariationOption = "Product variation option added";
          this.getAllProductVariationsOptions();
          this.clearAddProductVariationOptionForm();
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessageVariationOption = "Product variation option already exists..!";
          }
          else {
            this.errorMessageVariationOption = "Error while adding product variation option data";
          }
          return;
        })
  }

  addNewProductVariation() {
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";

    if (this.addNewProductVariationForm.value["items"].length <= 0) {
      this.errorMessageNewProductVariation = "Please select variation option and enter value";
      return;
    }
    else {
      for (let i = 0; i < this.addNewProductVariationForm.value["items"].length; i++) {
        if (this.addNewProductVariationForm.value["items"][i].Name == "" || this.addNewProductVariationForm.value["items"][i].Name == "--Select Option--" || this.addNewProductVariationForm.value["items"][i].Name == undefined) {
          this.errorMessageNewProductVariation = "Please select variation option";
          return;
        }

        if (this.addNewProductVariationForm.value["items"][i].Value == "" || this.addNewProductVariationForm.value["items"][i].Value == undefined) {
          this.errorMessageNewProductVariation = "Please enter product variation value";
          return;
        }

        let string = this.addNewProductVariationForm.value["items"][i].Value;
        let result = /^[a-zA-Z]+/.test(string)
        if (!result) {
          this.errorMessageNewProductVariation = "Product variation value should be start with alphabet";
          return;
        }
      }
    }

    this.newProductvariations.ID = this.addNewProductVariationForm.value["ID"] > 0 ? this.addNewProductVariationForm.value["ID"] : 0;
    this.newProductvariations.ProductID = this.addNewProductVariationForm.value["ProductID"];
    this.newProductvariations.Items = this.addNewProductVariationForm.value["items"];

    let ProductID = this.addNewProductVariationForm.value["ProductID"];
    let ProductName = this.addNewProductVariationForm.value["ProductName"];

    this.productvariationsService.saveProductVariation(this.newProductvariations)
      .subscribe(
        data => {
          if (this.addNewProductVariationForm.value["items"].length > 0) {
            for (let i = this.addNewProductVariationForm.value["items"].length; i > 0; i--) {
              (this.addNewProductVariationForm.get('items') as FormArray).removeAt(i);
            }
          }

          this.addNewProductVariationForm.reset();
          this.addNewProductVariationForm.patchValue({
            ID: 0,
            ProductID: ProductID,
            ProductName: ProductName,
          });
          this.getAllProductVariationsOnProductID(this.addNewProductVariationForm.value["ProductID"])
          this.successMessageNewProductVariation = "Product variation saved";

          for (var j = 0; j < this.productVariationOptions.length; j++) {
            this.productVariationOptions[j].disabled = false;
          }
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessageNewProductVariation = "Product variation already exists..!";
          }
          else {
            this.errorMessageNewProductVariation = "Error while saving product variation data";
          }
          return;
        })

  }

  addItem() {
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";

    if (this.addNewProductVariationForm.value["items"].length <= 0) {
      this.errorMessageNewProductVariation = "Please select variation option and enter value";
      return;
    }
    else {
      for (let i = 0; i < this.addNewProductVariationForm.value["items"].length; i++) {
        if (this.addNewProductVariationForm.value["items"][i].Name == "" || this.addNewProductVariationForm.value["items"][i].Name == "--Select Option--" || this.addNewProductVariationForm.value["items"][i].Name == undefined) {
          this.errorMessageNewProductVariation = "Please select variation option, before adding new";
          return;
        }

        if (this.addNewProductVariationForm.value["items"][i].Value == "" || this.addNewProductVariationForm.value["items"][i].Value == undefined) {
          this.errorMessageNewProductVariation = "Please enter product variation value, before adding new";
          return;
        }

        let string = this.addNewProductVariationForm.value["items"][i].Value;
        let result = /^[a-zA-Z]+/.test(string)
        if (!result) {
          this.errorMessageNewProductVariation = "Product variation value should be start with alphabet, before adding new";
          return;
        }
      }
    }

    if (this.productVariationOptions.length - 1 > this.addNewProductVariationForm.value["items"].length) {
      this.items.push(this.formBuilder.group({
        Name: [''],
        Value: [''],
      }));

      for (var j = 0; j < this.productVariationOptions.length; j++) {
        this.productVariationOptions[j].disabled = false;
      }

      for (let i = 0; i < this.addNewProductVariationForm.value["items"].length; i++) {
        if (this.addNewProductVariationForm.value["items"][i].Name != "" || this.addNewProductVariationForm.value["items"][i].Name != "--Select Option--"
          || this.addNewProductVariationForm.value["items"][i].Name != undefined) {
          for (var j = 0; j < this.productVariationOptions.length; j++) {
            if (this.addNewProductVariationForm.value["items"][i].Name == this.productVariationOptions[j].Name) {
              this.productVariationOptions[j].disabled = true;
            }
          }
        }
      }
    }
    else {
      this.errorMessageNewProductVariation = "No more variation option to add";
      return;
    }
  }

  removeItem(index) {
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";
    if (this.addNewProductVariationForm.value["items"].length > 1) {
      (this.addNewProductVariationForm.get('items') as FormArray).removeAt(index);
    }
    for (var j = 0; j < this.productVariationOptions.length; j++) {
      this.productVariationOptions[j].disabled = false;
    }
    for (let i = 0; i < this.addNewProductVariationForm.value["items"].length; i++) {
      if (this.addNewProductVariationForm.value["items"][i].Name != "" || this.addNewProductVariationForm.value["items"][i].Name != "--Select Option--"
        || this.addNewProductVariationForm.value["items"][i].Name != undefined) {
        for (var j = 0; j < this.productVariationOptions.length; j++) {
          if (this.addNewProductVariationForm.value["items"][i].Name == this.productVariationOptions[j].Name) {
            this.productVariationOptions[j].disabled = true;
          }
        }
      }
    }

  }

  productVariationReset() {
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";

    let ProductID = this.addNewProductVariationForm.value["ProductID"];
    let ProductName = this.addNewProductVariationForm.value["ProductName"];

    this.addNewProductVariationForm.reset();

    this.addNewProductVariationForm.patchValue({
      ID: 0,
      ProductID: ProductID,
      ProductName: ProductName,
    });

    if (this.addNewProductVariationForm.value["items"].length > 0) {
      for (let i = this.addNewProductVariationForm.value["items"].length; i > 0; i--) {
        (this.addNewProductVariationForm.get('items') as FormArray).removeAt(i);
      }
    }

    for (var j = 0; j < this.productVariationOptions.length; j++) {
      this.productVariationOptions[j].disabled = false;
    }
  }

  onRowEditInitProductVariations(productVariations: Productvariations) {

    if (this.addNewProductVariationForm.value["items"].length > 0) {
      for (let i = this.addNewProductVariationForm.value["items"].length; i > 0; i--) {
        (this.addNewProductVariationForm.get('items') as FormArray).removeAt(i);
      }
    }

    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";
    this.addNewProductVariationForm.patchValue({
      ID: 0,
    });

    this.addNewProductVariationForm.patchValue({
      ID: productVariations.ID,
    });

    let varDetails = JSON.parse(productVariations.VariationDetail.trim());

    for (let i = 0; i < varDetails.length - 1; i++) {
      this.items.push(this.formBuilder.group({
        Name: [''],
        Value: [''],
      }));
    }

    this.addNewProductVariationForm.patchValue({
      items: varDetails,
    });

    for (var j = 0; j < this.productVariationOptions.length; j++) {
      this.productVariationOptions[j].disabled = false;
    }

    for (let i = 0; i < this.addNewProductVariationForm.value["items"].length; i++) {
      if (this.addNewProductVariationForm.value["items"][i].Name != "" || this.addNewProductVariationForm.value["items"][i].Name != "--Select Option--"
        || this.addNewProductVariationForm.value["items"][i].Name != undefined) {
        for (var j = 0; j < this.productVariationOptions.length; j++) {
          if (this.addNewProductVariationForm.value["items"][i].Name == this.productVariationOptions[j].Name) {
            this.productVariationOptions[j].disabled = true;
          }
        }
      }
    }
  }

  onRowEditCancelProductVariations(productVariations: Productvariations, index: number, event: Event) {
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure, you want to delete product variation?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        this.deleteProductVariation(productVariations);
      },
      reject: () => {
      }
    });
  }

  deleteProductVariation(productVariations: Productvariations) {
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";
    this.productvariationsService.deleteProductVariation(productVariations)
      .subscribe(
        data => {
          this.successMessageNewProductVariation = "Product variation deleted";
          this.getAllProductVariationsOnProductID(this.addNewProductVariationForm.value["ProductID"])
          this.addNewProductVariationForm.patchValue({
            ID: 0,
          });
        },
        error => {
          if (error.error.toString().toLowerCase() == "exists.!") {
            this.errorMessageNewProductVariation = "Mapped/Associated/In Stock product variation is not allowed to delete";
          }
          else {
            this.errorMessageNewProductVariation = "Error while deleting product variation data";
          }
          return;
        })

  }

  onSelectProductVariationOptionChange(event) {
    this.errorMessageVariationOption = "";
    this.successMessageVariationOption = "";
    this.successMessageNewProductVariation = "";
    this.errorMessageNewProductVariation = "";
    let query = event.value;

    for (var j = 0; j < this.productVariationOptions.length; j++) {
      this.productVariationOptions[j].disabled = false;
    }

    for (let i = 0; i < this.addNewProductVariationForm.value["items"].length; i++) {
      if (this.addNewProductVariationForm.value["items"][i].Name != "" || this.addNewProductVariationForm.value["items"][i].Name != "--Select Option--"
        || this.addNewProductVariationForm.value["items"][i].Name != undefined) {
        for (var j = 0; j < this.productVariationOptions.length; j++) {
          if (this.addNewProductVariationForm.value["items"][i].Name == this.productVariationOptions[j].Name) {
            this.productVariationOptions[j].disabled = true;
          }
        }
      }
    }

    //let query = event.value;
    //let count = this.addNewProductVariationForm.value["items"].length;

    //if (this.addNewProductVariationForm.value["items"].length == 1) {
    //}
    //else if (this.addNewProductVariationForm.value["items"].length == 2) {
    //  for (let i = 0; i < this.addNewProductVariationForm.value["items"].length - 1; i++) {
    //    if (this.addNewProductVariationForm.value["items"][i].Name == query) {
    //      this.errorMessageNewProductVariation = query + " - option already selected, Please select another variation option";

    //      (this.addNewProductVariationForm.get('items') as FormArray).removeAt(i);
    //      if (this.productVariationOptions.length - 1 > this.addNewProductVariationForm.value["items"].length) {
    //        this.items.push(this.formBuilder.group({
    //          Name: [''],
    //          Value: [''],
    //        }));
    //      }

    //      return;
    //    }
    //  }
    //}
    //else {

    //  let count = this.addNewProductVariationForm.value["items"].length - 1;

    //  for (let i = 0; i < count; i++) {
    //    if (this.addNewProductVariationForm.value["items"][i].Name == query) {
    //      this.errorMessageNewProductVariation = query + " - option already selected, Please select another variation option";

    //      (this.addNewProductVariationForm.get('items') as FormArray).removeAt(count);

    //      if (this.productVariationOptions.length - 1 > count) {
    //        this.items.push(this.formBuilder.group({
    //          Name: [''],
    //          Value: [''],
    //        }));
    //      }

    //      return;
    //    }
    //  }
    //}
  }

  //End Code Product Variations

  // class end
}
