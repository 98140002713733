import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProductManagement } from '../models/productmanagement';
import { ProductLocation } from '../models/ProductLocation';


@Injectable({
  providedIn: 'root'
})
export class ProductmanagementService {
  public httpOptions: {};
  supplierId: number = 0;
  //const httpOptions = {
  //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
  //};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getAllSuppliers(): Observable<any> {
    return this.http.get('/api/Supplier/GetAllSuppliers', this.httpOptions);
  }

  getProducts(): Observable<any> {
    //return this.http.get('/api/ProductManagement/GetAllProductDetails');
    return this.http.get('/api/ProductManagement/GetAllProductDetails', this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getAllProducts(): Observable<any> {
    return this.http.get('/api/Location/GetAllProductDetailsLocation', this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  saveProduct(productManagement: Observable<ProductManagement>) {

    return this.http.post('/api/ProductManagement/SaveProduct', productManagement, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  updateProduct(productmanagement: ProductManagement) {
    return this.http.post('/api/ProductManagement/UpdateProduct', productmanagement, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  deleteProduct(productmanagement: ProductManagement) {
    return this.http.post('/api/ProductManagement/DeleteProduct', productmanagement, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getProductPackageTemplateDataBasedOnProduct(id): Observable<any> {
    return this.http.get('/api/ProductPackageTemplate/GetProductPackageTemplateDataBasedOnProduct?id=' + id, this.httpOptions)
  }


  getProductCarrierPrefenencesBasedOnProductID(id): Observable<any> {
    return this.http.get('/api/ProductManagement/GetProductCarrierPrefenencesBasedOnProductID?id=' + id, this.httpOptions)
  }
}
