export class ProductManagement {
  constructor(
    public ID:number,
    public Name: string,
    public SupplierProductCode: string,
    public SupplierID: number,
    public IsMultiPack: boolean, 
    public BarCode: string,
    public ProductCategoryID: number,
    public ProductUnitWeight: number,
    public ProductLength: number,
    public ProductWidth: number,
    public ProductHeight: number,
    public VatTypeID: number,
    public RoyalMailPackageFormat: number,
    public HasVariations: boolean,
    public PackedUnitWeight: number,
    public PackedLength: number,
    public PackedWidth: number,
    public PackedHeight: number,
    public Label_SupplierName: string,
    public Label_PackageFormaterName: string,
    public SelectedLocationIDs: object,
  ) { }
}
