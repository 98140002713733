import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Supplier } from '../models/supplier';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getAllSuppliers(): Observable<any> {
    return this.http.get('/api/Supplier/GetAllSuppliers', this.httpOptions);
  }  

  saveSupplier(supplier: Supplier) {
    return this.http.post('/api/Supplier/SaveSupplier', supplier, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  updateSupplier(supplier: Supplier) {
    return this.http.post('/api/Supplier/UpdateSupplier', supplier, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  deleteSupplier(supplier) {
    return this.http.post('/api/Supplier/DeleteSupplier', supplier, this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }

  getSupplierDetail(supplier){
    return this.http.post('/api/Supplier/GetSupllierDetail', supplier, this.httpOptions)
    .pipe(
      map((res: Response) => {
        let body = res;// .json();
        return body
          || {};
      }));
  }

 

}
