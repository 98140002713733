<!-- Sidenav -->
<div class="" (window:resize)="isMobile($event)">
    <app-sidebar
      [ngClass]="{ 'sidenav fixed-left': isMobileResolution === false }"
    ></app-sidebar>
    <div class="main-content">
      <!-- Top navbar -->
      <app-nav-menu></app-nav-menu>
      <!-- Pages -->
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
</div>