import { Injectable } from '@angular/core';
import { SharedModule } from '../shared.module';
import { Observable } from 'rxjs';
import { Login } from '../models/login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VattypesService {

  public httpOptions: {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
  }

  getAllVATTypes(): Observable<any> {
    return this.http.get('/api/VATType/GetAllVATTypes', this.httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;// .json();
          return body
            || {};
        }));
  }
}
